/* import __COLOCATED_TEMPLATE__ from './content-summary.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type ArticleMultilingual from 'embercom/models/article-multilingual';

interface ContentSummaryArgs {
  article: ArticleMultilingual;
  localeId?: string;
  showArticleTags?: boolean;
  onLinkClick?: () => void;
}

export default class ContentSummary extends Component<ContentSummaryArgs> {
  @service declare appService: any;

  get app() {
    return this.appService.app;
  }

  get selectedLocalizedContent() {
    if (!this.args.localeId) {
      return this.args.article.defaultLocalizedContent;
    }
    return this.args.article.contentByLocale(this.args.localeId);
  }

  get showReviewStamp() {
    if (this.args.localeId) {
      return this.selectedLocalizedContent?.needsReview;
    } else {
      return this.args.article.needsReview;
    }
  }

  @action onLinkClick() {
    this.args.onLinkClick?.();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::List::ContentSummary': typeof ContentSummary;
    'articles/list/content-summary': typeof ContentSummary;
  }
}
