/* import __COLOCATED_TEMPLATE__ from './height.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type Header from 'embercom/models/customization-options/global-options/header';
interface Args {
  header: Header;
  form: any;
}

const MIN_HEIGHT_VALUE = 245;
const MAX_HEIGHT_VALUE = 600;

export default class HeaderHeight extends Component<Args> {
  minHeightValue = MIN_HEIGHT_VALUE;
  maxHeightValue = MAX_HEIGHT_VALUE;

  @tracked _headerHeight = this.headerStyle.height
    ? parseInt(this.headerStyle.height, 10)
    : MIN_HEIGHT_VALUE;

  get headerStyle() {
    return this.args.header.style;
  }

  get headerHeight() {
    return this._headerHeight;
  }

  set headerHeight(value: number) {
    this._headerHeight = value;

    if (value >= this.minHeightValue && value <= this.maxHeightValue) {
      this.headerStyle.height = `${value}px`;
    }
  }

  @action onHeaderHeightBlur() {
    if (this.headerHeight > this.maxHeightValue) {
      this.headerHeight = this.maxHeightValue;
    } else if (this.headerHeight < this.minHeightValue) {
      this.headerHeight = this.minHeightValue;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::Styling::Header::Height': typeof HeaderHeight;
  }
}
