/* import __COLOCATED_TEMPLATE__ from './coming-soon-banner.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { taskFor } from 'ember-concurrency-ts';
import { restartableTask } from 'ember-concurrency-decorators';
import type BannerDismissalService from 'embercom/services/banner-dismissal-service';

interface Signature {
  Args: {
    title: string;
    description: string;
    image: string;
    bgColor: string;
    showComingSoon: boolean;
    wistiaId?: string;
    videoTitle?: string;
  };
  Blocks: {
    actions: [];
  };
}

export default class ComingSoonBanner extends Component<Signature> {
  @service declare intercomEventService: $TSFixMe;
  @service declare bannerDismissalService: BannerDismissalService;
  @tracked shouldShowBanner = false;
  dismissalKey = 'tasks-coming-soon-banner';

  constructor(owner: unknown, args: any) {
    super(owner, args);
    taskFor(this.computeVisibility).perform();
  }

  @restartableTask
  *computeVisibility() {
    let adminHasDismissedBanner: boolean = yield this.bannerDismissalService.hasDismissed(
      this.dismissalKey,
    );
    this.shouldShowBanner = !adminHasDismissedBanner;
    if (!adminHasDismissedBanner) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'viewed',
        object: 'discovery_banner',
        context: this.dismissalKey,
      });
    }
  }

  @restartableTask
  *handleDismissClick() {
    this.shouldShowBanner = false;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'dismissed',
      object: 'discovery_banner',
      context: this.dismissalKey,
    });
    yield this.bannerDismissalService.dismiss(this.dismissalKey);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Banners::ComingSoonBanner': typeof ComingSoonBanner;
    'banners/coming-soon-banner': typeof ComingSoonBanner;
  }
}
