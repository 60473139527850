/* import __COLOCATED_TEMPLATE__ from './deletion.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type HelpCenterSite from 'embercom/models/help-center-site';
import { type HelpCenterSettingNavigationHelper } from '../main';
import type IntlService from 'embercom/services/intl';

interface Args {
  site: HelpCenterSite;
  navigationHelper: HelpCenterSettingNavigationHelper;
}

export default class Deletion extends Component<Args> {
  @service declare appService: any;
  @service declare helpCenterService: any;
  @service declare intl: IntlService;
  @tracked showConfirmModal = false;

  get sites() {
    return this.helpCenterService.sites;
  }

  @action deleteHelpCenter() {
    this.showConfirmModal = true;
  }

  @action async confirmDeleteHelpCenter() {
    await this.helpCenterService.deleteHelpCenter.perform(this.args.site);
    this.showConfirmModal = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::General::Deletion': typeof Deletion;
    'articles/site/settings/general/deletion': typeof Deletion;
  }
}
