/* import __COLOCATED_TEMPLATE__ from './multi-article-group-selector.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import type Store from '@ember-data/store';
import type MutableArray from '@ember/array/mutable';
import { isPresent } from '@ember/utils';
import type HelpCenterSite from 'embercom/models/help-center-site';
import type ArticleGroup from 'embercom/models/articles/article-group';
import { indentCollections, type CollectionWithLevel } from './multi-article-group-selector-helper';

const NEW_SELECTOR = 'new-selector';
export interface MultiArticleGroupSelectorArgs {
  applyCollections: (collectionIds: string[]) => void;
  setCanSave: (canSave: boolean) => void;
  article: any;
}

export type CollectionOption = {
  text: string;
  value: string;
  isSelected: boolean;
  component: string;
  componentAttrs: { indentLevel: number };
};

type HelpCentersToCollectionsMap = Map<string, CollectionOption[]>;
type CollectionsToHelpCenters = Map<string, string>;

export default class MultiArticleGroupSelector extends Component<MultiArticleGroupSelectorArgs> {
  @service declare appService: any;
  @service declare store: Store;

  @tracked selectedTuples: Map<string | undefined, string | undefined> = new Map();

  helpCentersToCollections: HelpCentersToCollectionsMap = new Map();
  collectionsToHelpCenters: CollectionsToHelpCenters = new Map();

  constructor(owner: any, args: MultiArticleGroupSelectorArgs) {
    super(owner, args);
    this.setSelectedTuples();
    this.initCollectionsMappings();
  }

  get app() {
    return this.appService.app;
  }

  get helpCenters(): MutableArray<HelpCenterSite> {
    return this.store.peekAll('help-center-site');
  }

  get helpCenterOptions() {
    return this.helpCenters.map((helpCenter: HelpCenterSite) => ({
      text: helpCenter.name,
      value: helpCenter.id,
      isDisabled: this.selectedHelpCenters.includes(helpCenter.id),
      websiteTurnedOn: helpCenter.websiteTurnedOn,
      component: 'articles/editor/settings-side-drawer/help-center-option',
    }));
  }

  get selectedCollections(): Array<string> {
    // @ts-ignore isPresent's type definition is not imported correctly.
    return Array.from(this.selectedTuples.values()).filter((id) => isPresent(id));
  }

  get selectedHelpCenters(): Array<string> {
    // @ts-ignore isPresent's type definition is not imported correctly.
    return Array.from(this.selectedTuples.keys()).filter(
      (id) => isPresent(id) && id !== NEW_SELECTOR,
    );
  }

  get allHelpCentersSelected(): boolean {
    return this.helpCenters.length === this.selectedHelpCenters.length;
  }

  get showAddToHelpCenterButton(): boolean {
    return (this.helpCenters.length as number) > this.selectedTuples.size;
  }

  get selectableTuples(): Array<[string | undefined, string | undefined]> {
    let tuples = Array.from(this.selectedTuples.entries()).filter(
      ([helpCenterId, _collectionId]) => {
        return helpCenterId !== NEW_SELECTOR;
      },
    );
    if (this.selectedTuples.has(NEW_SELECTOR) && !this.allHelpCentersSelected) {
      tuples.push([undefined, undefined]);
    }
    return tuples;
  }

  @action onHelpCenterSelect(helpCenterId: string, oldHelpCenterId?: string) {
    this.selectedTuples.set(helpCenterId, undefined);
    this.selectedTuples.delete(oldHelpCenterId);
    this.selectedTuples.delete(NEW_SELECTOR);
    this.refeshSelectedTuples();
  }

  @action onCollectionSelect(helpCenterId: string, collectionId: string) {
    this.selectedTuples.set(helpCenterId, collectionId);
    this.refeshSelectedTuples();
  }

  @action removeTuple(helpCenterId: string) {
    this.selectedTuples.delete(helpCenterId);
    if (this.selectedTuples.size === 0) {
      this.selectedTuples.set(NEW_SELECTOR, undefined);
    }
    this.refeshSelectedTuples();
  }

  @action collectionOptions(helpCenterId: string) {
    return this.helpCentersToCollections.get(helpCenterId) || [];
  }

  @action addToHelpCenter() {
    this.selectedTuples.set(NEW_SELECTOR, undefined);
    this.refeshSelectedTuples();
  }

  private refeshSelectedTuples() {
    this.args.applyCollections(this.selectedCollections);
    // Tickle Glimmer to re-render the component.
    this.selectedTuples = this.selectedTuples;
    this.updateCanSave();
  }

  private updateCanSave() {
    let result = true;

    // If the article is not in any HC then allow this setting to be saved, since it's valid
    // Otherwise, we validate all of the tuple selections.
    let shouldValidateTuples =
      this.selectedTuples.size > 1 || !this.selectedTuples.has(NEW_SELECTOR);

    if (shouldValidateTuples) {
      for (let [hcId, collection] of this.selectedTuples) {
        if (!hcId || (hcId !== NEW_SELECTOR && !collection)) {
          result = false;
          break;
        }
      }
    }

    this.args.setCanSave(result);
  }

  private initCollectionsMappings() {
    let helpCentersToCollections: HelpCentersToCollectionsMap = new Map();
    let collectionsToHelpCenters: CollectionsToHelpCenters = new Map();

    let helpCenterCollector: Map<string, ArticleGroup[]> = new Map();
    let articleGroups = this.store.peekAll('articles/article-group');

    articleGroups
      .filter((collection: ArticleGroup) => !collection.readOnly)
      .forEach((collection: ArticleGroup) => {
        let helpCenterId = collection.helpCenterId;
        collectionsToHelpCenters.set(collection.id, collection.helpCenterId);

        let collectionsArray = helpCenterCollector.get(helpCenterId) ?? [];
        collectionsArray.push(collection);
        helpCenterCollector.set(helpCenterId, collectionsArray);
      });

    helpCenterCollector.forEach((collections, helpCenterId) => {
      helpCentersToCollections.set(
        helpCenterId,
        indentCollections(collections).map((collection: CollectionWithLevel) =>
          this.makeCollectionOption(collection),
        ),
      );
    });

    this.helpCentersToCollections = helpCentersToCollections;
    this.collectionsToHelpCenters = collectionsToHelpCenters;
  }

  private setSelectedTuples() {
    let collections = this.args.article.get('inCollections');
    collections.forEach((collection: ArticleGroup) => {
      let helpCenterId = collection.get('helpCenterId');
      let collectionId = collection.get('id');
      this.selectedTuples.set(helpCenterId, collectionId);
    });
    if (collections.length === 0) {
      this.selectedTuples.set(NEW_SELECTOR, undefined);
    }
    this.refeshSelectedTuples();
  }

  private makeCollectionOption(augmentedCollection: CollectionWithLevel): CollectionOption {
    return {
      text: `${augmentedCollection.collection.get('name')}`,
      value: augmentedCollection.collection.id,
      isSelected: this.selectedCollections.includes(augmentedCollection.collection.id),
      component: 'articles/editor/settings-side-drawer/indented-collection-option',
      componentAttrs: { indentLevel: augmentedCollection.level },
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Editor::SettingsSideDrawer::MultiArticleGroupSelector': typeof MultiArticleGroupSelector;
    'articles/editor/settings-side-drawer/multi-article-group-selector': typeof MultiArticleGroupSelector;
  }
}
