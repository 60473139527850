/* import __COLOCATED_TEMPLATE__ from './help-center-filter.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { modifier } from 'ember-modifier';
import type IntlService from 'embercom/services/intl';

interface FilterItem {
  text: string;
  value: string;
  isSelected: boolean;
  component: string;
}
interface Args {
  availableItems: FilterItem[];
  selectedValues: string[];
  activeFilter: string;
  filterContent: (filter: string, value: string[]) => void;
  updateActiveFilter: (filter: string | undefined) => void;
}

export default class HelpCenterFilter extends Component<Args> {
  @service declare intl: IntlService;
  @service declare intercomEventService: any;

  @tracked selectedValues: string[] = [];

  filterResetable = modifier((_: Element, [v]: any) => {
    let newValues = v || [];

    this.onSelectionChange(newValues.slice());
  });

  get isOpen() {
    return this.args.activeFilter === 'help_center_ids';
  }

  get availableItems() {
    return this.args.availableItems || [];
  }

  get hasFilter() {
    // Show search box when there are more than 8 items
    return this.items.length > 8;
  }

  get items() {
    let result: FilterItem[] = [];
    result.push(
      ...this.availableItems.map((item: FilterItem) => ({
        text: item.text,
        value: item.value,
        isSelected: this.selectedValues ? this.selectedValues.includes(item.value) : false,
        component: 'articles/shared/help-center-filter-option',
      })),
    );
    return result;
  }

  get details() {
    let count = this.selectedValues.length;

    return this.intl.t('articles.shared.help_center_filter.details', {
      firstItem: count ? this.getDisplayName(this.selectedValues[0]) : '',
      count,
      additional: count - 1,
    });
  }

  get label() {
    return this.intl.t('articles.shared.help_center_filter.label');
  }

  get searchPlaceHolder() {
    return this.intl.t('articles.shared.help_center_filter.search_placeholder');
  }

  @action deleteSelectedValues() {
    this.onSelectionChange([]);
    this.args.filterContent('help_center_ids', this.selectedValues);
  }

  @action onSelectionChange(selectedValues: string[]) {
    this.selectedValues = selectedValues;
  }

  @action onOpen() {
    if (this.isOpen) {
      return;
    }

    this.args.updateActiveFilter('help_center_ids');
    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      object: 'help_center_filter',
    });
  }

  @action onClose() {
    this.args.updateActiveFilter(undefined);
    let original = this.args.selectedValues || [];
    if (
      original.length === this.selectedValues.length &&
      original.every((value: string, index: number) => value === this.selectedValues[index])
    ) {
      return;
    }

    this.args.filterContent('help_center_ids', this.selectedValues);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'applied_filter',
      object: 'help_center_filter',
      count: this.selectedValues.length,
      values: this.selectedValues.map((v) => this.getDisplayName(v)),
    });
  }

  private getDisplayName(v: string) {
    let matched = this.items.find((e) => e.value === v);
    if (!matched) {
      return '';
    }
    return matched.text;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Shared::HelpCenterFilter': typeof HelpCenterFilter;
    'articles/shared/help-center-filter': typeof HelpCenterFilter;
  }
}
