/* import __COLOCATED_TEMPLATE__ from './migrate-to-stripe-app-modal.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { post, get } from 'embercom/lib/ajax';
import { taskFor } from 'ember-concurrency-ts';
import type IntlService from 'ember-intl/services/intl';
import type { TaskGenerator } from 'ember-concurrency';

interface Signature {
  Args: {
    closeModal: () => void;
  };
}

interface StripeAppAccount {
  stripe_account_name: string;
}

export default class MigrateToStripeAppModal extends Component<Signature> {
  @service declare appService: $TSFixMe;
  @service declare redirectService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;

  @tracked primaryAccount?: StripeAppAccount = undefined;

  constructor(owner: unknown, args: Signature['Args']) {
    super(owner, args);
    taskFor(this.fetchStripeAppPrimaryAccount).perform();
  }

  get app() {
    return this.appService.app;
  }

  get admin() {
    return this.app.currentAdmin;
  }

  @task({ drop: true })
  *updateInstallation(): TaskGenerator<void> {
    try {
      yield post(`/ember/stripe_settings/update_installation`, {
        app_id: this.app.id,
        admin_id: this.admin.id,
      });

      if (!this.primaryAccount) {
        return this.redirectService.redirect(
          `/ember/stripe_settings/add_account?app_id=${this.app.id}&admin_id=${this.admin.id}`,
        );
      } else {
        yield this.appService.app.reload();
        this.notificationsService.notifyConfirmation(
          this.intl.t('appstore.settings.stripe.migrate-to-stripe-app-modal.upgrade-successful'),
        );
        this.args.closeModal();
      }
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t(
          'appstore.settings.stripe.migrate-to-stripe-app-modal.upgrade-installation-error',
        ),
      );
    }
  }

  @task({ restartable: true })
  *fetchStripeAppPrimaryAccount(): TaskGenerator<void> {
    this.primaryAccount = yield get(`/ember/stripe_settings/fetch_stripe_app_primary_account`, {
      app_id: this.app.id,
    });
  }

  @action async performUpdateInstallation() {
    await taskFor(this.updateInstallation).perform();
  }

  get fetchingStripeAppPrimaryAccount() {
    return taskFor(this.fetchStripeAppPrimaryAccount).isRunning;
  }

  get updatingInstallation() {
    return taskFor(this.updateInstallation).isRunning;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    MigrateToStripeAppModal: typeof MigrateToStripeAppModal;
    'migrate-to-stripe-app-modal': typeof MigrateToStripeAppModal;
  }
}
