/* import __COLOCATED_TEMPLATE__ from './credit-card-component.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type CardlessConversionModalService from 'embercom/services/cardless-conversion-modal-service';

export default class CreditCardComponent extends Component {
  @service declare customerService: $TSFixMe;
  @service declare cardlessConversionModalService: CardlessConversionModalService;
  @service declare appService: $TSFixMe;
  @tracked cardModalIsOpen = false;

  get customer() {
    return this.customerService.customer;
  }

  get cannotChangePaymentMethod() {
    return (
      this.customer.inTrialOrExpiredTrial &&
      !this.appService.app.isStandaloneApp &&
      !this.customer.inSalesProspectTrial
    );
  }

  @action
  openModal() {
    if (this.cannotChangePaymentMethod) {
      this.cardlessConversionModalService.toggle();
    } else {
      this.openCardModal();
    }
  }

  openCardModal() {
    this.cardModalIsOpen = true;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Settings::Payment::CreditCardComponent': typeof CreditCardComponent;
    'billing/settings/payment/credit-card-component': typeof CreditCardComponent;
  }
}
