/* import __COLOCATED_TEMPLATE__ from './usage-alerts-limits-modal-input.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { restartableTask } from 'ember-concurrency-decorators';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { timeout } from 'ember-concurrency';
import { Metric } from 'embercom/models/data/pricing/metric-types';
import { ChargeModel } from 'embercom/models/billing/price/charge';
import type IntlService from 'ember-intl/services/intl';
import type Charge from 'embercom/models/billing/price/charge';
import { taskFor } from 'ember-concurrency-ts';

const DEBOUNCE_MS = 250;
const NUMBER_OF_INCLUDED_TIERS_FROM_PRICING_ENDPOINT = 1;

export interface UsageAlertLimitAmountInputArgs {
  initialAmount: number | null;
  charge: Charge;
  onUpdate: (arg0: number | null) => void;
  enabled: boolean;
  title: string;
  showPrice: boolean;
}

export default class UsageAlertsLimitsModalInputComponent extends Component<UsageAlertLimitAmountInputArgs> {
  @service declare customerService: any;
  @service declare intl: IntlService;
  @service declare notificationsService: any;

  @tracked amount = this.args.initialAmount ?? null;
  @tracked price: $TSFixMe = {};
  showPrice = this.args.showPrice;

  constructor(owner: unknown, args: UsageAlertLimitAmountInputArgs) {
    super(owner, args);

    if (this.showPrice && this.amount !== null && this.amount > 0) {
      taskFor(this.fetchPrice).perform();
    }
  }

  get selectedMetric() {
    return this.args.charge.pricing_metric;
  }

  get isFetchingRunning() {
    return taskFor(this.fetchPrice).isRunning;
  }

  @action
  getPrice(event: InputEvent) {
    let target = event.target as HTMLInputElement;
    let value = parseFloat(target.value);

    if (!isNaN(value)) {
      this.amount = parseFloat(value.toFixed(2));
      if (this.isPhone || this.amount <= 0) {
        this.price = 0;
      }
      if (!this.isPhone && this.showPrice) {
        taskFor(this.fetchPrice).perform();
      }
    }

    this.args.onUpdate(this.amount);
  }

  @restartableTask
  *fetchPrice() {
    yield timeout(DEBOUNCE_MS);
    try {
      // @ts-ignore
      this.price = yield this.customerService.fetchCurrentPricePerUsage({
        includeTiers: NUMBER_OF_INCLUDED_TIERS_FROM_PRICING_ENDPOINT,
        usageAmount: this.amount,
        pricingMetric: this.selectedMetric,
      });
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('billing.usage.modal-usage-alerts-and-limits.display-price-error'),
      );
    }
  }

  get priceForUnit() {
    if (!this.price || this.amount === null || this.amount <= 0) {
      return 0;
    }
    return this.calculatePriceFromCharge();
  }

  calculatePriceFromCharge() {
    let charge = this.chargeForSelectedMetric;
    if (!charge) {
      return 0;
    }

    let priceInDollars = this.centsToDollars(charge.price ?? 0);

    if (charge.charge_model === ChargeModel.Tiered) {
      let firstTier = charge.tiers?.firstObject;

      if (firstTier.price_format === ChargeModel.FlatFee) {
        return priceInDollars - firstTier.price ?? 0;
      }
    }
    return priceInDollars;
  }

  get chargeForSelectedMetric() {
    return this.price.firstObject.getChargeForMetric(this.selectedMetric);
  }

  get isPhone() {
    return this.selectedMetric === Metric.calling;
  }

  get phonePrice() {
    return this.centsToDollars(this.args.charge.per_unit_price);
  }

  centsToDollars(cents: number) {
    return cents / 100;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Usage::UsageAlertsLimitsModalInput': typeof UsageAlertsLimitsModalInputComponent;
    'billing/usage/usage-alerts-limits-modal-input': typeof UsageAlertsLimitsModalInputComponent;
  }
}
