/* import __COLOCATED_TEMPLATE__ from './non-contracted-under-limit.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type { TooltipComponentSignature } from '../schemas';
import { type TooltipRow } from '../shared-components/tooltip-item';
export default class NonContractedUnderLimitComponent extends Component<TooltipComponentSignature> {
  @service declare intl: IntlService;
  @service declare customerService: $TSFixMe;

  get additionalText() {
    return this.intl.t('billing.summary.usage-tooltip.price_per_resolution');
  }

  get totalRow() {
    return [
      {
        label: this.intl.t('billing.summary.usage-tooltip.total'),
        muted: true,
        noPadding: true,
        strong: true,
        value: this.formatPrice(this.args.metricPrice.per_unit_price * this.args.currentUsage),
      },
    ] as TooltipRow[];
  }

  get trialDisclaimerRow() {
    if (this.customerService.customer?.inTrial) {
      return [
        {
          label: this.intl.t('billing.summary.usage-tooltip.trial_disclaimer'),
          muted: true,
          noPadding: true,
        },
      ] as TooltipRow[];
    }
    return [];
  }

  formatPrice(priceInCents: number) {
    return this.intl.formatNumber(priceInCents / 100, {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Usage::TooltipsNew::FixedPriceMetrics::NonContractedUnderLimit': typeof NonContractedUnderLimitComponent;
  }
}
