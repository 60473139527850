/* import __COLOCATED_TEMPLATE__ from './color-panel.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import type OutboundContent from 'embercom/models/customization-options/outbound-content';

export interface Args {
  customizationOptions: OutboundContent;
  form: any;
}

export default class ColorPanel extends Component<Args> {
  @action
  updateBackgroundColor(color: string) {
    this.args.customizationOptions.selectColor(color);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::NewStyling::Footer::ColorPanel': typeof ColorPanel;
  }
}
