/* import __COLOCATED_TEMPLATE__ from './hero-banner.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import type StripeMigration from 'embercom/models/billing/stripe-migration';
import Component from '@glimmer/component';
import { helpLinkUrlsForStripeMigrations } from 'embercom/helpers/billing/migrations-helper';
import { inject as service } from '@ember/service';

interface Args {
  migration: StripeMigration;
  talkToSales: () => void;
  formattedMigrationDate: string;
}

interface Signature {
  Args: Args;
}

export default class MigrationHeroBannerComponent extends Component<Signature> {
  @service declare appService: any;
  @service declare customerService: any;

  translationKey = 'billing.migrations.migration_overview.hero_banner.';

  get helpLinkUrls() {
    return helpLinkUrlsForStripeMigrations;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::StripeMigration::MigrationSubcontent::HeroBanner': typeof MigrationHeroBannerComponent;
  }
}
