/* import __COLOCATED_TEMPLATE__ from './notifications-and-limits.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { guidFor } from '@ember/object/internals';
import { Metric } from 'embercom/models/data/pricing/metric-types';
import { getArticleFromDistribution } from 'embercom/lib/articles/article-distribution';
export default class NotificationsAndLimits extends Component {
  @tracked usageReminderSwitchEnabled = !this.args.usageReminder.isNew;
  @tracked showValidationModal = false;
  @tracked showLimitValidationModal = false;
  @tracked openSectionId = 'usage-reminder';
  resolutionPerUnitPrice = this._perUnitPrice(this._resolutionMetricName);
  resolutionsInputId = `resolutions-threshold-id-${guidFor(this)}`;
  resolutionsLimitInputId = `resolutions-reminder-threshold-id-${guidFor(this)}`;

  @service appService;
  @service customerService;
  @service notificationsService;
  @service intl;

  get _resolutionMetricName() {
    return this.app.usesResolutionsWithCustomAnswers
      ? Metric.resolutions_with_custom_answers
      : Metric.resolutions;
  }

  get app() {
    return this.appService.app;
  }

  get contractedResolutionsAmount() {
    if (this.app.usesResolutionsWithCustomAnswers) {
      return this.args.contract.contractUsageLimits.resolutions_with_custom_answers;
    }

    return this.args.contract.contractUsageLimits.resolutions;
  }

  get usageReminderArticleLink() {
    if (this.app.usesResolutionsWithCustomAnswers) {
      // For pricing 5 customers
      return 'https://www.intercom.com/help/en/articles/8991894-how-to-see-and-manage-your-usage#h_36badc7757';
    } else {
      return 'https://www.intercom.com/help/en/articles/8060969-get-notified-of-your-fin-usage-and-set-hard-limits-legacy-plans';
    }
  }

  _perUnitPrice(metricName) {
    if (this.customerService.currentPrice?.breakdown) {
      return (
        this.customerService.currentPrice.breakdown.reduce((accumulator, breakdownItem) => {
          if (isPresent(accumulator)) {
            return accumulator;
          }

          let resolutionCharge = breakdownItem.charges?.find(
            (charge) => charge.pricing_metric === metricName,
          );

          if (resolutionCharge?.per_unit_price) {
            return resolutionCharge.per_unit_price / 100;
          }
        }) || 0
      );
    }

    return 0;
  }

  get isSaveButtonDisabled() {
    return (
      !this.app.canUseFeature('ai_answers_billing_feature') ||
      !this.args.usageReminder.hasDirtyAttributes ||
      (this.args.usageReminder.isNew && !this.usageReminderSwitchEnabled)
    );
  }

  get isLimitSaveButtonDisabled() {
    return (
      !this.app.canUseFeature('ai_answers_billing_feature') ||
      !this.args.finUsageLimit.hasDirtyAttributes
    );
  }

  get reminderPrice() {
    return this._price(this.args.usageReminder.threshold);
  }

  get limitsPrice() {
    return this._price(this.args.finUsageLimit.threshold);
  }

  _price(threshold) {
    return this.app.isSalesforceContracted
      ? this._salesforceContractedPrice(threshold)
      : threshold * this.resolutionPerUnitPrice;
  }

  _salesforceContractedPrice(threshold) {
    return Math.max(0, threshold - this.contractedResolutionsAmount) * this.resolutionPerUnitPrice;
  }

  @action toggleUsageReminder() {
    if (!this.args.usageReminder.isNew) {
      if (this.usageReminderSwitchEnabled && !this.args.usageReminder.isDeleted) {
        this.args.usageReminder.deleteRecord();
      } else {
        this.args.usageReminder.rollbackAttributes();
      }
    } else {
      this.args.onToggleOnNewUsageReminder();
    }

    this.usageReminderSwitchEnabled = !this.usageReminderSwitchEnabled;
  }

  @action toggleUsageLimit() {
    this.args.finUsageLimit.toggleProperty('enabled');
  }

  @task
  *validateAndUpdateThreshold() {
    if (this.usageReminderSwitchEnabled && !this._validateThresholdBiggerThanZero()) {
      return;
    }

    this.shouldBeActivatedNextBillingCycle
      ? yield this.toggleValidationModal()
      : yield this.updateUsageReminderThreshold.perform();
  }

  @task
  *validateAndUpdateLimitThreshold() {
    if (this.args.finUsageLimit.enabled && !this._validateLimitThresholdBiggerThanZero()) {
      return;
    }

    this.alreadyBreachedLimit
      ? yield this.toggleLimitValidationModal()
      : yield this.updateUsageLimitThreshold.perform();
  }

  get shouldBeActivatedNextBillingCycle() {
    return (
      this.usageReminderSwitchEnabled &&
      this.args.currentUsage.value >= this.args.usageReminder.threshold
    );
  }

  get alreadyBreachedLimit() {
    return (
      this.args.finUsageLimit.enabled &&
      this.args.currentUsage.value >= this.args.finUsageLimit.threshold
    );
  }

  get finBillingUrl() {
    return getArticleFromDistribution(
      'NOTIFICATIONS_AND_LIMITS__USAGE_REMINDER',
      this.app,
      this.customerService,
    ).articleLink;
  }

  toggleValidationModal() {
    this.showValidationModal = !this.showValidationModal;
  }

  toggleLimitValidationModal() {
    this.showLimitValidationModal = !this.showLimitValidationModal;
  }

  @task
  *updateUsageReminderThreshold(willStartNextBillingCycle = false) {
    try {
      if (!this.args.usageReminder.isDeleted) {
        this.args.usageReminder.willStartNextBillingCycle = willStartNextBillingCycle;
      }

      yield this.args.usageReminder.save();

      this.notificationsService.notifyConfirmation(
        this.intl.t('billing.manage-usage.successfully-saved'),
      );
    } catch (error) {
      this.notificationsService.notifyError(this.intl.t('billing.manage-usage.unexpected-error'));

      throw error;
    }

    if (this.args.usageReminder.isDeleted && !this.args.usageReminder.hasDirtyAttributes) {
      // to replenish the deleted record with a new clean record
      this.args.onDestroyUsageReminder();
    }

    this.showValidationModal = false;
  }

  @task
  *updateUsageLimitThreshold() {
    try {
      yield this.args.finUsageLimit.save();

      this.notificationsService.notifyConfirmation(
        this.intl.t('billing.manage-usage.successfully-saved'),
      );
    } catch (error) {
      this.notificationsService.notifyError(this.intl.t('billing.manage-usage.unexpected-error'));

      throw error;
    }

    this.showLimitValidationModal = false;
  }

  _validateThresholdBiggerThanZero() {
    return document.getElementById(this.resolutionsInputId).reportValidity();
  }

  _validateLimitThresholdBiggerThanZero() {
    return document.getElementById(this.resolutionsLimitInputId).reportValidity();
  }
}
