/* import __COLOCATED_TEMPLATE__ from './tabs-component.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable @intercom/intercom/no-component-inheritance */
/* eslint-disable ember/require-computed-property-dependencies */
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import MessageEditorTabsComponent from './legacy-message-editor-tabs-component';

const TABS = {
  article: {
    label: 'Article',
    isPercentageValue: false,
    views: ['article'],
    color: 'text-muted',
    icon: 'newspaper',
  },
  viewers: {
    label: 'Viewers',
    isPercentageValue: false,
    views: ['viewers'],
  },
  conversations: {
    label: 'Conversations',
    isPercentageValue: false,
    views: ['newchat'],
  },
  reactions: {
    label: 'Reactions',
    isPercentageValue: false,
    views: ['reacted'],
  },
  happy: {
    label: '\uD83D\uDE03',
    isPercentageValue: true,
    views: ['reaction_0'],
  },
  neutral: {
    label: '\uD83D\uDE10',
    isPercentageValue: true,
    views: ['reaction_1'],
  },
  sad: {
    label: '\uD83D\uDE1E',
    isPercentageValue: true,
    views: ['reaction_2'],
  },
};

export default MessageEditorTabsComponent.extend({
  stats: readOnly('article.stats'),

  tabs: computed('stats', 'stats.lastUpdatedAt', 'app', function () {
    return [
      this.getTabContent('article'),
      this.getTabContent('viewers', this.get('stats.viewCount')),
      this.getTabContent('conversations', this.get('stats.replyCount')),
      this.getTabContent('reactions', this.get('stats.responseCount')),
      this.getTabContent('sad', this.get('stats.reaction2Percentage') || 0),
      this.getTabContent('neutral', this.get('stats.reaction1Percentage') || 0),
      this.getTabContent('happy', this.get('stats.reaction0Percentage') || 0),
    ];
  }),

  getTabContent(name, value = '') {
    return Object.assign({}, TABS[name], {
      statistic: value,
      isClickable: true,
    });
  },
});
