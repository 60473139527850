/* import __COLOCATED_TEMPLATE__ from './confirmation-modal.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type Plan from 'embercom/models/plan';
import type RouterService from '@ember/routing/router-service';

interface Signature {
  Args: {
    redirectSource: 'exit_survey' | 'cancellation_flow' | null;
  };
}

export default class ConfirmationModal extends Component<Signature> {
  @service appService: any;
  @service intercomEventService: any;
  @service declare router: RouterService;

  get isOpen() {
    return (
      this.args.redirectSource === 'cancellation_flow' || this.args.redirectSource === 'exit_survey'
    );
  }

  get modalDescriptionKey() {
    if (this.args.redirectSource === 'exit_survey') {
      return 'billing.settings.cancel-confirmation-modal.description.exit-survey';
    }
    return 'billing.settings.cancel-confirmation-modal.description.cancellation-flow';
  }

  get app() {
    return this.appService.app;
  }

  get planName() {
    return this.currentSubscriptionPlan.name;
  }

  get currentSubscriptionPlan() {
    return this.app.allPlansOnPricingModel.find(
      (plan: Plan) => plan.billableCustomerPlan && !plan.product.addon,
    );
  }

  @action
  handleCancellation() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'continue_to_cancel_subscription',
      section: 'cancel_subscription.confirmation_modal',
      place: 'billing_settings',
    });
    // TODO: Implement the logic to cancel the subscription
  }

  @action
  handleModalClose(object: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object,
      section: 'cancel_subscription.confirmation_modal',
      place: 'billing_settings',
      context: 'abort_cancel_subscription',
    });
    this.router.transitionTo('apps.app.settings.subscription.billing.settings', {
      queryParams: {
        redirect_source: null,
      },
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Settings::Cancel::ConfirmationModal': typeof ConfirmationModal;
    'billing/settings/cancel/confirmation-modal': typeof ConfirmationModal;
  }
}
