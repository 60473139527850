/* import __COLOCATED_TEMPLATE__ from './article-status-filter.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class ArticleStatusFilter extends Component {
  @service intl;
  @service intercomEventService;

  @tracked selectedValue = '';

  constructor() {
    super(...arguments);
    this.selectedValue = this.args.selectedValue || '';
  }

  get isOpen() {
    return this.args.activeFilter === 'status';
  }

  get availableItems() {
    return [
      {
        text: this.intl.t('articles.list.status_filter.draft_label'),
        value: 'draft',
        classes: 'articles__status__filter__draft mr-1',
      },
      {
        text: this.intl.t('articles.list.status_filter.published_label'),
        value: 'published',
        classes: 'articles__status__filter__published mr-1',
      },
      {
        text: this.intl.t('articles.list.status_filter.missing_label'),
        value: 'missing',
        classes: 'articles__status__filter__missing mr-1',
      },
      {
        text: this.intl.t('articles.list.status_filter.published_with_draft_label'),
        value: 'published_with_draft',
        classes: 'articles__status__filter__published-with-draft mr-1',
      },
    ];
  }

  get items() {
    return this.availableItems.map((item) => ({
      text: item.text,
      value: item.value,
      classes: item.classes,
      isSelected: this.selectedValue ? this.selectedValue === item.value : false,
      component: 'articles/list/article-status-filter-option',
    }));
  }

  get details() {
    let count = this.selectedValue ? 1 : 0;

    return this.intl.t('articles.list.status_filter.details', {
      firstItem: count ? this._getDisplayName(this.selectedValue) : '',
      count,
      additional: count - 1,
    });
  }

  get label() {
    return this.intl.t('articles.list.status_filter.label');
  }

  _getDisplayName(v) {
    let matched = this.items.find((e) => e.value === v);
    if (!matched) {
      return '';
    }
    return matched.text;
  }

  @action resetSelectedValue(_element, [selectedValue]) {
    this.onSelectItem(selectedValue || '', true);
  }

  @action deleteSelectedValue() {
    this.onSelectItem(this.defaultValue, true);
    this.args.filterContent('status', this.selectedValue || '');
  }

  @action onSelectItem(selectedValue, forced = false) {
    if (this.selectedValue !== selectedValue || forced) {
      this.selectedValue = selectedValue;
    } else {
      this.selectedValue = '';
    }
  }

  @action onOpen() {
    if (this.isOpen) {
      return;
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      object: 'article_status_filter',
    });
  }

  @action onClose() {
    this.args.updateActiveFilter(undefined);
    let original = this.args.selectedValue || '';
    if (original === this.selectedValue) {
      return;
    }
    this.args.filterContent('status', this.selectedValue || '');

    this.intercomEventService.trackAnalyticsEvent({
      action: 'applied_filter',
      object: 'article_status_filter',
      count: this.selectedValue ? 1 : 0,
      values: this._getDisplayName(this.selectedValue),
    });
  }
}
