/* import __COLOCATED_TEMPLATE__ from './credit-card-modal.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import ENV from 'embercom/config/environment';
import { post } from 'embercom/lib/ajax';

export default class CreditCardModal extends Component {
  @service appService;
  @service customerService;
  @service stripev3;
  @service notificationsService;
  @service purchaseAnalyticsService;
  @service store;
  @service intl;
  @service themeService;
  @tracked setupIntent = null;
  @tracked elements = null;
  @service router;

  constructor() {
    super(...arguments);
    taskFor(this.fetchSetupIntent).perform();
  }

  @task
  *fetchSetupIntent() {
    try {
      let response = yield post(`/ember/customers/create_setup_intent_for_customer`, {
        app_id: this.appService.app.id,
        admin_id: this.appService.app.currentAdminId,
      });
      this.setupIntent = response;

      yield taskFor(this.initializeStripeElements).perform();
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('billing.credit-card-modal.stripe.payment-element-error'),
      );
    }
  }

  addressRequiresValidationForCountry(countryCode) {
    if (this.appService.app.disableFrontendAddressValidation) {
      return false;
    }

    return ['US', 'CA'].includes(countryCode);
  }

  @task
  *initializeStripeElements() {
    if (this.stripev3.stripeOptions?.stripeAccount !== this.setupIntent.stripe_account_id) {
      this.stripev3._stripe = null;
    }

    yield this.stripev3.load(this.getStripeKey(), {
      stripeAccount: this.setupIntent.stripe_account_id,
    });

    let getComputedCssVar = (varName) =>
      window.getComputedStyle(document.documentElement).getPropertyValue(varName);
    this.elements = this.stripev3.elements({
      loader: 'auto',
      appearance: {
        theme: 'stripe',
        variables: {
          colorText: getComputedCssVar('--text-default'),
          colorPrimary: getComputedCssVar('--accent-fill'),
          colorBackground: getComputedCssVar('--base-module'),
        },
      },
      clientSecret: this.setupIntent.client_secret,
    });

    this.addressElementReference = this.elements.create('address', {
      mode: 'billing',
      fields: { name: 'never' },
    });
    this.paymentElementReference = this.elements.create('payment', { layout: 'tabs' });

    this.paymentElementReference.mount('#payment-element');
    this.addressElementReference.mount('#address-element');
  }

  _isStaging() {
    return window.location.hostname.startsWith(ENV.APP.stagingHostname);
  }

  getStripeKey() {
    if (this._isStaging()) {
      return ENV.APP.stripe.sandboxPublishableKey;
    }
    return this.stripev3.publishableKey;
  }

  @task
  *updateCardTask() {
    let response;
    let addressElement = this.elements.getElement('address');
    let {
      value: { address },
    } = yield addressElement.getValue();

    try {
      let addressParams = {
        country_code: address?.country,
        state_code: address?.state,
        postcode: address?.postal_code,
        city: address?.city,
        street_address: address?.line1,
        app_id: this.appService.app.id,
      };

      // validate US/CA address with avalara
      if (this.addressRequiresValidationForCountry(address.country ?? '')) {
        try {
          yield taskFor(this.validateAddress).perform(addressParams);
        } catch (err) {
          this.notificationsService.notifyError(
            this.intl.t('signup.teams.pricing5.annual-plans.address-error'),
          );
          this.purchaseAnalyticsService.trackEvent({
            action: 'errored',
            object: 'update_card_button',
            context: 'address_validation',
            place: 'credit-card-modal',
          });
          return;
        }
      }

      let baseUrl = window.location.origin;
      let url = this.router.urlFor(
        'apps.app.settings.subscription.billing.settings',
        this.appService.app.id,
      );

      response = yield this.stripev3.instance.confirmSetup({
        elements: this.elements,
        redirect: 'if_required',
        confirmParams: {
          return_url: `${baseUrl}${url}`,
        },
      });
    } catch (_err) {
      return this.notificationsService.notifyError(
        this.intl.t('billing.credit-card-modal.stripe.generic-error'),
      );
    }

    if (response.error) {
      switch (response.error?.type) {
        case 'card_error':
        case 'validation_error':
          if (
            response.error?.decline_code === 'lost_card' ||
            response.error?.decline_code === 'stolen_card'
          ) {
            this.notificationsService.notifyError(
              this.intl.t('billing.credit-card-modal.stripe.lost-stolen-card'),
            );
          } else {
            this.notificationsService.notifyError(response.error?.message);
          }
          break;
        case 'invalid_request_error':
          if (response.error?.code === 'setup_intent_authentication_failure') {
            this.notificationsService.notifyError(
              this.intl.t('billing.credit-card-modal.stripe.card-unauthorized'),
            );
          } else {
            this.notificationsService.notifyError(
              this.intl.t('billing.credit-card-modal.stripe.generic-error'),
            );
          }
          break;
        default:
          this.notificationsService.notifyError(
            this.intl.t('billing.credit-card-modal.stripe.generic-error'),
          );
          break;
      }
      return;
    }

    let stripePaymentMethodId = response.setupIntent.payment_method;

    let requestData = {
      country_code: address?.country,
      state_code: address?.state,
      stripe_payment_method_id: stripePaymentMethodId,
      app_id: this.appService.app.id,
      billing_address: {
        street_address: address?.line1,
        city: address?.city,
        postcode: address?.postal_code,
        country: address?.country,
        state_code: address?.state,
      },
    };
    yield taskFor(this.updateDefaultPaymentMethod).perform(requestData);
    this.args.closeModal();
  }

  @task
  *validateAddress(address) {
    let response;
    try {
      response = yield post('/ember/customers/validate_address', address);
      return response;
    } catch (err) {
      captureException(err);
      this.notificationsService.notifyError(
        this.intl.t('signup.teams.pricing5.annual-plans.address-error'),
      );
      console.error(err?.jqXHR?.responseJSON?.error);
      captureException(err, {
        tags: {
          responsibleTeam: 'team-purchase',
          responsible_team: 'team-purchase',
        },
      });
      throw err;
    }
  }

  @task
  *updateDefaultPaymentMethod(params) {
    let response = yield post(
      `/ember/customers/${this.customerService.customer.id}/update_credit_card`,
      params,
    );

    this.store.pushPayload('billing/customer', {
      'billing/customer': response,
    });
    this.notificationsService.notifyConfirmation(
      this.intl.t('billing.credit-card-modal.successful-update'),
    );
  }
}
