/* import __COLOCATED_TEMPLATE__ from './confirm-component.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable @intercom/intercom/no-component-inheritance */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/require-computed-property-dependencies */
import FormComponent from 'embercom/components/signup/new/form-component';
import { action } from '@ember/object';
import { readOnly, alias, filterBy, mapBy } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import ajax from 'embercom/lib/ajax';
import { task } from 'ember-concurrency';
import { flatMap } from '@intercom/pulse/lib/computed-properties';
import {} from 'embercom/lib/billing';

export default FormComponent.extend({
  purchaseAnalyticsService: service(),
  notificationsService: service(),
  appService: service(),
  modelDataCacheService: service(),
  customerService: service(),
  tagName: null,
  allowTrials: true,
  app: readOnly('appService.app'),
  customer: alias('customerService.customer'),
  products: alias('customerService.products'),
  changedProducts: filterBy('products', 'changedPlan'),
  stripev3: service('stripev3'),
  stripePaymentMethodId: null,
  plans: flatMap('products', 'plans'),
  activePlans: filterBy('plans', 'active'),
  activePlanIds: mapBy('activePlans', 'idAsNumber'),
  productsBeingRemoved: filterBy('products', 'removing'),

  sendNewOrUpdateSubscriptionTask: task(function* (formData) {
    let appId = this.get('app.id');
    let object = 'start-subscription';

    if (this.productsBeingRemoved.length > 0) {
      object = 'downgrade-subscription';
    } else if (this.get('customer.hasActiveSubscription')) {
      object = 'upgrade-subscription';
    }

    let context = 'change_plan_modal';

    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object,
      planIds: this.activePlanIds,
      context,
    });

    formData = { ...formData, source: 'confirm-component' };

    let params = {
      url: `/ember/customers/${appId}/update_subscription`,
      type: 'post',
      contentType: 'application/json',
      data: JSON.stringify(formData),
    };

    yield ajax(params)
      .then(() => {
        this.purchaseAnalyticsService.trackEvent({
          action: 'completed',
          object,
          planIds: this.activePlanIds,
          featureName: this.featureName,
          context,
        });

        this.notificationsService.notifyConfirmation(
          'You have successfully updated your subscription!',
        );

        this.customerService.reportProductChangesForAnalytics({
          place: 'upgrade_modal',
          paywalled_feature: this.featureName,
        });

        this.customerService.loadData({ fetchPrices: true, source: 'confirm-component' });
      })
      .catch((error) => {
        this.purchaseAnalyticsService.trackEvent({
          action: 'failed',
          object,
          planIds: this.activePlanIds,
          featureName: this.featureName,
          context,
        });
        throw error;
      });
  }).enqueue(),

  postTask: null,

  updateSubscriptionFormData() {
    let appId = this.get('app.id');
    let formData = {
      plan_ids: this.get('customerService.activePlanIds'),
      app_id: appId,
      allow_trials: this.allowTrials,
    };
    return formData;
  },

  isLocationDataValid() {
    this._resetInvalidLocationDataErrors();
    if (this.hasInvalidLocationData && !this.get('app.hasActiveSubscription')) {
      this._showInvalidLocationDataErrors();
      return false;
    }
    return true;
  },

  sendPayment() {
    let formData = this.updateSubscriptionFormData();
    let inboxWasActive = this.get('app.inboxIsActive');
    this.sendNewOrUpdateSubscriptionTask
      .perform(formData)
      .then(() =>
        this.app.reload().then(() => {
          this.modelDataCacheService.clear();
          this.updateAppAdminInboxAccess(inboxWasActive);
          this.postTask();
        }),
      )
      .catch((err) => {
        let message = 'Please contact our sales team.';
        if (err && err.jqXHR && err.jqXHR.responseJSON && err.jqXHR.responseJSON[0]) {
          message = err.jqXHR.responseJSON[0];
        }
        if (message.includes('stripe') || message.includes('Stripe')) {
          message =
            'We are currently experiencing an issue with our payment provider. Please try again later.';
        }
        this.notificationsService.notifyError(
          `There was an error updating your subscription: ${message}`,
        );
      });
  },

  updateAppAdminInboxAccess(inboxWasActive) {
    if (!inboxWasActive && this.get('app.inboxIsActive')) {
      return this.set('app.currentAdmin.currentAppPermissions.has_inbox_access', true);
    }
  },
  updateSubscription: action(function () {
    this.sendPayment();
  }),
});
