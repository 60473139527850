/* import __COLOCATED_TEMPLATE__ from './multi-workspace-contracted-under-limit.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { type TooltipRow } from 'embercom/components/billing/usage/tooltips-new/shared-components/tooltip-item';
import type { TooltipComponentSignature } from '../../schemas';

export default class MultiWorkspaceContractedUnderLimitComponent extends Component<TooltipComponentSignature> {
  @service declare intl: IntlService;
  @service declare customerService: $TSFixMe;

  get inTrial() {
    return this.customerService.customer?.inTrial;
  }

  get usageForThisWorkspaceThisPeriod() {
    let res = [
      {
        label: this.intl.t(
          'billing.summary.usage-tooltip.multi_workspace.usage_for_this_workspace_this_period',
        ),
        muted: true,
        noPadding: true,
        strong: true,
      },
      { label: `${this.args.finUsageThisPeriod}` },
    ];
    return res as TooltipRow[];
  }

  get usageForAllWorkspacesThisPeriod() {
    let res = [
      {
        label: this.intl.t(
          'billing.summary.usage-tooltip.multi_workspace.usage_for_all_workspaces_this_period',
        ),
        muted: true,
        noPadding: true,
        strong: true,
      },
      { label: this.args.totalUsage },
    ];
    return res as TooltipRow[];
  }

  get totalCumulativeUsageForAllWorkspacesSinceBucketStart() {
    let finBucketsCumulativeUsageForAllWorkspaces = this.intl.formatNumber(
      this.args.finBucketsCumulativeUsageForAllWorkspaces,
      {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      },
    );

    let key =
      this.args.bucketDurationInMonths === 12
        ? 'billing.summary.usage-tooltip.multi_workspace.total_usage_period_year'
        : 'billing.summary.usage-tooltip.multi_workspace.total_usage_period_quarter';
    let res = [
      {
        label: this.intl.t(key),
        muted: true,
        noPadding: true,
        strong: true,
      },
      { label: finBucketsCumulativeUsageForAllWorkspaces },
    ];
    return res as TooltipRow[];
  }

  get inSubscription() {
    let key =
      this.args.bucketDurationInMonths === 12
        ? 'billing.summary.usage-tooltip.multi_workspace.prepaid_usage_this_year'
        : 'billing.summary.usage-tooltip.multi_workspace.prepaid_usage_this_quarter';
    let res = [
      {
        label: this.intl.t(key),
        muted: true,
        noPadding: true,
        strong: true,
      },
      { label: `${this.args.formattedFinBucketInContract}` },
    ];
    return res as TooltipRow[];
  }

  get noAdditionalUsage() {
    let res = [
      {
        label: this.intl.t('billing.summary.usage-tooltip.no_additional_usage'),
        muted: true,
        noPadding: true,
      },
    ];
    return res as TooltipRow[];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Usage::TooltipsNew::BucketedMetrics::MultiWorkspaceContractedUnderLimit': typeof MultiWorkspaceContractedUnderLimitComponent;
  }
}
