/* import __COLOCATED_TEMPLATE__ from './section-list-component.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { schedule } from '@ember/runloop';
import $ from 'jquery';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { computed, action } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import SortableList from 'embercom/components/mixins/articles/sortable-list';
import ErrorHandling from 'embercom/components/mixins/articles/error-handling';
import EventTracking from 'embercom/components/mixins/articles/event-tracking';
import { moveElementInOrderedList } from 'embercom/lib/reordering';
import { task } from 'ember-concurrency';

export default Component.extend(ErrorHandling, EventTracking, SortableList, {
  store: service(),
  intl: service(),
  helpCenterService: service(),
  notificationsService: service(),
  intercomConfirmService: service(),
  sortableGroup: 'educate-articles',
  draggableSelector: '.articles__list__article-card',
  draggableHandleSelector: '.educate__draggable-handle',
  helpCenterSite: readOnly('helpCenterService.site'),

  activeContent: computed('section.localizedContent.[]', 'activeLocale', function () {
    return (
      this.section.localizedContent.findBy('localeId', this.activeLocale) ||
      this.section.localizedContent.createFragment({
        localeId: this.activeLocale,
        name: '',
      })
    );
  }),

  getArticle(articleId) {
    return this.section.store.peekRecord('article-multilingual', articleId);
  },

  onDraggingEnd() {
    $('.o__drop-target').removeClass('o__drop-target');
    this.showSectionReorderWarning();
  },

  showSectionReorderWarning() {
    if (this.helpCenterService.showSectionReorderWarning) {
      this.notificationsService.removeNotification('sectionReorderWarning');
      this.notificationsService.notifyWarningWithButton(
        this.intl.t('articles.collections.reordering.title'),
        {
          label: this.intl.t('articles.collections.reordering.label'),
          action: this.sectionReorderWarningButton.bind(this),
        },
        5000,
        'sectionReorderWarning',
      );
    }
  },

  sectionReorderWarningButton() {
    this.helpCenterService.set('showSectionReorderWarning', false);
    this.notificationsService.removeNotification('sectionReorderWarning');
  },

  onMove(event) {
    // When removing the class we are not scoping to any specific element since we may have dragged an article over the collections list - added the
    // class and now this event is us still dragging but moving the article back over a section.
    //
    $('.o__drop-target').removeClass('o__drop-target');
    if (event.related) {
      $(event.related).addClass('o__drop-target');
    }

    return true; // Means that we will allow a drop here
  },

  shouldIncrementCount(folder, article) {
    if (folder.get('isSection') && folder.get('parent.id') === article.get('collection.id')) {
      return false;
    }
    return !(folder.get('isCollection') && folder.get('id') === article.get('collection.id'));
  },

  onDragToOtherList(event) {
    // This is a bit of a hack but it's needed to work around the 'Sortable' component's behavior (when you drag and item and drop it into another
    // list it adds the element and doesn't allow you to override this behavior)
    //
    $(event.item).remove();

    let article = this.getArticle(event.item.dataset.articleId);
    let section = this.section;
    moveElementInOrderedList(this.get('section.sortedArticles'), article, event.newIndex);
    let incrementCount = this.shouldIncrementCount(section, article);
    if (article.get('jsonBlocks')) {
      article.set('jsonBlocks', undefined);
    }
    article.setProperties({
      folder: section,
      order: event.newIndex,
    });

    article
      .save()
      .then(() => {
        this.trackEducateArticleEvent(article, { action: 'added', place: section.get('type') });
        if (incrementCount) {
          article.incrementProperty('collection.count');
        }
      })
      .catch((response) => {
        this.handleServerError(response, 'There was a problem moving your article');
      });
  },

  onDraggingUpdate(event) {
    $('.o__drop-target', this.element).removeClass('o__drop-target');

    // The reordering executed by the Sortable plugin manipulates the DOM and leaves Ember confused.
    // We can trigger a re-render by removing the article from this list (by clearing it's folder), and then by adding it back - in a separate execution of
    // the run loop (hence the run.later).
    //
    let article = this.getArticle(event.item.dataset.articleId);
    article.set('folder', null);

    schedule('afterRender', () => {
      $(event.item).remove(); // Remove the weird unbound DOM element that the sortable plugin created
      article.set('folder', this.section);
      moveElementInOrderedList(this.get('section.sortedArticles'), article, event.newIndex);
      article
        .reorder(event.newIndex)
        .then(() => {
          this.trackEducateArticleEvent(article, {
            action: 'reordered',
            place: article.get('folder.type'),
          });
        })
        .catch((response) => {
          this.handleServerError(response, 'There was a problem updating your article order');
        });
    });
  },

  trackArticleEvent(article, originalCollection) {
    let metadata = { action: 'removed' };
    if (originalCollection.get('isCollection')) {
      metadata.from_collection_id = originalCollection.get('id');
      metadata.place = 'collection';
    } else {
      metadata.from_section_id = originalCollection.get('id');
      metadata.place = 'section';
    }
    this.trackEducateArticleEvent(article, metadata);
  },

  removeArticle: task(function* (article, collection) {
    let home = this.store.peekRecord('articles/article-group', 'home');
    article.set('folder', home);
    yield article
      .save()
      .then(() => {
        this.trackArticleEvent(article, collection);
        collection.reload();
      })
      .catch((response) => {
        this.handleServerError(
          response,
          'There was a problem removing the article from this collection',
        );
        article.rollbackAttributes();
        collection.reload();
      });
  }).enqueue(),

  shouldRemoveArticle: action(async function (article, collection) {
    let confirmOptions = {
      title: this.intl.t('articles.collections.confirm_delete.article.title'),
      confirmButtonText: this.intl.t('articles.collections.confirm_delete.article.confirm_button'),
      cancleButtonText: this.intl.t('articles.collections.confirm_delete.cancel'),
      body: this.intl.t('articles.collections.confirm_delete.article.body'),
    };
    let isConfirmed = await this.intercomConfirmService.confirm(confirmOptions);
    if (isConfirmed) {
      this.removeArticle.perform(article, collection);
    }
  }),

  actions: {
    reorderSection(direction) {
      let section = this.section;
      let collection = section.get('parent');
      let siblings = collection.get('sortedSections');
      let oldIndex = siblings.indexOf(section);
      let newIndex = oldIndex + (direction === 'down' ? 1 : -1);

      let targetSibling = siblings[newIndex];
      let movingSibling = siblings[oldIndex];
      let tmpOrder = movingSibling.get('order');

      movingSibling.set('order', targetSibling.get('order'));
      targetSibling.set('order', tmpOrder);

      section
        .reorder(newIndex)
        .then(() => {
          this.trackEducateCollectionOrSectionEvent(section, {
            action: 'reordered',
            place: 'collection',
          });
        })
        .catch((response) => {
          this.handleServerError(response, 'There was a problem moving your section');
        });
    },
  },
});
