/* import __COLOCATED_TEMPLATE__ from './regular-migration-hero-banner.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

interface Args {
  talkToSales: () => void;
  nextInvoiceDate: string;
  shouldShowImprovedFlow: boolean;
}

interface Signature {
  Args: Args;
}

export default class RegularMigrationHeroBannerComponent extends Component<Signature> {
  @service declare appService: any;
  @service declare customerService: any;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::StripeMigration::RegularMigrationHeroBanner': typeof RegularMigrationHeroBannerComponent;
  }
}
