/* import __COLOCATED_TEMPLATE__ from './invite-teammates-form.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */

import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import { TeammatesInviter } from 'embercom/lib/teammates-inviter';
import type GuideLibraryService from 'embercom/services/guide-library-service';
import type Store from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import { getOwner } from '@ember/application';
import type Quote from 'embercom/models/quote';
import { Metric } from 'embercom/models/data/pricing/metric-types';
import { DEFAULT_REQUEST_PARAMS, type RequestParams } from 'embercom/services/quote-service';
import type QuoteService from 'embercom/services/quote-service';
import { taskFor } from 'ember-concurrency-ts';
import { PRICING_5_X_CORE_ADVANCED_ID, PRICING_5_X_CORE_PLANS } from 'embercom/lib/billing';
import { dropTask, keepLatestTask } from 'ember-concurrency-decorators';
import type Charge from 'embercom/models/billing/price/charge';
import type Contract from 'embercom/models/billing/contract';
import { PricingModelIdentifier } from 'embercom/lib/billing/pricing-models';

export interface Args {
  afterInviteCallback?: () => void;
}

const QuoteRequestParams = (id: number, identifier?: PricingModelIdentifier): RequestParams => ({
  ...DEFAULT_REQUEST_PARAMS,
  planIds: [id],
  source: 'checkout-invite-teammates-form',
  ...(identifier ? { pricingModelIdentifier: identifier } : {}),
});

export default class InviteTeammatesForm extends Component<Args> {
  @service declare appService: any;
  @service declare router: RouterService;
  @service declare store: Store;
  @service declare guideLibraryService: GuideLibraryService;
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare customerService: $TSFixMe;
  @service declare quoteService: QuoteService;

  @tracked contract: Contract | undefined;

  teammatesInviter: TeammatesInviter;

  constructor(owner: unknown, args: any) {
    super(owner, args);

    this.teammatesInviter = new TeammatesInviter(getOwner(this));
    taskFor(this.loadCustomerData).perform();
  }

  get app() {
    return this.appService.app;
  }

  @keepLatestTask
  *loadCustomerData() {
    let requestParams: RequestParams[] = [];

    // Add early stage plans
    [
      PricingModelIdentifier.PRICING_5_1_EARLY_STAGE,
      PricingModelIdentifier.PRICING_5_1_EARLY_STAGE_FREE,
    ].forEach((identifier) =>
      requestParams.push(QuoteRequestParams(Number(PRICING_5_X_CORE_ADVANCED_ID), identifier)),
    );

    // Add 5.x core plans
    let planIds = PRICING_5_X_CORE_PLANS.map((p) => Number(p));
    planIds.forEach((id) => requestParams.push(QuoteRequestParams(id)));

    yield Promise.all([
      taskFor(this.customerService.ensureDataIsLoaded).perform({ fetchPrices: false }),
      taskFor(this.quoteService.getQuotes).perform(requestParams),
      taskFor(this.loadWorkspacesContract).perform(),
    ]);
  }

  @dropTask
  *loadWorkspacesContract() {
    this.contract = yield this.store.findRecord('billing/contract', this.app.id);
  }

  get loading() {
    return taskFor(this.loadCustomerData).isRunning || this.quoteService.loading;
  }

  get activePlanId(): number | undefined {
    let {
      activePlanIds,
      isPricing5CorePlan,
    }: {
      activePlanIds: number[];
      isPricing5CorePlan: (planId: number) => boolean;
    } = this.customerService;

    return activePlanIds.find((id) => isPricing5CorePlan(id));
  }

  get isBilledAnnually(): boolean {
    return this.customerService.customer?.subscription.isBilledAnnually ?? false;
  }

  get quote(): Quote | undefined {
    if (this.quoteService.loading || this.quoteService.quotes.length === 0 || !this.activePlanId) {
      return undefined;
    }

    let pricingModelIdentifier = PricingModelIdentifier.PRICING_5_1;
    if (this.isEarlyStage) {
      pricingModelIdentifier = this.isEarlyStagePartner
        ? PricingModelIdentifier.PRICING_5_1_EARLY_STAGE_FREE
        : PricingModelIdentifier.PRICING_5_1_EARLY_STAGE;
    }

    return this.quoteService.getQuoteByPlanAndPricingModel({
      planIds: [this.activePlanId],
      pricingModelIdentifier,
    });
  }

  get price(): string | undefined {
    if (!this.quote || !this.activePlanId) {
      return undefined;
    }

    let price = this.quote.fullSeatPrice(this.activePlanId, Metric.core_seat_count);
    return this.intl.formatNumber(price, {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    });
  }

  get isEarlyStage(): boolean {
    return this.customerService.customer?.currentEarlyStageCustomer ?? false;
  }

  get isEarlyStagePartner(): boolean {
    return this.isEarlyStage && (this.customerService.customer?.earlyStagePartner ?? false);
  }

  get seatsLeft() {
    return this.baseUsage - this.actualUsage;
  }

  get baseUsage(): number {
    if (!this.quote || !this.activePlanId) {
      return 0;
    }

    return this.quote.metricBaseUsage(this.activePlanId, Metric.core_seat_count) ?? 0;
  }

  get actualUsage() {
    if (!this.quote || !this.activePlanId) {
      return 0;
    }

    if (this.contract?.totalUsage[Metric.core_seat_count]) {
      return this.contract.totalUsage[Metric.core_seat_count];
    }

    let charge: Charge | undefined = this.quote.breakdownPricingMetric(
      this.activePlanId,
      Metric.core_seat_count,
    );

    return charge?.actual_usage ?? 0;
  }

  get formDescription() {
    if (this.loading) {
      return '';
    }

    if (this.isEarlyStage) {
      return this.seatsLeft > 0
        ? this.intl.t('checkout.invite_teammates.early_stage_with_seats', {
            htmlSafe: true,
            fullSeatCount: this.seatsLeft,
            earlyStageArticleUrl:
              'https://www.intercom.com/help/en/articles/9361928-early-stage-program#h_aa03eb1940',
          })
        : this.intl.t('checkout.invite_teammates.early_stage_without_seats', {
            htmlSafe: true,
            earlyStageArticleUrl:
              'https://www.intercom.com/help/en/articles/9361928-early-stage-program#h_aa03eb1940',
          });
    }

    if (this.isBilledAnnually && this.seatsLeft > 0) {
      return this.intl.t('checkout.invite_teammates.bill_with_seats', {
        htmlSafe: true,
        price: this.price,
        fullSeatCount: this.seatsLeft,
      });
    }

    return this.intl.t('checkout.invite_teammates.bill_without_seats', {
      htmlSafe: true,
      price: this.price,
    });
  }

  @action async performInvite() {
    try {
      let numberOfTeammatesInvited = this.teammatesInviter.validEmails.length;
      let pendingTeammatesLink = this.router.urlFor('apps.app.settings.workspace.teammates', {
        queryParams: {
          tab: 'invited',
        },
      });
      await this.teammatesInviter.performInvite();

      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'onboarding.home.steps.invite-teammates.quick-invite-modal.success-banner-post-trial-invite',
          {
            numberOfTeammatesInvited,
            pendingTeammatesLink,
            htmlSafe: true,
          },
        ),
      );

      this.args.afterInviteCallback?.();
    } catch (error) {
      this.notificationsService.notifyError(this.intl.t('onboarding.home.developer-invite.error'));
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Checkout::InviteTeammatesForm': typeof InviteTeammatesForm;
    'checkout/invite-teammates-form': typeof InviteTeammatesForm;
  }
}
