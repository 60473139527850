/* import __COLOCATED_TEMPLATE__ from './contracted-over-limit.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { type TooltipRow } from 'embercom/components/billing/usage/tooltips-new/shared-components/tooltip-item';
import type { TooltipComponentSignature } from '../schemas';

export default class ContractedOverLimitComponent extends Component<TooltipComponentSignature> {
  @service declare intl: IntlService;
  @service declare customerService: $TSFixMe;

  get inTrial() {
    return this.customerService.customer?.inTrial;
  }

  get additionalText() {
    return this.intl.t('billing.summary.usage-tooltip.price_per_additional_seat_title');
  }

  get additionalUsageRow() {
    let res = [
      {
        label: this.intl.t('billing.summary.usage-tooltip.additional_usage'),
        muted: true,
        noPadding: true,
        strong: true,
      },
      {
        label: `${this.args.currentUsage - this.args.allowanceForBillingPeriod}`,
      },
    ];
    return res as TooltipRow[];
  }

  get prorationExplainer() {
    return [
      {
        label: this.intl.t('billing.summary.usage-tooltip.proration_explainer'),
        muted: true,
        noPadding: true,
      },
    ] as TooltipRow[];
  }

  get trialDisclaimerRow() {
    return [
      {
        label: this.intl.t('billing.summary.usage-tooltip.trial_disclaimer'),
        muted: true,
        noPadding: true,
      },
    ] as TooltipRow[];
  }

  get proratedTotalRow() {
    return [
      {
        label: this.intl.t('billing.summary.usage-tooltip.total'),
        muted: true,
        noPadding: true,
        strong: true,
        value: this.args.currentMetricChargeDue,
      },
    ] as TooltipRow[];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Usage::TooltipsNew::SeatMetrics::ContractedOverLimit': typeof ContractedOverLimitComponent;
  }
}
