/* import __COLOCATED_TEMPLATE__ from './panel.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { DEFAULT_MESSENGER_COLORS } from 'embercom/lib/messenger-settings-defaults';
import {
  actionOptions,
  actionTargetOptions,
  defaultReactions,
  positionOptions,
  styleOptions,
} from 'embercom/models/banners/banner';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

const UNASSIGNED_SENDER = -1;

export default class ControlPanel extends Component {
  @service appService;
  @service contentEditorService;
  @service intl;

  actionOptions = actionOptions;
  positionOptions = positionOptions;
  styleOptions = styleOptions;

  constructor() {
    super(...arguments);
    this.args.banner.urlButtonColor = this.args.banner.urlButtonColor || '#f0f1ef';
  }

  get app() {
    return this.appService.app;
  }

  bannerActions = [
    { text: this.intl.t('outbound.banners.actions.none'), value: actionOptions.no_action },
    {
      text: this.intl.t('outbound.banners.actions.open-a-url'),
      value: actionOptions.open_a_url,
      icon: 'globe',
    },
    {
      text: this.intl.t('outbound.banners.actions.open-url-button'),
      value: actionOptions.open_url_button,
      icon: 'globe',
      stamp: {
        text: this.intl.t('outbound.all.shared.new'),
        color: 'sky',
      },
    },
    {
      text: this.intl.t('outbound.banners.actions.ask-for-reactions'),
      value: actionOptions.reaction,
      icon: 'lwr-happy',
    },
    {
      text: this.intl.t('outbound.banners.actions.collect-visitor-emails'),
      value: actionOptions.email_collector,
      icon: 'email',
    },
    {
      text: this.intl.t('outbound.banners.actions.launch-a-product-tour'),
      value: actionOptions.product_tour,
      icon: 'product-tours',
    },
  ];

  colorOptions = DEFAULT_MESSENGER_COLORS;

  get activeLocalizedBannerContent() {
    // Need to use optional chaining here due to an Ember bug.
    // https://github.com/emberjs/ember.js/issues/18969
    return this.args.banner?.localizedBannerContents.firstObject;
  }

  get displayInfoBanner() {
    return !this.args.banner.showDismissButton && !this.contentEditorService.isSeriesMode;
  }

  get openInNewTab() {
    return this.args.banner.actionTarget === actionTargetOptions.new_tab;
  }

  get selectedBannerImage() {
    if (this.activeLocalizedBannerContent.fromId === UNASSIGNED_SENDER) {
      return 'no_image';
    }
    return 'sender';
  }

  get settingsHeaderText() {
    return this.intl.t('outbound.banners.settings-header');
  }

  @action updateBannerAction(bannerAction) {
    this.args.banner.action = bannerAction;

    if (bannerAction === actionOptions.reaction && this.args.banner.reactionSet.length === 0) {
      this.args.banner.reactionSet = defaultReactions;
    }

    if (
      bannerAction !== actionOptions.open_a_url &&
      bannerAction !== actionOptions.open_url_button
    ) {
      this.activeLocalizedBannerContent.actionHref = null;
      this.activeLocalizedBannerContent.actionTitle = null;
    }
  }

  @action setSelectedBannerOption(bannerImageOption) {
    if (bannerImageOption === 'no_image') {
      this.activeLocalizedBannerContent.fromId = UNASSIGNED_SENDER;
    } else {
      this.activeLocalizedBannerContent.fromId = this.app.currentAdmin.id;
    }
  }

  @action toggleOpenInNewTab() {
    if (this.args.banner.actionTarget === actionTargetOptions.current_tab) {
      this.args.banner.actionTarget = actionTargetOptions.new_tab;
    } else {
      this.args.banner.actionTarget = actionTargetOptions.current_tab;
    }
  }
}
