/* import __COLOCATED_TEMPLATE__ from './cardless-trial-summary.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

interface Args {
  newSettings?: boolean;
}
export default class CardlessTrialSummary extends Component<Args> {
  @service declare appService: any;
  @service declare customerService: any;

  get app() {
    return this.appService.app;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Summary::CardlessTrialSummary': typeof CardlessTrialSummary;
    'billing/summary/cardless-trial-summary': typeof CardlessTrialSummary;
  }
}
