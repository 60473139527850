/* import __COLOCATED_TEMPLATE__ from './begin-trial-guide-lib.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */

import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type CardlessTrialService from 'embercom/services/cardless-trial-service';
import type GuideLibraryService from 'embercom/services/guide-library-service';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';

const WARNING_COLORS = 'bg-notice-container border-notice-border';
const PAYWALL_COLORS = 'bg-paywall-container border-paywall-border';
const DEFAULT_COLORS = 'bg-beta-container border-beta-border';

interface Args {
  showUpgradeButton: boolean;
  onUpgrade: () => void;
  transitionToOnboardingHome: () => void;
  showContinueButton: boolean;
  showExtendTrialCTA: boolean;
  requestTrialExtension: () => void;
  validEarlyStageApplicant: boolean;
  transitionToEarlyStageCheckout: () => void;
  canUseEarlyStageDetection: boolean;
  triggerAnalytics: () => void;
  upgradeButtonLabel: string;
  trialPlanName: string;
  isStandaloneApp: boolean;
}

interface Signature {
  Args: Args;
  Element: never;
}

export default class BeginTrialGuideLib extends Component<Signature> {
  @service declare guideLibraryService: GuideLibraryService;
  @service declare cardlessTrialService: CardlessTrialService;
  @service declare appService: $TSFixMe;
  @service declare finStandaloneService: FinStandaloneService;

  get isStandaloneApp() {
    return this.appService.app.isStandaloneApp;
  }

  get daysLeftOfTrial() {
    return this.cardlessTrialService.daysLeftOfTrial;
  }

  // for standalone apps, when fin is live and trial is nearing its end show a warning banner
  get showWarning() {
    let trialEnding = this.daysLeftOfTrial <= 3 && this.daysLeftOfTrial >= 0;
    return this.isStandaloneApp && this.finStandaloneService.finIsLive && trialEnding;
  }

  get bannerColor() {
    if (this.showWarning) {
      return WARNING_COLORS;
    } else if (this.daysLeftOfTrial >= 0) {
      return DEFAULT_COLORS;
    } else if (this.isStandaloneApp) {
      return PAYWALL_COLORS;
    } else {
      return DEFAULT_COLORS;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Banners::BeginTrialGuideLib': typeof BeginTrialGuideLib;
  }
}
