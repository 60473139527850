/* import __COLOCATED_TEMPLATE__ from './contracted-under-limit.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { type TooltipRow } from 'embercom/components/billing/usage/tooltips-new/shared-components/tooltip-item';
import type { TooltipComponentSignature } from '../schemas';

export default class ContractedUnderLimitComponent extends Component<TooltipComponentSignature> {
  @service declare intl: IntlService;
  @service declare customerService: $TSFixMe;

  get inTrial() {
    return this.customerService.customer?.inTrial;
  }

  get totalUsage() {
    let key =
      this.args.bucketDurationInMonths === 12
        ? 'billing.summary.usage-tooltip.total_usage_period_year'
        : 'billing.summary.usage-tooltip.total_usage_period_quarter';
    let formattedFinBucketsCumulativeUsage = this.intl.formatNumber(
      this.args.finBucketsCumulativeUsage,
      {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      },
    );
    let res = [
      {
        label: this.intl.t(key),
        muted: true,
        noPadding: true,
        strong: true,
      },
      { label: `${formattedFinBucketsCumulativeUsage}` },
    ];
    return res as TooltipRow[];
  }

  get inSubscription() {
    let key =
      this.args.bucketDurationInMonths === 12
        ? 'billing.summary.usage-tooltip.in_subscription_year'
        : 'billing.summary.usage-tooltip.in_subscription_quarter';
    let res = [
      {
        label: this.intl.t(key),
        muted: true,
        noPadding: true,
        strong: true,
      },
      { label: `${this.args.formattedFinBucketInContract}` },
    ];
    return res as TooltipRow[];
  }

  get noAdditionalUsage() {
    let res = [
      {
        label: this.intl.t('billing.summary.usage-tooltip.no_additional_usage'),
        muted: true,
        noPadding: true,
      },
    ];
    return res as TooltipRow[];
  }

  get trialDisclaimer() {
    return [
      {
        label: this.intl.t('billing.summary.usage-tooltip.trial_disclaimer'),
        muted: true,
        noPadding: true,
      },
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Usage::TooltipsNew::BucketedMetrics::ContractedUnderLimit': typeof ContractedUnderLimitComponent;
  }
}
