/* import __COLOCATED_TEMPLATE__ from './seat-selector.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type QuoteService from 'embercom/services/quote-service';
import { ModalTypes } from './subscription-builder';
import { action } from '@ember/object';

interface Args {
  header: string;
  loading: boolean;
  isOverageSeats: boolean;
  seatNumber: number;
  overageSavings: number;
  minimumSeatNumber: number;
  maximumCopilotSeats?: number;
  priceTranslationString: string;
  updateSeatNumber: (seatNumber: number) => void;
  moveOverageSeats: () => void;
  showModal: (modalType: ModalTypes) => void;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

export default class SeatSelector extends Component<Signature> {
  @service declare intl: any;
  @service declare quoteService: QuoteService;

  @action
  updateSeatNumber(value: number) {
    let seatValue = Number(value);
    let seatNumber: number;
    if (this.args.isOverageSeats) {
      if (seatValue < this.args.seatNumber) {
        this.args.showModal(ModalTypes.RemovingMonthlySeats);
      } else {
        this.args.showModal(ModalTypes.AddingMonthlySeats);
      }
      seatNumber = this.args.seatNumber;
    } else if (seatValue < this.args.minimumSeatNumber) {
      this.args.showModal(ModalTypes.RemoveYearlySeats);
      seatNumber = this.args.minimumSeatNumber;
    } else if (this.args.maximumCopilotSeats && seatValue > this.args.maximumCopilotSeats) {
      this.args.showModal(ModalTypes.AddingTooManyCopilotSeats);
      seatNumber = this.args.maximumCopilotSeats;
    } else if (seatValue > 100) {
      seatNumber = 100;
    } else {
      seatNumber = seatValue;
    }
    this.args.updateSeatNumber(seatNumber);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::AnnualManageSubscription::SeatSelector': typeof SeatSelector;
  }
}
