/* import __COLOCATED_TEMPLATE__ from './action.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import Component from '@glimmer/component';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

interface Args {
  type: 'link' | 'button' | 'wistia_link';
  label: string;
  icon: InterfaceIconName;
  analyticsId: string;
  onClick?: () => void;
  recordAnalyticsEvent: (action: string, analyticsId: string, bannerId: string) => void;
  buttonType?: 'primary' | 'secondary';
  buttonClass?: string;
  wistiaId?: string;
}

interface Signature {
  Args: Args;
}

export default class Action extends Component<Signature> {
  get onClick() {
    if (this.args.onClick) {
      return this.args.onClick;
    }
    return () => null;
  }

  get buttonType() {
    return this.args.buttonType || 'primary';
  }

  get wistiaId(): string {
    return this.args.wistiaId || '';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Common::HeroBanner::Action': typeof Action;
    'common/hero-banner/action': typeof Action;
  }
}
