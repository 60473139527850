/* import __COLOCATED_TEMPLATE__ from './create-help-center-modal.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { CAN_MANAGE_ARTICLES_PERMISSION } from 'embercom/lib/articles/constants';
import type HelpCenterSite from 'embercom/models/help-center-site';
import type Store from '@ember-data/store';
import { isBlank } from '@ember/utils';

interface Args {
  transitionToSettingsPage: (helpCenter: HelpCenterSite | null) => void;
  onModalClose: () => void;
}

export default class CreateHelpCenterModal extends Component<Args> {
  @service declare intl: IntlService;
  @service declare permissionsService: any;
  @service declare store: Store;
  @service declare helpCenterService: any;
  @service declare notificationsService: any;
  @service declare appService: any;

  @tracked newHelpCenterIdentifier = '';
  @tracked newHelpCenterName = '';
  @tracked creatingHelpCenter = false;

  validIdentifierRegex = /^[a-zA-Z0-9_-]+$/;

  get isInvalid() {
    return this.isIdentifierInvalid || this.isNameInvalid;
  }

  get isIdentifierEmpty() {
    return isBlank(this.newHelpCenterIdentifier);
  }

  get isIdentifierInvalid() {
    return this.isIdentifierEmpty || this.identifierHasInvalidCharacters;
  }

  get identifierHasInvalidCharacters() {
    return !this.validIdentifierRegex.test(this.newHelpCenterIdentifier);
  }

  get identifierErrorMessage() {
    if (this.isIdentifierEmpty) {
      return this.intl.t('articles.shared.create-modal.identifier-empty');
    } else {
      return this.intl.t('articles.shared.create-modal.invalid-characters');
    }
  }

  get isNameInvalid() {
    return this.newHelpCenterName.length === 0 || isBlank(this.newHelpCenterName);
  }

  get nameErrorMessage() {
    return this.intl.t('articles.shared.create-modal.name-empty');
  }

  get paywallFeatureKey() {
    if (this.appService.app.showMultiHelpCenterPaywalling) {
      return 'messenger_multibrand';
    }

    return '';
  }

  @action onNameChange(event: KeyboardEvent) {
    let target = event.target as HTMLInputElement;
    let name = target.value;
    let normalisedName = this.nameToHyphenatedIdentifier(name);
    this.newHelpCenterIdentifier = normalisedName;
  }

  private nameToHyphenatedIdentifier(name: string) {
    return name.trim().toLowerCase().split(/\s+/).join('-');
  }

  @action async createHelpCenter() {
    await this.permissionsService.checkPermission(CAN_MANAGE_ARTICLES_PERMISSION);

    this.creatingHelpCenter = true;
    let creatingAdminId = this.appService.app.currentAdmin.id;
    let helpCenterSite = await this.helpCenterService.createHelpCenter.perform({
      identifier: this.newHelpCenterIdentifier,
      name: this.newHelpCenterName,
      creatingAdminId,
    });

    if (!helpCenterSite) {
      // an error in saving, most likely the identifier is already taken
      this.creatingHelpCenter = false;
      return;
    }

    this.notificationsService.notifyConfirmation(
      this.intl.t('new-settings.submenu.helpcenter.create-help-center-success'),
    );

    this.args.onModalClose();
    this.creatingHelpCenter = false;

    this.args.transitionToSettingsPage(helpCenterSite);
    await this.helpCenterService.forceFetchSite(helpCenterSite.id);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Shared::CreateHelpCenterModal': typeof CreateHelpCenterModal;
    'articles/shared/create-help-center-modal': typeof CreateHelpCenterModal;
  }
}
