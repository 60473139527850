/* import __COLOCATED_TEMPLATE__ from './fonts.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import Component from '@glimmer/component';
import type Font from 'embercom/models/customization-options/global-options/font';
import {
  AVAILABLE_GOOGLE_FONTS,
  DEFAULT_FONT,
} from 'embercom/models/customization-options/global-options/font';
import { type UploadHelper } from 'embercom/objects/media/media-helper';
import type HelpCenterNavigationService from 'embercom/services/help-center-navigation-service';
import { inject as service } from '@ember/service';
import { isEmpty } from 'underscore';

import type IntlService from 'embercom/services/intl';

/* eslint-disable @intercom/intercom/no-bare-strings */
// Disabling bare strings for this const because it's a list of font names, not translated strings.
/* eslint-enable @intercom/intercom/no-bare-strings */

interface Args {
  fontOptions: Font;
  uploadHelper: UploadHelper;
}

export default class Fonts extends Component<Args> {
  @service declare helpCenterNavigationService: HelpCenterNavigationService;

  @service declare intl: IntlService;

  get availableFonts() {
    let customFontFamilyOptions = this.args.fontOptions.customFontFamilyOptions.map((option) => ({
      text: option.fontFamily,
      value: option.fontFamily,
    }));
    let googleFontFamilyOptions = AVAILABLE_GOOGLE_FONTS.map((option) => ({
      text: option.fontFamily,
      value: option.fontFamily,
      component: 'articles/site/settings/styling/fonts/font-picker-item',
    }));
    let options: any[] = googleFontFamilyOptions;
    if (!isEmpty(customFontFamilyOptions)) {
      options = [
        {
          heading: this.intl.t('articles.settings.redesign.content.font.picker.custom-fonts'),
          items: customFontFamilyOptions,
        },
        {
          heading: this.intl.t('articles.settings.redesign.content.font.picker.google-fonts'),
          items: googleFontFamilyOptions,
        },
      ];
    }
    return options;
  }

  get primaryFontFamily() {
    return this.args.fontOptions?.primary || DEFAULT_FONT;
  }

  get secondaryFontFamily() {
    return this.args.fontOptions?.secondary || DEFAULT_FONT;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::Styling::Fonts': typeof Fonts;
    'articles/site/settings/styling/fonts': typeof Fonts;
  }
}
