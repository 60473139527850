/* import __COLOCATED_TEMPLATE__ from './migration-choice.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import type StripeMigration from 'embercom/models/billing/stripe-migration';
import Component from '@glimmer/component';
import {
  PRICING_5_X_CORE_PLANS,
  PRICING_5_X_ADDON_PLAN_IDS,
  PLAN_DATA,
  PRICING_5_X_CORE_ESSENTIAL_ID,
  PRICING_5_X_CORE_ADVANCED_ID,
  PRICING_5_X_CORE_EXPERT_ID,
  PROACTIVE_SUPPORT_PLUS_BASE_ID,
  FIN_AI_COPILOT_BASE_ID,
} from 'embercom/lib/billing';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import {
  getEstimatedLiteSeatCount,
  getEstimatedCoreSeatCharge,
  getCorePlanBreakdown,
  helpLinkUrlsForStripeMigrations,
  formattedMigrationDate,
  getPspPlanBreakdown,
  getCopilotUsage,
  getCopilotPrice,
} from 'embercom/helpers/billing/migrations-helper';
import type Price from 'embercom/models/price';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { taskFor } from 'ember-concurrency-ts';
import { dropTask } from 'ember-concurrency-decorators';
import { difference, intersection } from 'underscore';
import type MigrationSeatConfiguration from 'embercom/models/billing/migration-seat-configuration';

interface Args {
  migration: StripeMigration;
  pricing5Estimates: {
    essentialEstimate: Price;
    essentialWithPSPEstimate: Price;
    essentialWithCopilotEstimate: Price;
    advancedEstimate: Price;
    advancedWithPSPEstimate: Price;
    advancedWithCopilotEstimate: Price;
    expertEstimate: Price;
    expertWithPSPEstimate: Price;
    expertWithCopilotEstimate: Price;
    essentialWithPSPCopilotEstimate: Price;
    advancedWithPSPCopilotEstimate: Price;
    expertWithPSPCopilotEstimate: Price;
  };
  onSelectionChange: $TSFixMe;
  howWeAssignSeatsLink?: string;
  howWeAssignSeatsContent?: string;
  allFeatures: string[];
  availableFeaturesForApp: string[];
  availableEssentialFeatures: string[];
  availableAdvancedFeatures: string[];
  availableExpertFeatures: string[];
  availablePSPFeatures: string[];
  currentPrices: Price[];
  onProactiveMigrationSave: () => void;
  migrationSeatConfiguration?: MigrationSeatConfiguration;
}

interface PlanEstimates {
  base: Price;
  withPsp: Price;
  withCopilot: Price;
  withCopilotAndPsp: Price;
}

type PlanID = string;

type PlanEstimatesMap = Record<PlanID, PlanEstimates>;

interface Signature {
  Args: Args;
}

export default class BillingMigrationMigrationChoiceComponent extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;

  @tracked showConfirmationModal = false;

  translationKey = 'billing.migrations.migration_overview.migrationChoice';

  get translationsByKeys(): { [index: string]: string } {
    return {
      mainPlan: this.intl.t(PLAN_DATA[this.corePlanId].nameTranslationKey),
      confirmation: this.intl.t(`${this.translationKey}.confirmation_content`, {
        migrationDate: formattedMigrationDate(this.args.migration.migrationDate, this.intl),
      }),
      fullSeat: this.intl.t(`${this.translationKey}.seats_full`, {
        seatCount: this.estimatedCoreSeatCharge.actualUsage,
      }),
      liteSeat: this.intl.t(`${this.translationKey}.seats_lite`, {
        seatCount: this.estimatedLiteSeatCount,
      }),
      summaryCalculation: this.intl.t(`${this.translationKey}.summary_calculation_explanation`, {
        planName: this.intl.t(PLAN_DATA[this.corePlanId].nameTranslationKey),
      }),
      featuresNew: this.intl.t(`${this.translationKey}.summary_new_features_title`, {
        newFeatureCount: this.newFeaturesCount,
        htmlSafe: true,
      }),
      featuresLost: this.intl.t(`${this.translationKey}.summary_new_features_content`, {
        lostFeatureCount: this.featuresLostCount,
      }),
      basePriceExtra: this.intl.t(`${this.translationKey}.base_price_extra`, {
        migrationDate: formattedMigrationDate(this.args.migration.migrationDate, this.intl),
      }),
      summaryDisclaimer: this.intl.t(`${this.translationKey}.summary_disclaimer`, {
        migrationDate: formattedMigrationDate(this.args.migration.migrationDate, this.intl),
        htmlSafe: true,
      }),
    };
  }

  get migrationOverviewTitle() {
    if (this.packageSaved) {
      return this.intl.t('billing.migrations.migration_overview.section_saved');
    } else {
      return this.intl.t('billing.migrations.migration_overview.section_recommended');
    }
  }

  get migrationOverviewSavedByTranslation() {
    if (this.packageSaved) {
      return this.intl.t('billing.migrations.migration_overview.saved_by');
    } else {
      return this.intl.t('billing.migrations.migration_overview.previously_confirmed_by');
    }
  }

  private get packageSaved() {
    return this.args.migration.optIntoDifferentPlans || this.seatsSavedAndConfirmed;
  }

  private get seatsSavedAndConfirmed() {
    return (
      this.args.migration.isConfirmedByCustomer &&
      this.args.migrationSeatConfiguration?.hasCustomerSaved
    );
  }

  get helpLinkUrls() {
    return helpLinkUrlsForStripeMigrations;
  }

  get estimatedBasePrice() {
    let estimatedBasePrice = this.estimatedCoreSeatCharge.price;
    if (this.hasIncludedPsp) {
      estimatedBasePrice += this.pspBasePrice;
    }
    return estimatedBasePrice + getCopilotPrice(this.postMigrationPlanEstimate);
  }

  get currentPrice() {
    return this.args.currentPrices[0];
  }

  get estimatedPriceDifference() {
    return this.postMigrationPlanEstimate.amount - this.currentPrice.amount;
  }

  get monthlyTotalUsageBasedChannelPrice() {
    return this.postMigrationPlanEstimate.amount;
  }

  get postMigrationPlanEstimate() {
    let selectedPlan = this.planEstimatesMap[this.corePlanId];

    if (
      this.addonIds.includes(PROACTIVE_SUPPORT_PLUS_BASE_ID) &&
      this.addonIds.includes(FIN_AI_COPILOT_BASE_ID)
    ) {
      return selectedPlan.withCopilotAndPsp;
    } else if (this.addonIds.includes(PROACTIVE_SUPPORT_PLUS_BASE_ID)) {
      return selectedPlan.withPsp;
    } else if (this.addonIds.includes(FIN_AI_COPILOT_BASE_ID)) {
      return selectedPlan.withCopilot;
    } else {
      return selectedPlan.base;
    }
  }

  private get planEstimatesMap(): PlanEstimatesMap {
    return {
      [PRICING_5_X_CORE_ESSENTIAL_ID]: {
        base: this.args.pricing5Estimates.essentialEstimate,
        withPsp: this.args.pricing5Estimates.essentialWithPSPEstimate,
        withCopilot: this.args.pricing5Estimates.essentialWithCopilotEstimate,
        withCopilotAndPsp: this.args.pricing5Estimates.essentialWithPSPCopilotEstimate,
      },
      [PRICING_5_X_CORE_ADVANCED_ID]: {
        base: this.args.pricing5Estimates.advancedEstimate,
        withPsp: this.args.pricing5Estimates.advancedWithPSPEstimate,
        withCopilot: this.args.pricing5Estimates.advancedWithCopilotEstimate,
        withCopilotAndPsp: this.args.pricing5Estimates.advancedWithPSPCopilotEstimate,
      },
      [PRICING_5_X_CORE_EXPERT_ID]: {
        base: this.args.pricing5Estimates.expertEstimate,
        withPsp: this.args.pricing5Estimates.expertWithPSPEstimate,
        withCopilot: this.args.pricing5Estimates.expertWithCopilotEstimate,
        withCopilotAndPsp: this.args.pricing5Estimates.expertWithPSPCopilotEstimate,
      },
    };
  }

  get shouldShowAddons() {
    return this.hasIncludedPsp || this.hasCopilotUsage;
  }

  private get hasCopilotUsage() {
    return getCopilotUsage(this.postMigrationPlanEstimate) > 0;
  }

  private get hasIncludedPsp() {
    return this.addonIds.includes(PROACTIVE_SUPPORT_PLUS_BASE_ID);
  }

  get pspBasePrice() {
    return getPspPlanBreakdown(this.postMigrationPlanEstimate).plan_starting_price;
  }

  get estimatedLiteSeatCount() {
    return getEstimatedLiteSeatCount(
      this.corePlanId,
      this.appService.app.humanAdmins.length,
      this.corePlanBreakdown!,
    );
  }

  get planIds() {
    return this.args.migration.postMigrationPlanIds;
  }

  get corePlanId(): string {
    return PRICING_5_X_CORE_PLANS.find((val) => this.planIds.includes(Number(val)))!;
  }

  get addonIds(): Array<string> {
    return PRICING_5_X_ADDON_PLAN_IDS.filter((val) => this.planIds.includes(Number(val)));
  }

  get estimatedCoreSeatCharge() {
    return getEstimatedCoreSeatCharge(this.corePlanBreakdown)!;
  }

  get newFeaturesCount() {
    return difference(this.futureFeatures, this.availableMarketingFeaturesForApp).length;
  }

  get featuresLostCount() {
    return difference(this.availableMarketingFeaturesForApp, this.futureFeatures).length;
  }

  private get availableMarketingFeaturesForApp() {
    return intersection(this.args.availableFeaturesForApp, this.args.allFeatures);
  }

  private get futureFeatures() {
    if (this.hasIncludedPsp) {
      return Array.from(
        new Set(this.args.availablePSPFeatures.concat(this.futureCorePlanFeatures)),
      );
    }

    return this.futureCorePlanFeatures;
  }

  private get futureCorePlanFeatures() {
    return {
      [PRICING_5_X_CORE_ESSENTIAL_ID]: this.args.availableEssentialFeatures,
      [PRICING_5_X_CORE_ADVANCED_ID]: this.args.availableAdvancedFeatures.concat(
        this.args.availableEssentialFeatures,
      ),
      [PRICING_5_X_CORE_EXPERT_ID]: this.args.availableExpertFeatures.concat(
        this.args.availableAdvancedFeatures.concat(this.args.availableEssentialFeatures),
      ),
    }[this.corePlanId]!;
  }

  private get corePlanBreakdown() {
    return getCorePlanBreakdown(this.postMigrationPlanEstimate.breakdown);
  }

  get recommendedPlanIdsAsString() {
    return this.args.migration.recommendedPlanIds?.map((planId) => String(planId)) ?? [];
  }

  get isRecommendedPlan() {
    return this.recommendedPlanIdsAsString.includes(this.corePlanId);
  }

  @action
  confirmRecommendedPlanIds() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      context: 'stripe_migrations',
      place: 'overview_tab',
      object: 'confirm_button',
    });

    return taskFor(this.saveMigrationRecommendedPlanIdsTask).perform();
  }

  @action
  editPlans() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      context: 'stripe_migrations',
      place: 'overview_tab',
      object: 'edit_plan_button',
    });
    this.args.onSelectionChange('moveToNewPricing');
  }

  @dropTask *saveMigrationRecommendedPlanIdsTask() {
    let migration = this.args.migration;
    migration.planIds = migration.recommendedPlanIds;

    try {
      yield migration.save();

      this.args.onProactiveMigrationSave();
    } catch (e) {
      migration.rollbackAttributes();
      this.notificationsService.notifyError(this.intl.t('billing.migrations.submit_error_toast'));
      throw e;
    } finally {
      this.showConfirmationModal = false;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::StripeMigration::MigrationSubcontent::MigrationChoice': typeof BillingMigrationMigrationChoiceComponent;
  }
}
