/* import __COLOCATED_TEMPLATE__ from './non-contracted-over-limit.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { type TooltipRow } from 'embercom/components/billing/usage/tooltips-new/shared-components/tooltip-item';
import type Store from '@ember-data/store';
import type { TooltipComponentSignature } from 'embercom/components/billing/usage/tooltips-new/schemas';
export default class NonContractedOverLimitComponent extends Component<TooltipComponentSignature> {
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare store: Store;
  @service declare customerService: $TSFixMe;

  get inTrial() {
    return this.customerService.customer?.inTrial;
  }

  get contract() {
    return this.store.peekRecord('billing/contract', this.appService.app.id);
  }

  get billableSeats() {
    return this.contract.billedUsage?.core_seat_count ?? 0;
  }

  get additionalText() {
    return this.intl.t('billing.summary.usage-tooltip.price_per_additional_seat_title');
  }

  get prorationExplainer() {
    return [
      {
        label: this.intl.t('billing.summary.usage-tooltip.proration_explainer'),
        muted: true,
        noPadding: true,
      },
    ] as TooltipRow[];
  }

  get proratedTotalRow() {
    return [
      {
        label: this.intl.t('billing.summary.usage-tooltip.total'),
        muted: true,
        noPadding: true,
        strong: true,
        value: this.args.currentMetricChargeDue,
      },
    ] as TooltipRow[];
  }

  get trialDisclaimerRow() {
    return [
      {
        label: this.intl.t('billing.summary.usage-tooltip.trial_disclaimer'),
      },
    ] as TooltipRow[];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Usage::TooltipsNew::SeatMetrics::NonContractedOverLimit': typeof NonContractedOverLimitComponent;
  }
}
