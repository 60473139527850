/* import __COLOCATED_TEMPLATE__ from './custom-away-reason.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { type AwayStatusReason } from 'embercom/objects/inbox/away-reason';
import type AdminAwayService from 'embercom/services/admin-away-service';
import type IntlService from 'ember-intl/services/intl';
import { type Placement, top } from '@popperjs/core';
// @ts-ignore
import intermoji from '@intercom/intermoji';

interface Args {
  awayStatusReason: Partial<{
    emoji?: string;
    label: string;
  }>;
  tooltipPlacement?: Placement;
  cssClass?: string;
  isTooltipDisabled?: boolean;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
  Blocks: any;
}

export default class CustomAwayReason extends Component<Signature> {
  @service declare adminAwayService: AdminAwayService;
  @service declare intl: IntlService;

  get reason() {
    return this.args.awayStatusReason as AwayStatusReason;
  }

  get maybeTrasnslatedLabel() {
    let translationKey = this.adminAwayService.getTranslationKeyForDefaultAwayReason(this.reason);
    if (translationKey) {
      return this.intl.t(`inbox.user-menu.default-reasons.${translationKey}`);
    }

    return this.reason.label;
  }

  get emojiUri() {
    return intermoji.twemojiSVGUri(this.reason.emoji);
  }

  get tooltipPlacement() {
    return this.args.tooltipPlacement || top;
  }

  get cssClass() {
    return this.args.cssClass || 'max-w-64';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Common::AwayStatusReasonRow': typeof CustomAwayReason;
    'common/away-status-reason-row': typeof CustomAwayReason;
  }
}
