/* import __COLOCATED_TEMPLATE__ from './pane.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import type HelpCenterSite from 'embercom/models/help-center-site';
import { type HelpCenterSettingNavigationHelper, type TlsSelectionHelper } from '../main';
import { inject as service } from '@ember/service';
import { type SectionId } from 'embercom/services/help-center-setting-navigation-service';

interface Args {
  app: any;
  site: HelpCenterSite;
  navigationHelper: HelpCenterSettingNavigationHelper;
  tlsSelectionHelper: TlsSelectionHelper;
  newSettings?: boolean;
}

export default class Pane extends Component<Args> {
  @service declare helpCenterService: any;

  @action
  onOpenSectionChange(sectionId: SectionId) {
    this.args.navigationHelper.navigate(sectionId);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::General::Pane': typeof Pane;
    'articles/site/settings/general/pane': typeof Pane;
  }
}
