/* import __COLOCATED_TEMPLATE__ from './list-item.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import { type ArticleInserterResult } from './list';
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  article: ArticleInserterResult;
  helpCenterId: string;
  setPreviewArticle: (article: ArticleInserterResult, helpCenterId: string) => void;
}

interface Signature {
  Args: Args;
}

const ListItem = templateOnlyComponent<Signature>();
export default ListItem;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::InboxInserter::ListItem': typeof ListItem;
  }
}
