/* import __COLOCATED_TEMPLATE__ from './billing-period-selector.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
import Component from '@glimmer/component';
import { BILLING_PERIODS } from 'embercom/lib/billing';

interface Args {
  billingPeriod: BILLING_PERIODS;
  updateBillingPeriod: (value: BILLING_PERIODS) => void;
  isComparisonModal?: boolean;
}

interface Signature {
  Element: any;
  Args: Args;
}

export default class BillingPeriodSelector extends Component<Signature> {
  get monthlyBillingPeriodValue() {
    return BILLING_PERIODS.Monthly;
  }

  get annualBillingPeriodValue() {
    return BILLING_PERIODS.Annual;
  }

  get isMonthlyBillingSelected() {
    return this.args.billingPeriod === BILLING_PERIODS.Monthly;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AnnualPlans::BillingPeriodSelector': typeof BillingPeriodSelector;
  }
}
