/* import __COLOCATED_TEMPLATE__ from './badge.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { or } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default Component.extend({
  helpCenterService: service(),
  isSingleLanguage: or('singleLanguageMode', 'helpCenterService.isAllSingleLanguage'),
  localeName: computed('localeId', 'helpCenterService.site.selectedLocalesOrdered', function () {
    return this.helpCenterService.site.selectedLocalesOrdered.findBy('localeId', this.localeId)
      .name;
  }),
  state: computed('localeId', 'article.articleContents.[]', function () {
    let content = this.article.contentByLocale(this.localeId);
    if (content) {
      if (content.liveVersionId && content.liveVersionId !== content.latestVersionId) {
        return 'published_with_draft';
      }
      return content.state;
    }
    return 'missing';
  }),
  stateCssClass: computed('state', function () {
    return `o__${this.state.replaceAll('_', '-')}`;
  }),
  stateTranslationKey: computed('state', function () {
    return `articles.list.content_status.badge.${this.state}_label`;
  }),
  needsReview: computed('localeId', 'article.articleContents.[]', function () {
    let content = this.article.contentByLocale(this.localeId);
    if (content) {
      return content.needsReview;
    }
    return false;
  }),
});
