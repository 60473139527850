/* import __COLOCATED_TEMPLATE__ from './simple-automations-banner.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */

import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: HTMLElement;
}

const SimpleAutomationsBanner = templateOnlyComponent<Signature>();
export default SimpleAutomationsBanner;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Automation::Basics::SimpleAutomationsBanner': typeof SimpleAutomationsBanner;
  }
}
