/* import __COLOCATED_TEMPLATE__ from './language.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import type SupportedLocale from 'embercom/models/articles/site/supported-locale';
import type HelpCenterSite from 'embercom/models/help-center-site';
import { inject as service } from '@ember/service';

interface Args {
  app: any;
  site: HelpCenterSite;
}

interface DropDownOption {
  text: string;
  value: string;
}

export default class Language extends Component<Args> {
  @service declare helpCenterService: $TSFixMe;
  get selectedLanguageDropdownItems(): Array<DropDownOption> {
    return this.args.site.supportedLocales.map((locale: SupportedLocale) => {
      return {
        text: locale.name,
        value: locale.localeId,
      };
    });
  }

  get selectedLanguageList(): Array<SupportedLocale> {
    return this.args.site.selectedLocalesWithoutDefault;
  }

  get addableLanguageDropdownItems(): Array<DropDownOption> {
    return this.args.site.supportedLocales
      .filter((locale: SupportedLocale) => {
        return locale.isSelected !== true;
      })
      .map((locale: SupportedLocale) => {
        return {
          text: locale.name,
          value: locale.localeId,
        };
      });
  }

  get defaultLocale(): SupportedLocale {
    return this.args.site.defaultLocale;
  }

  @action
  setDefaultLanguage(localeId: string): void {
    this.addLanguage(localeId);
    this.helpCenterService.updateLocale(localeId);
    this.args.site.locale = localeId;
  }

  @action
  removeLanguage(localeId: string): void {
    let localeToRemove = this.args.site.supportedLocales.find((locale: SupportedLocale) => {
      return locale.localeId === localeId;
    });
    if (localeToRemove) {
      localeToRemove.isSelected = false;
    }
  }

  @action
  addLanguage(localeId: string): void {
    let localeToAdd = this.args.site.supportedLocales.find((locale) => {
      return locale.localeId === localeId;
    });
    if (localeToAdd) {
      localeToAdd.isSelected = true;

      if (localeToAdd.title === null || localeToAdd.title === undefined) {
        localeToAdd.title = this.defaultLocale.title;
      }
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::General::Language': typeof Language;
    'articles/site/settings/general/language': typeof Language;
  }
}
