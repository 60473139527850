/* import __COLOCATED_TEMPLATE__ from './article-picker.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-on-calls-in-components */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import { inject as service } from '@ember/service';
import { equal, or } from '@ember/object/computed';
import { computed, action } from '@ember/object';
import EventTracking from 'embercom/components/mixins/articles/event-tracking';
import Component from '@ember/component';

import { task, all } from 'ember-concurrency';

export default Component.extend(EventTracking, {
  notificationsService: service(),
  helpCenterService: service(),
  store: service(),
  tagName: '',
  articles: null,
  selectedArticles: null,
  loading: equal('articles', null),
  busy: or('populateArticles.isRunning', 'addSelectedArticles.isRunning'),
  selectedArticleGroup: null,

  sectionsList: computed('collection.children.[]', function () {
    return this.collection.get('children').map((section) => ({
      text: section.name,
      value: section.id,
    }));
  }),

  didReceiveAttrs() {
    this._super(...arguments);
    this.set('selectedArticles', []);
    this.set('selectedArticleGroup', this.collection);
    if (this.isOpen) {
      this.populateArticles.perform();
    }
  },

  populateArticles: task(function* () {
    let response = yield this.store.query('article-multilingual', {
      collection_id: null,
      disable_pagination: true,
    });
    let allArticles = response.toArray();
    let existingArticles = this.get('collection.allArticles');
    let availableArticles = allArticles.reject((item) => {
      return existingArticles.includes(item);
    });
    this.set('articles', availableArticles);
  }).drop(),

  addSelectedArticles: task(function* () {
    let group = this.selectedArticleGroup;
    let saveTasks = [];
    for (let article of this.selectedArticles) {
      saveTasks.push(this.addArticle.perform(article, group));
    }
    try {
      yield all(saveTasks);
    } catch (error) {
      this.notificationsService.notifyError('Sorry. There was a problem adding your articles.');
    } finally {
      this.onModalClose();
      group.reload();
    }
  }).drop(),

  addArticle: task(function* (article, collection) {
    article.setProperties({
      folder: collection,
      order: null,
    });
    yield article
      .save()
      .then(() => {
        this.trackEducateArticleEvent(article, { action: 'added', place: 'collection' });
      })
      .catch(() => {
        article.rollbackAttributes();
      });
  })
    .maxConcurrency(3)
    .enqueue(),

  selectArticleGroup: action(function (groupId) {
    let group = this.store.peekRecord('articles/article-group', groupId);
    this.set('selectedArticleGroup', group);
  }),
});
