/* import __COLOCATED_TEMPLATE__ from './header-links.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type HelpCenterSite from 'embercom/models/help-center-site';
import type HelpCenterSiteLink from 'embercom/models/help-center-site-link';
import type HelpCenterSiteLinkGroup from 'embercom/models/help-center-site-link-group';
import type Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';

interface Args {
  site: HelpCenterSite;
}

export default class HeaderLinks extends Component<Args> {
  @service declare store: Store;
  @tracked selectedLanguage: string = this.args.site.locale;
  maxLinksPerHeader = 3;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    if (!this.headerLinkGroupByLocale) {
      this.createDefaultLinkGroup();
    }
  }

  get headerLinkGroups(): Array<HelpCenterSiteLinkGroup> {
    return this.args.site.headerLinkGroups;
  }

  // The ! is a non-null assertion operator. It tells TypeScript that the value will not be null or undefined.
  // As a default value is set on init and when the language is changed.
  get headerLinkGroupByLocale(): HelpCenterSiteLinkGroup {
    return this.headerLinkGroups.findBy('locale', this.selectedLanguage)!;
  }

  get headerLinkGroupLinks(): Array<HelpCenterSiteLink> {
    return this.headerLinkGroupByLocale.links;
  }

  get canAddLinks(): boolean {
    if (!this.headerLinkGroupByLocale) {
      // The link group will be created when adding a link.
      return true;
    }
    return this.headerLinkGroupLinks.length < this.maxLinksPerHeader;
  }

  get languages() {
    return this.args.site.selectedLocales.map((locale) => ({
      text: locale.name,
      value: locale.localeId,
    }));
  }

  @action
  onLanguageChange(locale: string) {
    this.selectedLanguage = locale;

    if (!this.headerLinkGroups.findBy('locale', locale)) {
      this.createDefaultLinkGroup();
    }
  }

  @action
  addLink() {
    if (!this.headerLinkGroups.findBy('locale', this.selectedLanguage)) {
      this.createDefaultLinkGroup();
    }
    let linkGroup = this.headerLinkGroupByLocale;
    linkGroup.addLink();
  }

  private createDefaultLinkGroup() {
    this.args.site.addHeaderLinkGroup(this.selectedLanguage, 0);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::Content::Links::HeaderLinks': typeof HeaderLinks;
    'articles/site/settings/content/links/header-links': typeof HeaderLinks;
  }
}
