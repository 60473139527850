/* import __COLOCATED_TEMPLATE__ from './contracted-over-limit.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { type TooltipRow } from 'embercom/components/billing/usage/tooltips-new/shared-components/tooltip-item';
import type { TooltipComponentSignature } from '../schemas';

export default class ContracteOverLimitComponent extends Component<TooltipComponentSignature> {
  @service declare intl: IntlService;
  @service declare customerService: $TSFixMe;

  get inTrial() {
    return this.customerService.customer?.inTrial;
  }

  get infoBoxContent() {
    let key =
      this.args.bucketDurationInMonths === 12
        ? 'billing.summary.usage-tooltip.exceeds_bucket_threshold_year'
        : 'billing.summary.usage-tooltip.exceeds_bucket_threshold_quarter';

    return this.intl.t(key, { formattedBucketNumber: this.args.formattedFinBucketInContract });
  }

  get proratedTotalRow() {
    return [
      {
        label: this.intl.t('billing.summary.usage-tooltip.total'),
        muted: true,
        noPadding: true,
        strong: true,
        value: this.args.currentMetricChargeDue,
      },
    ] as TooltipRow[];
  }

  get trialDisclaimerRow() {
    return [
      {
        label: this.intl.t('billing.summary.usage-tooltip.trial_disclaimer'),
        muted: true,
        noPadding: true,
      },
    ] as TooltipRow[];
  }

  get shouldShowAdditionalUsage() {
    return this.args.overageQuantityForBillingPeriod > 0;
  }

  get usageRow() {
    return [
      {
        label: this.intl.t('billing.summary.usage-tooltip.non_seat_metric_title'),
        muted: true,
        noPadding: true,
        strong: true,
      },
      { label: `${this.args.finUsageThisPeriod}` },
    ] as TooltipRow[];
  }

  get pricePerResolutionRow() {
    return [
      {
        label: this.intl.t('billing.summary.usage-tooltip.price_per_resolution'),
        muted: true,
        noPadding: true,
        strong: true,
      },
      { label: this.formattedResolutionPrice },
    ] as TooltipRow[];
  }

  get formattedResolutionPrice() {
    return this.intl.formatNumber(this.args.metricPrice.per_unit_price / 100, {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Usage::TooltipsNew::BucketedMetrics::ContracteOverLimit': typeof ContracteOverLimitComponent;
  }
}
