/* import __COLOCATED_TEMPLATE__ from './order-summary.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import {
  FIN_AI_COPILOT_BASE_ID,
  PROACTIVE_SUPPORT_PLUS_BASE_ID,
  type BILLING_PERIODS,
} from 'embercom/lib/billing';
import { Metric } from 'embercom/models/data/pricing/metric-types';
import moment from 'moment-timezone';
import type QuoteService from 'embercom/services/quote-service';
import type IntlService from 'embercom/services/intl';
import type Plan from 'embercom/models/plan';

const FIN_AI_COPILOT_BASE_ID_NUM = Number(FIN_AI_COPILOT_BASE_ID);
const PROACTIVE_SUPPORT_PLUS_BASE_ID_NUM = Number(PROACTIVE_SUPPORT_PLUS_BASE_ID);

interface SubscriptionSummary {
  selectedPlanId: number;
  selectedPlanName: string;
  contractedSeats: number;
  contractedCopilotSeats: number;
  addOnIds: number[];
  billingPeriod: BILLING_PERIODS;
}

interface Args {
  renewalDate: string;
  loading: boolean;
  subscriptionUpdated: boolean;
  currentSubscriptionSummary: SubscriptionSummary;
  updatedSubscriptionSummary: SubscriptionSummary;
  setContractedPriceEstimate: (value: string) => void;
}

interface Signature {
  Args: Args;
  Element: HTMLDivElement;
}

export default class OrderSummary extends Component<Signature> {
  @service declare appService: any;
  @service declare intl: IntlService;
  @service declare quoteService: QuoteService;

  // Generic calculation methods
  private calculateMonthlySeatPrice(price: number, seatAmount: number) {
    return price * seatAmount;
  }

  // Quote helper
  private quote(id: number) {
    return this.quoteService.getQuoteById([id]);
  }

  // Price getters for seat pricing
  private getSeatPrice(planId: number) {
    return this.quote(planId)?.fullSeatPrice(planId) || 0;
  }

  private getDiscountedSeatPrice(planId: number) {
    return this.quote(planId)?.discountedAnnualSeatPrice(planId) || 0;
  }

  private getCopilotSeatPrice() {
    return (
      this.quote(FIN_AI_COPILOT_BASE_ID_NUM)?.fullSeatPrice(
        FIN_AI_COPILOT_BASE_ID_NUM,
        Metric.copilot_seat_count,
      ) ?? 0
    );
  }

  private getDiscountedCopilotSeatPrice() {
    return (
      this.quote(FIN_AI_COPILOT_BASE_ID_NUM)?.discountedAnnualSeatPrice(
        FIN_AI_COPILOT_BASE_ID_NUM,
        Metric.copilot_seat_count,
      ) ?? 0
    );
  }

  private getProactiveAddonPrice(addonIds: number[]) {
    if (!addonIds?.includes(PROACTIVE_SUPPORT_PLUS_BASE_ID_NUM)) {
      return 0;
    }
    return (
      this.quote(PROACTIVE_SUPPORT_PLUS_BASE_ID_NUM)?.fullBreakdownAmount(
        PROACTIVE_SUPPORT_PLUS_BASE_ID_NUM,
      ) ?? 0
    );
  }

  get proactiveSupportPlusPlan() {
    return this.appService.app.allPlansOnPricingModel.find(
      (plan: Plan) => plan.idAsNumber === PROACTIVE_SUPPORT_PLUS_BASE_ID_NUM,
    );
  }

  // CURRENT SUBSCRIPTION CALCULATIONS

  // Seat prices for current subscription
  get currentFullSeatPrice() {
    return this.getSeatPrice(this.args.currentSubscriptionSummary.selectedPlanId);
  }

  get currentDiscountedSeatPrice() {
    return this.getDiscountedSeatPrice(this.args.currentSubscriptionSummary.selectedPlanId);
  }

  get currentCopilotSeatPrice() {
    return this.getCopilotSeatPrice();
  }

  get currentDiscountedCopilotSeatPrice() {
    return this.getDiscountedCopilotSeatPrice();
  }

  get currentProactiveAddonPrice() {
    return this.getProactiveAddonPrice(this.args.currentSubscriptionSummary.addOnIds);
  }

  // Monthly calculations for current subscription
  get currentMonthlyDiscountedContractedSeatPrice() {
    return this.calculateMonthlySeatPrice(
      this.currentDiscountedSeatPrice,
      this.args.currentSubscriptionSummary.contractedSeats,
    );
  }

  get currentMonthlyDiscountedContractedCopilotSeatPrice() {
    return this.calculateMonthlySeatPrice(
      this.currentDiscountedCopilotSeatPrice,
      this.args.currentSubscriptionSummary.contractedCopilotSeats,
    );
  }

  // Total calculations for current subscription
  get currentMonthlyDiscountedTotal() {
    let contractedTotal =
      this.currentMonthlyDiscountedContractedSeatPrice +
      this.currentMonthlyDiscountedContractedCopilotSeatPrice +
      this.currentProactiveAddonPrice;

    return contractedTotal;
  }

  get currentYearlyDiscountedTotal() {
    return this.currentMonthlyDiscountedTotal * 12;
  }

  get formattedCurrentYearlyDiscountedTotal() {
    return this.quoteService.formatPrice(this.currentYearlyDiscountedTotal);
  }

  // UPDATED SUBSCRIPTION CALCULATIONS

  // Seat prices for updated subscription
  get seatCountDifference() {
    return (
      this.args.updatedSubscriptionSummary.contractedSeats -
      this.args.currentSubscriptionSummary.contractedSeats
    );
  }

  get copilotSeatCountDifference() {
    return (
      this.args.updatedSubscriptionSummary.contractedCopilotSeats -
      this.args.currentSubscriptionSummary.contractedCopilotSeats
    );
  }

  get updatedFullSeatPrice() {
    return this.getSeatPrice(this.args.updatedSubscriptionSummary.selectedPlanId);
  }

  get updatedDiscountedSeatPrice() {
    return this.getDiscountedSeatPrice(this.args.updatedSubscriptionSummary.selectedPlanId);
  }

  get updatedCopilotSeatPrice() {
    return this.getCopilotSeatPrice();
  }

  get updatedDiscountedCopilotSeatPrice() {
    return this.getDiscountedCopilotSeatPrice();
  }

  get updatedProactiveAddonPrice() {
    return this.getProactiveAddonPrice(this.args.updatedSubscriptionSummary.addOnIds);
  }

  // Monthly calculations for updated subscription
  get updatedMonthlyContractedSeatPrice() {
    return this.calculateMonthlySeatPrice(
      this.updatedFullSeatPrice,
      this.args.updatedSubscriptionSummary.contractedSeats,
    );
  }

  get updatedMonthlyDiscountedContractedSeatPrice() {
    return this.calculateMonthlySeatPrice(
      this.updatedDiscountedSeatPrice,
      this.args.updatedSubscriptionSummary.contractedSeats,
    );
  }

  get updatedPlanCurrentMonthlyDiscountedContractedSeatPrice() {
    return this.calculateMonthlySeatPrice(
      this.updatedDiscountedSeatPrice,
      this.args.currentSubscriptionSummary.contractedSeats,
    );
  }

  get updatedMonthlyContractedSeatPriceDifference() {
    return (
      this.updatedMonthlyDiscountedContractedSeatPrice -
      this.updatedPlanCurrentMonthlyDiscountedContractedSeatPrice
    );
  }

  get updatedMonthlyDiscountedContractedCopilotSeatPrice() {
    return this.calculateMonthlySeatPrice(
      this.updatedDiscountedCopilotSeatPrice,
      this.args.updatedSubscriptionSummary.contractedCopilotSeats,
    );
  }

  get updatedMonthlyContractedCopilotSeatPriceDifference() {
    return (
      this.updatedMonthlyDiscountedContractedCopilotSeatPrice -
      this.currentMonthlyDiscountedContractedCopilotSeatPrice
    );
  }

  // Total calculations for updated subscription
  get updatedMonthlyDiscountedTotal() {
    let contractedTotal =
      this.updatedMonthlyDiscountedContractedSeatPrice +
      this.updatedMonthlyDiscountedContractedCopilotSeatPrice +
      this.updatedProactiveAddonPrice;

    return contractedTotal;
  }

  get updatedYearlyDiscountedTotal() {
    return this.updatedMonthlyDiscountedTotal * 12;
  }

  get formattedUpdatedYearlyDiscountedTotal() {
    return this.quoteService.formatPrice(this.updatedYearlyDiscountedTotal);
  }

  get contractedPriceEstimate() {
    let renewalDay = moment(this.args.renewalDate).startOf('day');
    let daysInYear = renewalDay.diff(moment(renewalDay).subtract(1, 'year'), 'days', true);

    let remainingDaysInContract = renewalDay.diff(moment().startOf('day'), 'days', true);
    let refundAmount = (this.currentYearlyDiscountedTotal / daysInYear) * remainingDaysInContract;
    let chargeAmount = (this.updatedYearlyDiscountedTotal / daysInYear) * remainingDaysInContract;
    let contractedPriceEstimate = this.quoteService.formatPrice(
      Math.trunc(chargeAmount - refundAmount),
    );
    this.args.setContractedPriceEstimate(contractedPriceEstimate);
    return contractedPriceEstimate;
  }

  get currentHasProactiveSupport() {
    return this.args.currentSubscriptionSummary.addOnIds?.includes(
      PROACTIVE_SUPPORT_PLUS_BASE_ID_NUM,
    );
  }

  get updatedHasProactiveSupport() {
    return this.args.updatedSubscriptionSummary.addOnIds?.includes(
      PROACTIVE_SUPPORT_PLUS_BASE_ID_NUM,
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::AnnualManageSubscription::OrderSummary': typeof OrderSummary;
  }
}
