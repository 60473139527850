/* import __COLOCATED_TEMPLATE__ from './base-section-wrapper.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';

interface Args {
  id: string;
  currentlyOpenSection: string | null;
  sectionGroup: string[];
  setCurrentlyOpenSection: (sectionId: string) => void;
  title: string;
  count: number;
  icon: any;
  openAccordionId: string | null;
  customStyles?: string;
}

interface Signature {
  Args: Args;
  Blocks: {
    content: [];
  };
}

export default class BaseSectionWrapper extends Component<Signature> {
  constructor(owner: any, args: Args) {
    super(owner, args);
    if (args.openAccordionId && args.sectionGroup.includes(args.openAccordionId)) {
      args.setCurrentlyOpenSection(args.id);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Automation::SimpleAutomations::BaseSectionWrapper': typeof BaseSectionWrapper;
  }
}
