/* import __COLOCATED_TEMPLATE__ from './media.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  mediaType: 'image' | 'video';
  mediaUrl: string;
  wistiaId?: string;
  onClick?: () => void;
}

interface Signature {
  Args: Args;
}

const Media = templateOnlyComponent<Signature>();
export default Media;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Common::HeroBanner::Media': typeof Media;
    'common/hero-banner/media': typeof Media;
  }
}
