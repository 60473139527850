/* import __COLOCATED_TEMPLATE__ from './date-range-picker.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import { readOnly, notEmpty, or } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import moment from 'moment-timezone';
import { ternaryToProperty } from '@intercom/pulse/lib/computed-properties';

const ISO_FORMAT_WITHOUT_TZ = 'YYYY-MM-DDTHH:mm:ss';

// after we strip the app's zone we need to convert it back into a moment for ember-power-calendar
let stripAppTimezone = (date) => (date ? moment(moment(date).format(ISO_FORMAT_WITHOUT_TZ)) : null);

export default Component.extend({
  classNames: ['date-range-picker__picker'],
  appService: service(),
  session: service(),
  app: readOnly('appService.app'),
  maxDate: ternaryToProperty(
    'maximumSelectionWindowInYears',
    'maxDateWithMaximumSelectionWindow',
    'simpleMaxDate',
  ),
  showHeader: true,
  showFooter: true,
  simpleMaxDate: null,
  appCreatedAt: computed('app.created_at', 'session.workspace.createdAt', function () {
    if (this.app) {
      return this.app.created_at;
    } else {
      return this.session?.workspace?.createdAt;
    }
  }),
  canCustomizeMinDate: or('maximumSelectionWindowInYears', 'canSelectAppCreationDate'),
  maxDateWithMaximumSelectionWindow: computed(
    'rangeWithoutZone.{start,end}',
    'maximumSelectionWindowInYears',
    function () {
      let now = moment();
      if (!this.get('rangeWithoutZone.end')) {
        let rangeStartPlusMaxWindow = moment(this.get('rangeWithoutZone.start')).add(
          this.maximumSelectionWindowInYears,
          'years',
        );
        if (rangeStartPlusMaxWindow.isBefore(now)) {
          return rangeStartPlusMaxWindow;
        }
      }
      return now;
    },
  ),
  minDate: ternaryToProperty(
    'canCustomizeMinDate',
    'minDateWithMaximumSelectionWindow',
    'simpleMinDate',
  ),
  simpleMinDate: computed('maxDate', 'appCreatedAt', 'customMinDate', function () {
    let appCreationDate = moment(this.appCreatedAt);
    let defaultMinDate = this.maxDate.clone().subtract(2, 'years');
    return moment.max(
      this.customMinDate
        ? [moment(this.customMinDate), defaultMinDate, appCreationDate]
        : [defaultMinDate, appCreationDate],
    );
  }),
  minDateWithMaximumSelectionWindow: computed(
    'rangeWithoutZone.{start,end}',
    'appCreatedAt',
    'maxDate',
    'canSelectAppCreationDate',
    'cannotSelectDatePriorToAppCreation',
    'maximumSelectionWindowInYears',
    function () {
      let appCreationDate = moment(this.appCreatedAt);

      if (this.canSelectAppCreationDate) {
        return appCreationDate;
      }
      let cannotSelectDatePriorToAppCreation = this.cannotSelectDatePriorToAppCreation ?? true;
      if (!this.get('rangeWithoutZone.end')) {
        let rangeStartMinusMaxWindow = moment(this.get('rangeWithoutZone.start')).subtract(
          this.maximumSelectionWindowInYears,
          'years',
        );
        if (
          appCreationDate.isAfter(rangeStartMinusMaxWindow) &&
          cannotSelectDatePriorToAppCreation
        ) {
          return appCreationDate;
        }
        return rangeStartMinusMaxWindow;
      }
      return this.maxDate.clone().subtract(2, 'years');
    },
  ),
  secondMonth: null,
  firstMonth: computed('secondMonth', function () {
    return this.secondMonth.clone().subtract(1, 'months');
  }),

  range: {
    start: null,
    end: null,
  },

  rangeEndSelected: notEmpty('rangeWithoutZone.end'),

  // because we pass a range as moment dates formatted in the app's zone,
  // we need to strip the app's zone so that ember-power-calendar
  // doesn't try to convert it to the computer's timezone if they are different
  rangeWithoutZone: computed('range.{start,end}', function () {
    return {
      start: stripAppTimezone(this.get('range.start')),
      end: stripAppTimezone(this.get('range.end')),
    };
  }),

  init() {
    this._super(...arguments);
    this.set('simpleMaxDate', moment());
    this.set('secondMonth', this.get('rangeWithoutZone.end') || moment());
  },
});
