/* import __COLOCATED_TEMPLATE__ from './group-title.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    metricIdentifier: string;
    onPricing5_XPlan: boolean;
    onFinForPlatforms: boolean;
  };
}

const GroupTitle = templateOnlyComponent<Signature>();
export default GroupTitle;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Summary::CardElements::GroupTitle': typeof GroupTitle;
  }
}
