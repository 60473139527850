/* import __COLOCATED_TEMPLATE__ from './early-stage-rejection.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import {
  PRICING_5_X_CORE_ADVANCED_ID,
  PRICING_5_X_CORE_ESSENTIAL_ID,
  PRICING_5_X_CORE_EXPERT_ID,
  PRICING_5_X_CORE_ID,
  PLAN_DATA,
  PRICING_5_X_EARLY_STAGE_FREE_SOLUTION_ID,
} from 'embercom/lib/billing';
import type Plan from 'embercom/models/plan';
import type Product from 'embercom/models/product';
import type CardlessConversionModalService from 'embercom/services/cardless-conversion-modal-service';
import type IntlService from 'embercom/services/intl';

interface Args {}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class EarlyStageRejectionComponent extends Component<Signature> {
  @service declare appService: any;
  @service declare cardlessConversionModalService: CardlessConversionModalService;
  @service declare intl: IntlService;

  get app() {
    return this.appService.app;
  }

  get isOnPricing5() {
    return this.app.onPricing5;
  }

  planPricePerSeatFormatted(planId: string) {
    let pricePerSeat = PLAN_DATA[planId].pricePerSeat;
    return this.intl.formatNumber(pricePerSeat, {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    });
  }

  get planData(): Array<any> {
    return [
      {
        id: PRICING_5_X_CORE_ESSENTIAL_ID,
        name: PLAN_DATA[PRICING_5_X_CORE_ESSENTIAL_ID].name,
        price: this.planPricePerSeatFormatted(PRICING_5_X_CORE_ESSENTIAL_ID),
        description: `modal.cardless-conversion-modal-pricing5.compare-plans.pricing_subtitle_${PRICING_5_X_CORE_ESSENTIAL_ID}`,
      },
      {
        id: PRICING_5_X_CORE_ADVANCED_ID,
        name: PLAN_DATA[PRICING_5_X_CORE_ADVANCED_ID].name,
        price: this.planPricePerSeatFormatted(PRICING_5_X_CORE_ADVANCED_ID),
        description: `modal.cardless-conversion-modal-pricing5.compare-plans.pricing_subtitle_${PRICING_5_X_CORE_ADVANCED_ID}`,
      },
      {
        id: PRICING_5_X_CORE_EXPERT_ID,
        name: PLAN_DATA[PRICING_5_X_CORE_EXPERT_ID].name,
        price: this.planPricePerSeatFormatted(PRICING_5_X_CORE_EXPERT_ID),
        description: `modal.cardless-conversion-modal-pricing5.compare-plans.pricing_subtitle_${PRICING_5_X_CORE_EXPERT_ID}`,
      },
    ];
  }

  get partnerProgram() {
    return (
      this.app.earlyStageApplicationSolutionId.toString() ===
      PRICING_5_X_EARLY_STAGE_FREE_SOLUTION_ID
    );
  }

  get planIdOnActiveTrial() {
    let product = this.app.products.find((product: Product) => product.id === PRICING_5_X_CORE_ID);

    if (product === undefined) {
      return undefined;
    }

    let plan = product.plans.find((plan: Plan) => plan.activeTrial);
    return plan ? plan.id : undefined;
  }

  @action
  openConversionModal() {
    this.cardlessConversionModalService.toggle();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::EarlyStageRejection': typeof EarlyStageRejectionComponent;
    'billing/early-stage-rejection': typeof EarlyStageRejectionComponent;
  }
}
