/* import __COLOCATED_TEMPLATE__ from './action-color.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import Component from '@glimmer/component';
import type HelpCenterSite from 'embercom/models/help-center-site';
import tinycolor from 'tinycolor2';

interface Args {
  site: HelpCenterSite;
}

export default class ActionColor extends Component<Args> {
  get isReadable(): boolean {
    let actionColor = this.args.site.themeColor;
    let bodyBackgroundColor = this.args.site.customizationOptions.body.backgroundColor;

    if (!bodyBackgroundColor) {
      return true;
    }

    return tinycolor.isReadable(bodyBackgroundColor, actionColor, {
      level: 'AA',
      size: 'small',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::Styling::ActionColor': typeof ActionColor;
    'articles/site/settings/styling/action-color': typeof ActionColor;
  }
}
