/* import __COLOCATED_TEMPLATE__ from './routing-questionnaire-modal.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type Plan from 'embercom/models/plan';
import type RouterService from '@ember/routing/router-service';
import { tracked } from '@glimmer/tracking';

interface Signature {
  Args: {
    isOpen: boolean;
    onModalClose: (nextStep: 'continue_cancellation' | 'abort_cancellation') => void;
    onContinueCancellation: (option: Option) => void;
  };
}

type Option = 'dont-want-intercom' | 'switching-plan-or-account';

export default class RoutingQuestionnaireModal extends Component<Signature> {
  @service appService: any;
  @service intercomEventService: any;
  @service declare router: RouterService;

  @tracked selectedOption: Option | '' = '';

  get app() {
    return this.appService.app;
  }

  get planName() {
    return this.currentSubscriptionPlan.name;
  }

  get currentSubscriptionPlan() {
    return this.app.allPlansOnPricingModel.find(
      (plan: Plan) => plan.billableCustomerPlan && !plan.product.addon,
    );
  }

  @action
  handleCancellation() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'continue_to_cancel_subscription',
      section: 'cancel_subscription.routing_questionnaire_modal',
      place: 'billing_settings',
      reason_to_cancel: this.selectedOption,
    });
    if (this.selectedOption) {
      this.args.onContinueCancellation(this.selectedOption);
    }
  }

  @action
  handleModalClose(object: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object,
      section: 'cancel_subscription.routing_questionnaire_modal',
      place: 'billing_settings',
      context: 'abort_cancel_subscription',
    });
    this.args.onModalClose('abort_cancellation');
  }

  @action
  handleOptionSelection(option: Option) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: `routing_questionnaire_modal_option_${option}`,
      section: 'cancel_subscription.routing_questionnaire_modal',
      place: 'billing_settings',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Settings::Cancel::RoutingQuestionnaireModal': typeof RoutingQuestionnaireModal;
    'billing/settings/cancel/routing-questionnaire-modal': typeof RoutingQuestionnaireModal;
  }
}
