/* import __COLOCATED_TEMPLATE__ from './main-form.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import Component from '@glimmer/component';
import type HelpCenterSite from 'embercom/models/help-center-site';
import type Block from 'embercom/models/customization-options/layout-options/blocks/content-block';
import { type UploadHelper } from 'embercom/objects/media/media-helper';

interface Args {
  site: HelpCenterSite;
  homepageBlock: Block;
  uploadHelper: UploadHelper;
  selectedLocale: string;
}

export default class MainForm extends Component<Args> {
  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.args.site.initializeLocalisedContent(this.args.selectedLocale);
  }

  get localizedContent() {
    return this.args.site.selectedLocales.find(
      (locale) => locale.localeId === this.args.selectedLocale,
    )?.contentBlock;
  }

  get contentBlockOptions() {
    return this.args.site.customizationOptions.contentBlock;
  }

  get mediaHelper() {
    return this.args.uploadHelper.getMediaHelper('contentBlock');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::NewStyling::Layout::ContentBlockElements::MainForm': typeof MainForm;
  }
}
