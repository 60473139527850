/* import __COLOCATED_TEMPLATE__ from './usage-tooltip.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import type PricingMetric from 'embercom/lib/purchase/pricing-metric';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import {
  getMetricTooltipMapping,
  METRIC_TYPES,
  getContractUsageStatus,
  CONTRACT_USAGE_STATUS,
  getCurrentMetricCharge,
} from 'embercom/helpers/billing/usage-helper';
import { dasherize } from '@ember/string';
import { type ComponentLike } from '@glint/template';
import { type TooltipRow } from 'embercom/components/billing/usage/tooltips-new/shared-components/tooltip-item';
import type Charge from 'embercom/models/billing/price/charge';

// Import components for all metric types
import ContractedOverLimit_Seat from 'embercom/components/billing/usage/tooltips-new/seat-metrics/contracted-over-limit';
import ContractedUnderLimit_Seat from 'embercom/components/billing/usage/tooltips-new/seat-metrics/contracted-under-limit';
import NonContractedOverLimit_Seat from 'embercom/components/billing/usage/tooltips-new/seat-metrics/non-contracted-over-limit';
import NonContractedUnderLimit_Seat from 'embercom/components/billing/usage/tooltips-new/seat-metrics/non-contracted-under-limit';

import ContractedOverLimit_Fixed from 'embercom/components/billing/usage/tooltips-new/fixed-price-metrics/contracted-over-limit';
import ContractedUnderLimit_Fixed from 'embercom/components/billing/usage/tooltips-new/fixed-price-metrics/contracted-under-limit';
import NonContractedOverLimit_Fixed from 'embercom/components/billing/usage/tooltips-new/fixed-price-metrics/non-contracted-over-limit';
import NonContractedUnderLimit_Fixed from 'embercom/components/billing/usage/tooltips-new/fixed-price-metrics/non-contracted-under-limit';

import ContractedOverLimit_Tiered from 'embercom/components/billing/usage/tooltips-new/tiered-metrics/contracted-over-limit';
import ContractedUnderLimit_Tiered from 'embercom/components/billing/usage/tooltips-new/tiered-metrics/contracted-under-limit';
import NonContractedOverLimit_Tiered from 'embercom/components/billing/usage/tooltips-new/tiered-metrics/non-contracted-over-limit';
import NonContractedUnderLimit_Tiered from 'embercom/components/billing/usage/tooltips-new/tiered-metrics/non-contracted-under-limit';

import ContractedOverLimit_Bucketed from 'embercom/components/billing/usage/tooltips-new/bucketed-metrics/contracted-over-limit';
import ContractedUnderLimit_Bucketed from 'embercom/components/billing/usage/tooltips-new/bucketed-metrics/contracted-under-limit';

import ContractedOverLimit_Bucketed_MultiWorkspace from 'embercom/components/billing/usage/tooltips-new/bucketed-metrics/multi-workspace/multi-workspace-contracted-over-limit';
import ContractedUnderLimit_Bucketed_MultiWorkspace from 'embercom/components/billing/usage/tooltips-new/bucketed-metrics/multi-workspace/multi-workspace-contracted-under-limit';

// Define a nested type-safe component map
const COMPONENT_MAP: Record<string, Record<string, ComponentLike | undefined>> = {
  [METRIC_TYPES.SEAT]: {
    [CONTRACT_USAGE_STATUS.CONTRACTED_OVER]: ContractedOverLimit_Seat as unknown as ComponentLike,
    [CONTRACT_USAGE_STATUS.CONTRACTED_UNDER]: ContractedUnderLimit_Seat as unknown as ComponentLike,
    [CONTRACT_USAGE_STATUS.NON_CONTRACTED_OVER]:
      NonContractedOverLimit_Seat as unknown as ComponentLike,
    [CONTRACT_USAGE_STATUS.NON_CONTRACTED_UNDER]:
      NonContractedUnderLimit_Seat as unknown as ComponentLike,
  },
  [METRIC_TYPES.FIXED_PRICE]: {
    [CONTRACT_USAGE_STATUS.CONTRACTED_OVER]: ContractedOverLimit_Fixed as unknown as ComponentLike,
    [CONTRACT_USAGE_STATUS.CONTRACTED_UNDER]:
      ContractedUnderLimit_Fixed as unknown as ComponentLike,
    [CONTRACT_USAGE_STATUS.NON_CONTRACTED_OVER]:
      NonContractedOverLimit_Fixed as unknown as ComponentLike,
    [CONTRACT_USAGE_STATUS.NON_CONTRACTED_UNDER]:
      NonContractedUnderLimit_Fixed as unknown as ComponentLike,
  },
  [METRIC_TYPES.TIERED]: {
    [CONTRACT_USAGE_STATUS.CONTRACTED_OVER]: ContractedOverLimit_Tiered as unknown as ComponentLike,
    [CONTRACT_USAGE_STATUS.CONTRACTED_UNDER]:
      ContractedUnderLimit_Tiered as unknown as ComponentLike,
    [CONTRACT_USAGE_STATUS.NON_CONTRACTED_OVER]:
      NonContractedOverLimit_Tiered as unknown as ComponentLike,
    [CONTRACT_USAGE_STATUS.NON_CONTRACTED_UNDER]:
      NonContractedUnderLimit_Tiered as unknown as ComponentLike,
  },
  [METRIC_TYPES.BUCKETED]: {
    [CONTRACT_USAGE_STATUS.CONTRACTED_OVER]:
      ContractedOverLimit_Bucketed as unknown as ComponentLike,
    [CONTRACT_USAGE_STATUS.CONTRACTED_UNDER]:
      ContractedUnderLimit_Bucketed as unknown as ComponentLike,
  },
  [METRIC_TYPES.BUCKETED_MULTI_WORKSPACE]: {
    [CONTRACT_USAGE_STATUS.CONTRACTED_OVER]:
      ContractedOverLimit_Bucketed_MultiWorkspace as unknown as ComponentLike,
    [CONTRACT_USAGE_STATUS.CONTRACTED_UNDER]:
      ContractedUnderLimit_Bucketed_MultiWorkspace as unknown as ComponentLike,
  },
};

interface Args {
  pricingMetric: PricingMetric;
  currentBillingPeriodCharges: $TSFixMe;
  metricPerUnitPrice: number;
  metricPrice: Charge;
  currentUsage: number;
  contractAllowanceForMetric: number;
  shouldUseBucketedMetric: boolean;
  finBucketsCumulativeUsage: number;
  finBucketsCumulativeUsageForAllWorkspaces: number;
  finUsageThisPeriod: number;
  bucketDurationInMonths: number | any;
  contractedUsage: number;
  isMultiWorkspace: boolean;
  totalUsage: number;
}

export default class UsageTooltip extends Component<Args> {
  @service declare intl: IntlService;
  @service declare appService: any;

  get metricKey() {
    return this.args.pricingMetric.metric;
  }

  get metricTooltipMapping() {
    return getMetricTooltipMapping(this.metricKey, this.intl);
  }

  get mappedTitle(): string | undefined {
    return this.metricTooltipMapping?.title || '';
  }

  get currentMetricCharge() {
    let charge_key = this.metricTooltipMapping?.charge_key;

    return getCurrentMetricCharge(charge_key, this.args.currentBillingPeriodCharges);
  }

  // This is the only row that appears in every tooltip variation
  // (except when Fin buckets is exceeding it in the current period),
  // so we are leaving it here.
  get usageRow() {
    let usage = this.args.shouldUseBucketedMetric
      ? this.args.finUsageThisPeriod
      : this.args.currentUsage;

    return [
      {
        label: this.metricTooltipMapping?.tooltipUsageRowTitle || '',
        muted: true,
        noPadding: true,
        strong: true,
      },
      { label: `${usage}` },
    ] as TooltipRow[];
  }

  get shouldShowUsageThisPeriodRow() {
    if (
      (this.metricType === METRIC_TYPES.BUCKETED &&
        this.status === CONTRACT_USAGE_STATUS.CONTRACTED_OVER) ||
      (this.metricType === METRIC_TYPES.BUCKETED_MULTI_WORKSPACE && this.args.isMultiWorkspace)
    ) {
      return false;
    }
    return true;
  }

  get status() {
    if (this.args.shouldUseBucketedMetric) {
      if (this.isFinBucketedOverLimit) {
        return CONTRACT_USAGE_STATUS.CONTRACTED_OVER;
      } else {
        return CONTRACT_USAGE_STATUS.CONTRACTED_UNDER;
      }
    }

    return getContractUsageStatus(this.appService.app, this.currentMetricCharge);
  }

  get isFinBucketedOverLimit() {
    if (
      (this.metricType === METRIC_TYPES.BUCKETED_MULTI_WORKSPACE &&
        this.args.finBucketsCumulativeUsageForAllWorkspaces >
          this.finBucketResolutionsInContract) ||
      (this.metricType === METRIC_TYPES.BUCKETED &&
        this.args.finBucketsCumulativeUsage > this.args.contractedUsage)
    ) {
      return true;
    }

    return false;
  }

  get metricType() {
    if (this.args.shouldUseBucketedMetric) {
      // support multi workspace fin buckets tooltip variants, but only with FF for now
      if (this.args.isMultiWorkspace && this.appService.app.usagePageTweaksForFinBuckets) {
        return METRIC_TYPES.BUCKETED_MULTI_WORKSPACE;
      }
      return METRIC_TYPES.BUCKETED;
    }
    return this.metricTooltipMapping?.metricType || METRIC_TYPES.SEAT;
  }

  get component() {
    let metricType = dasherize(this.metricType);
    let status = dasherize(this.status);
    return COMPONENT_MAP[metricType]?.[status] ?? null;
  }

  get allowanceForBillingPeriod() {
    // no currentMetricCharge = under limit - use contract allowance
    if (!this.currentMetricCharge) {
      return this.args.contractAllowanceForMetric;
    }
    return parseInt(this.currentMetricCharge.allowance?.quantity, 10);
  }

  get overageQuantityForBillingPeriod() {
    // no currentMetricCharge = no overage
    if (!this.currentMetricCharge) {
      return 0;
    }
    return parseInt(this.currentMetricCharge.quantity, 10);
  }

  get calculateDiscountPercent() {
    if (!this.currentMetricCharge) {
      return 0;
    }
    return this.intl.formatNumber(
      (this.currentMetricCharge.discount_amount / this.currentMetricCharge.total_amount) * 100,
      {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      },
    );
  }

  get currentMetricChargeDue() {
    if (!this.currentMetricCharge) {
      return 0;
    }
    let amountDue = (this.currentMetricCharge.amount_due / 100).toFixed(2);
    return this.intl.formatNumber(amountDue, {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  get finBucketResolutionsInContract() {
    return this.args.contractedUsage * this.args.bucketDurationInMonths;
  }

  get formattedFinBucketResolutionsInContract() {
    return this.intl.formatNumber(this.finBucketResolutionsInContract, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Usage::TooltipsNew::UsageTooltip': typeof UsageTooltip;
  }
}
