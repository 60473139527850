/* import __COLOCATED_TEMPLATE__ from './card-header.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { getPricingMetricDataFor } from 'embercom/lib/billing';
import { isPeopleReachedPlan, isServicesPlan } from 'embercom/lib/billing/plans';
import { Metric } from 'embercom/models/data/pricing/metric-types';

interface Args {
  planId: number;
  productId: number;
}

type TooltipInfo = {
  key: string;
  descriptionTranslationKey: string;
  helpLinkUrl: string;
  helpLinkTextTranslationKey: string;
};

const SERVICES_TOOLTIP_INFO: { [key: number]: TooltipInfo } = {
  24: {
    key: 'premier_onboarding',
    descriptionTranslationKey:
      'billing.summary.price-usage-breakdown-card.services-tooltips.premier-onboarding.description',
    helpLinkUrl: "https://www.intercom.com/help/en/articles/5952022-Intercom's-Premier-Services",
    helpLinkTextTranslationKey:
      'billing.summary.price-usage-breakdown-card.services-tooltips.premier-onboarding.help-link',
  },
  // 2023 3 tiers version of premier onboarding
  33: {
    key: 'premier_onboarding',
    descriptionTranslationKey:
      'billing.summary.price-usage-breakdown-card.services-tooltips.premier-onboarding.description',
    helpLinkUrl: "https://www.intercom.com/help/en/articles/5952022-Intercom's-Premier-Services",
    helpLinkTextTranslationKey:
      'billing.summary.price-usage-breakdown-card.services-tooltips.premier-onboarding.help-link',
  },
  25: {
    key: 'premier_success',
    descriptionTranslationKey:
      'billing.summary.price-usage-breakdown-card.services-tooltips.premier-success.description',
    helpLinkUrl: "https://www.intercom.com/help/en/articles/5952022-Intercom's-Premier-Services",
    helpLinkTextTranslationKey:
      'billing.summary.price-usage-breakdown-card.services-tooltips.premier-success.help-link',
  },
  26: {
    key: 'premier_support',
    descriptionTranslationKey:
      'billing.summary.price-usage-breakdown-card.services-tooltips.premier-support.description',
    helpLinkUrl: "https://www.intercom.com/help/en/articles/5952022-Intercom's-Premier-Services",
    helpLinkTextTranslationKey:
      'billing.summary.price-usage-breakdown-card.services-tooltips.premier-support.help-link',
  },
};

export default class CardHeader extends Component<Args> {
  @service declare appService: any;

  get planId() {
    return this.args.planId.toString();
  }

  get data() {
    if (isPeopleReachedPlan(this.planId)) {
      return getPricingMetricDataFor(Metric.thirty_day_messaged_contacts, this.appService.app);
    } else if (isServicesPlan(this.planId)) {
      return SERVICES_TOOLTIP_INFO[this.args.productId];
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Summary::Tooltips::CardHeader': typeof CardHeader;
  }
}
