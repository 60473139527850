/* import __COLOCATED_TEMPLATE__ from './discount.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { type TooltipRow } from 'embercom/components/billing/usage/tooltips-new/shared-components/tooltip-item';

interface Args {
  currentMetricChargeDiscountPercentage?: string;
  currentMetricChargeDiscount?: number;
}

export default class Discount extends Component<Args> {
  @service declare intl: IntlService;

  get dataTestId() {
    return 'usage-discount-row';
  }

  get discountRow() {
    let discountInCents = this.args.currentMetricChargeDiscount || 0;
    let discountInDollars = discountInCents / 100;

    let res = [
      {
        label: this.intl.t('billing.summary.usage-tooltip.discount', {
          discount_percentage: this.args.currentMetricChargeDiscountPercentage,
        }),
        noPadding: true,
        success: true,
        value: `-${this.intl.formatNumber(discountInDollars, {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
      },
    ];
    return res as TooltipRow[];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Usage::TooltipsNew::SharedComponents::Discount': typeof Discount;
  }
}
