/* import __COLOCATED_TEMPLATE__ from './article-viewer.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed, action } from '@ember/object';
import { inject as service } from '@ember/service';
import { readOnly } from '@ember/object/computed';
import { CAN_CREATE_AND_EDIT_DRAFT_ARTICLES_PERMISSION } from 'embercom/lib/articles/constants';

export default Component.extend({
  showHistoryOverlay: false,
  router: service(),
  appService: service(),
  helpCenterService: service(),
  permissionsService: service(),
  classNames: ['flex-auto', 'flex', 'flex-col'],
  site: readOnly('helpCenterService.site'),
  app: readOnly('appService.app'),
  currentlySelectedLocaleId: readOnly('helpCenterService.currentlySelectedLocaleId'),

  selectedLocaleName: computed(
    'article',
    'site',
    'app',
    'currentlySelectedLocaleId',
    'helpCenterService',
    function () {
      return this.helpCenterService.allUniqueLocalesOrdered.find(
        (locale) => locale.localeId === this.currentlySelectedLocaleId,
      ).name;
    },
  ),

  selectedContent: computed('article', 'currentlySelectedLocaleId', function () {
    return this.article.contentByLocale(this.currentlySelectedLocaleId);
  }),

  addSelectedLocale: action(async function () {
    try {
      await this.permissionsService.checkPermission(CAN_CREATE_AND_EDIT_DRAFT_ARTICLES_PERMISSION);
    } catch (e) {
      return;
    }

    this.router.transitionTo(
      'apps.app.articles.articles.article-content.edit',
      await this.article.createContentWithVersionIfNone(this.currentlySelectedLocaleId),
    );
  }),

  isLatestInDraft: computed('selectedContent', 'selectedContent.liveVersionId', function () {
    if (this.selectedContent && this.selectedContent.liveVersionId) {
      return this.selectedContent.liveVersionId !== this.selectedContent.latestVersionId;
    }
    return false;
  }),
});
