/* import __COLOCATED_TEMPLATE__ from './list.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Component from '@glimmer/component';

export type ArticleInserterResult = {
  id: string;
  help_center_ids?: string[];
  title: string;
  author_id: string;
  visible_to_user: boolean;
  restricted_icon: boolean;
  origin: string;
};

type Args = {
  articles: ArticleInserterResult[];
  helpCenter: {
    id: string;
    name: string;
  };
  setPreviewArticle: (article: ArticleInserterResult, helpCenterId: string) => void;
};

export default class List extends Component<Args> {
  get filteredItems() {
    let result = this.args.articles.filter((i) => {
      return (
        i.help_center_ids?.includes(this.args.helpCenter.id) ||
        (this.args.helpCenter.id === 'unlisted' &&
          (!i.help_center_ids || i.help_center_ids.length === 0))
      );
    });

    return result;
  }

  get hasItemsToShow() {
    return this.filteredItems.length > 0;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::InboxInserter::List': typeof List;
    'articles/inbox-inserter/list': typeof List;
  }
}
