/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type IntlService from 'embercom/services/intl';
import { FIN_AI_COPILOT_BASE_ID, PLAN_DATA } from 'embercom/lib/billing';
import BillingConstants from 'embercom/lib/billing';
import { isPeopleReachedPlan, isServicesPlan } from 'embercom/lib/billing/plans';

interface Args {
  planId: number;
  productId: number;
  amount: number;
  shouldShowCancellation: boolean;
  shouldHidePrices: boolean;
  isTrialingAddOn: boolean;
  isAddon: boolean;
  isFixedTermCharge: boolean;
  onPricing5_XPlan: boolean;
  onFinForPlatforms: boolean;
  status: 'active' | 'cancelled' | 'trialing';
}

export default class Header extends Component<Args> {
  @service declare customerService: $TSFixMe;
  @service declare intl: IntlService;

  get planIdString() {
    return this.args.planId.toString();
  }

  get currentPrice() {
    return this.customerService.getPriceFromPlanIds([this.args.planId]);
  }

  get hasBaseCharge() {
    return this.currentPrice?.breakdown.any(({ plan_id }: { plan_id: string }) =>
      ['base_lite', 'base_standard'].includes(plan_id),
    );
  }

  get totalAmount() {
    if (this.hasBaseCharge) {
      return this.currentPrice.preDiscountAmount;
    }
    return this.args.amount;
  }

  get formattedPrice() {
    return this.args.isTrialingAddOn
      ? this.formatPriceFromCents(0, 0)
      : this.formatPriceFromCents(this.totalAmount, 2);
  }

  formatPriceFromCents(priceInCents: number, minimumFractionDigits = 0) {
    return this.formatPrice(priceInCents / 100, minimumFractionDigits);
  }

  formatPrice(price: number, minimumFractionDigits = 0) {
    return this.intl.formatNumber(price, {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits,
      maximumFractionDigits: 2,
    });
  }

  get shouldShowBaseChargeTooltip() {
    return this.hasBaseCharge && !this.args.isAddon;
  }

  get label() {
    let nameWithProduct = PLAN_DATA[this.args.planId]['nameWithProduct'];
    let planIdString = this.args.planId.toString();
    if (
      this.args.isAddon &&
      !(this.args.onPricing5_XPlan || this.args.onFinForPlatforms) &&
      !isPeopleReachedPlan(planIdString) &&
      !isServicesPlan(planIdString) &&
      planIdString !== FIN_AI_COPILOT_BASE_ID
    ) {
      return this.intl.t('billing.summary.price-usage-breakdown-card.add-on-name', {
        name: nameWithProduct,
      });
    }
    return nameWithProduct;
  }

  get description() {
    return BillingConstants.MODAL_ADD_PLAN_INFO[this.planIdString]?.descriptionTranslationKey
      ? this.intl.t(
          BillingConstants.MODAL_ADD_PLAN_INFO[this.planIdString].descriptionTranslationKey,
        )
      : null;
  }

  get paddingClass() {
    if (this.args.isFixedTermCharge) {
      return;
    } else if (this.args.onPricing5_XPlan || this.args.onFinForPlatforms) {
      if (isServicesPlan(this.args.planId.toString())) {
        return;
      }

      return 'pb-4';
    }

    return 'pb-3';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Summary::CardElements::Header': typeof Header;
  }
}
