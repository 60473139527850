/* import __COLOCATED_TEMPLATE__ from './pane.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import type HelpCenterSite from 'embercom/models/help-center-site';
import { type HelpCenterSettingNavigationHelper } from '../main';
import { PreviewType } from '../react-preview';
import { type SectionId } from 'embercom/services/help-center-setting-navigation-service';

interface Args {
  app: any;
  site: HelpCenterSite;
  navigationHelper: HelpCenterSettingNavigationHelper;
  setPreviewType: (previewType: string) => void;
}

const SECTION_TO_PREVIEW_TYPE: { [key: string]: PreviewType } = {
  header: PreviewType.Homepage,
  'welcome-message': PreviewType.Homepage,
  'order-and-layout': PreviewType.Homepage,
  'content-layout': PreviewType.Homepage,
  'related-articles': PreviewType.Article,
  author: PreviewType.Homepage,
  'table-of-contents': PreviewType.Article,
  descriptions: PreviewType.Collection,
  footer: PreviewType.Footer,
};

export default class Pane extends Component<Args> {
  @action
  onOpenSectionChange(sectionId: SectionId) {
    this.args.navigationHelper.navigate(sectionId);
    this.args.setPreviewType(SECTION_TO_PREVIEW_TYPE[sectionId]);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::Content::Pane': typeof Pane;
    'articles/site/settings/content/pane': typeof Pane;
  }
}
