/* import __COLOCATED_TEMPLATE__ from './restore-version-button.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type ArticleContent from 'embercom/models/articles/article-content';
import type IntlService from 'ember-intl/services/intl';
import type KnowledgeHubDrawerEditorService from 'embercom/services/knowledge-hub-drawer-editor-service';
import type KnowledgeHubEditorService from 'embercom/services/knowledge-hub-editor-service';

interface Args {
  article: ArticleContent;
  selectedVersion: any;
  closeSideDrawer: () => void;
  onVersionRestore: () => void;
  isLatestVersion: boolean;
}

export default class RestoreVersionButton extends Component<Args> {
  @service router: $TSFixMe;
  @tracked showConfirmModal = false;
  @service appService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare knowledgeHubDrawerEditorService: KnowledgeHubDrawerEditorService;
  @service declare knowledgeHubEditorService: KnowledgeHubEditorService;
  @action
  async restoreVersion() {
    await this.args.article.restoreVersion(this.args.selectedVersion);
    this.knowledgeHubEditorService.onContentUpdateCallback?.({
      type: 'edit',
      content: this.knowledgeHubEditorService.activeContentModel,
    });
    this.args.closeSideDrawer();
    this.transitionToEdit();
  }

  get app() {
    return this.appService.app;
  }

  get buttonCopy() {
    if (this.args.isLatestVersion) {
      return this.intl.t('articles.editor.side-drawer.restore-version.edit-latest');
    } else {
      return this.intl.t('articles.editor.side-drawer.restore-version.button-new');
    }
  }

  @action onButtonClick() {
    if (this.args.isLatestVersion) {
      this.restoreVersion();
    } else {
      this.showConfirmModal = true;
    }
  }

  transitionToEdit() {
    this.knowledgeHubDrawerEditorService.openEditDrawer({
      activeContentId: this.args.article.id,
      activeContentType: 'article',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Show::Content::RestoreVersionButton': typeof RestoreVersionButton;
    'articles/show/content/restore-version-button': typeof RestoreVersionButton;
  }
}
