/* import __COLOCATED_TEMPLATE__ from './header-links.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type HelpCenterSite from 'embercom/models/help-center-site';
import type HelpCenterSiteLink from 'embercom/models/help-center-site-link';
import type HelpCenterSiteLinkGroup from 'embercom/models/help-center-site-link-group';
import type Store from '@ember-data/store';
import { modifier } from 'ember-modifier';
import { schedule } from '@ember/runloop';

interface Args {
  site: HelpCenterSite;
  selectedLocale: string;
}

export default class HeaderLinks extends Component<Args> {
  maxLinksPerHeader = 3;

  setDefaultLinkGroup = modifier((_: Element, [_locale]: [string]) => {
    if (!this.headerLinkGroupByLocale) {
      schedule('afterRender', () => this.createDefaultLinkGroup());
    }
  });

  @service declare store: Store;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    if (!this.headerLinkGroupByLocale) {
      this.createDefaultLinkGroup();
    }
  }

  get headerLinkGroups(): Array<HelpCenterSiteLinkGroup> {
    return this.args.site.headerLinkGroups;
  }

  get headerLinkGroupByLocale() {
    return this.headerLinkGroups.findBy('locale', this.args.selectedLocale);
  }

  get headerLinkGroupLinks(): Array<HelpCenterSiteLink> {
    return this.headerLinkGroupByLocale?.links ?? [];
  }

  get canAddLinks(): boolean {
    if (!this.headerLinkGroupByLocale) {
      // The link group will be created when adding a link.
      return true;
    }
    return this.headerLinkGroupLinks.length < this.maxLinksPerHeader;
  }

  @action
  addLink() {
    if (!this.headerLinkGroupByLocale) {
      this.createDefaultLinkGroup();
    }

    this.headerLinkGroupByLocale!.addLink();
  }

  private createDefaultLinkGroup() {
    this.args.site.addHeaderLinkGroup(this.args.selectedLocale, 0);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::NewStyling::Navigation::HeaderLinks': typeof HeaderLinks;
  }
}
