/* import __COLOCATED_TEMPLATE__ from './tiered-pricing-tooltip-table.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type IntlService from 'embercom/services/intl';

interface PriceTier {
  id: number;
  starting_unit: number;
  ending_unit: number;
  price: number;
  price_format: string;
}

interface Signature {
  Args: {
    priceTiers: PriceTier[];
    contractedUsageLimit: number;
    overageQuantityForBillingPeriod: number;
    currentMetricChargeDue: string;
    dataTestId?: string;
  };
}

export default class TieredPricingTooltipTable extends Component<Signature> {
  @service declare intl: IntlService;

  formattedPrice = (price: number) => {
    return this.intl.formatNumber(price, {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 4,
      maximumFractionDigits: 4,
    });
  };

  get priceTiers() {
    let tiersCoveredByContractedUsage = this.tiersForQuantity(this.args.contractedUsageLimit);
    // since we subtract the tiers for contracted usage,
    // we need to ensure the final quantity is unchanged
    let tiersCoveredByCurrentUsage = this.tiersForQuantity(
      this.args.overageQuantityForBillingPeriod + this.args.contractedUsageLimit,
    );

    // Calculate the net usage for each tier by subtracting the contracted usage from the current usage
    let netUsageForTiers = tiersCoveredByCurrentUsage.map((currentTier) => {
      let contractedTier = tiersCoveredByContractedUsage.find((tier) => tier.id === currentTier.id);

      return {
        ...currentTier,
        usageForTier: contractedTier
          ? Math.max(0, currentTier.usageForTier - contractedTier.usageForTier)
          : currentTier.usageForTier,
      };
    });
    return netUsageForTiers.filter((tier) => tier.usageForTier >= 1);
  }

  tiersForQuantity = (usageQuantity: number) => {
    let remainingUsageToCount = usageQuantity;

    let tieredUsage = [];

    for (let tier of this.args.priceTiers) {
      let previousTier: PriceTier | undefined = tieredUsage[tieredUsage.length - 1];
      let startingUnit = tier.starting_unit;

      if (previousTier) {
        startingUnit = previousTier.ending_unit;
      }

      let sizeOfTier = tier.ending_unit - startingUnit;
      if (remainingUsageToCount < sizeOfTier) {
        tieredUsage.push({
          ...tier,
          usageForTier: remainingUsageToCount,
          isFullTier: false,
        });
        break;
      } else {
        tieredUsage.push({
          ...tier,
          usageForTier: sizeOfTier,
          isFullTier: true,
        });
        remainingUsageToCount -= sizeOfTier;
      }
    }
    return tieredUsage;
  };
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Usage::TooltipsNew::SharedComponents::TieredPricingTooltipTable': typeof TieredPricingTooltipTable;
  }
}
