/* import __COLOCATED_TEMPLATE__ from './grouped-charges-breakdown.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { type PlanCardRowsByCharge } from '../price-usage-breakdown-card';
import type Charge from 'embercom/models/billing/price/charge';
import type Plan from 'embercom/models/plan';
import { tracked } from '@glimmer/tracking';
import {
  PRICING_5_X_SMS_GROUP_KEY,
  PRICING_5_X_WHATSAPP_GROUP_KEY,
  PRICING_5_X_EMAIL_GROUP_KEY,
} from 'embercom/lib/purchase/constants';
import type Contract from 'embercom/models/billing/contract';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    plan: Plan;
    planId: number;
    groupIdentifier:
      | 'active_phone_numbers_count'
      | 'sms_segments_sent_received_monthly'
      | 'ai_helpdesk'
      | 'ai_bot'
      | 'sms'
      | 'whatsapp'
      | 'email'
      | 'calling';
    charges: Charge[];
    contract: Contract | undefined;
    shouldHidePrices: boolean;
    onPricing5_XPlan: boolean;
    onFinForPlatforms: boolean;
    allRowsByCharge: PlanCardRowsByCharge; // Deprecated - We're trying to move away from rows. Use the other args to generate the relevant content instead
  };
}
export default class GroupedChargesBreakdown extends Component<Signature> {
  @tracked hideUsageBreakdown = true;

  get supportsCollapsibleBreakdownCharges() {
    return [
      PRICING_5_X_SMS_GROUP_KEY,
      PRICING_5_X_WHATSAPP_GROUP_KEY,
      PRICING_5_X_EMAIL_GROUP_KEY,
    ].includes(this.args.groupIdentifier);
  }

  get collapsibleGroupIdentifier() {
    // collapsibleGroupIdentifier is only used when supportsCollapsibleBreakdownCharges is true
    return this.args.groupIdentifier as 'sms' | 'whatsapp' | 'email';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Summary::CardElements::GroupedChargesBreakdown': typeof GroupedChargesBreakdown;
  }
}
