/* RESPONSIBLE TEAM: team-knowledge-foundations */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { later } from '@ember/runloop';
import { DropActionType, type Tree, TreeItem, type TreeParent } from 'embercom/objects/tree-list';
import {
  type DataObject,
  type Collection,
  DataTypes,
  type ArticleSummary,
  type Content,
} from './types';
import type ArticleMultilingual from 'embercom/models/article-multilingual';

export let MAX_LEVEL = 2;
export let TIMEOUTS = {
  insertion: 125,
  expansionAndInsertion: 250,
  droppingInside: 500,
};
/**
 * To prevent having the tree in a inconsistent state the following rules apply
 * 1. If the target is a readonly collection
 * 2. Articles, can only be moved to collections not to the root
 * 3. Articles, can only be moved in between articles
 * 4. Collections, can only be moved in between collections
 * 5. Collections, can only be moved if after moving the whole subtree, the MAX_LEVEL is not surpassed
 */
export function canDropItem(
  draggingItem: TreeItem,
  targetParent: TreeParent,
  index: number,
  dropActionType: DropActionType,
): boolean {
  if (dropActionType === DropActionType.insideItem) {
    if (targetParent instanceof TreeItem) {
      let parent = targetParent;
      let targetParentDataObject = parent.dataObject as DataObject;
      let targetCollection = targetParentDataObject.content as Collection;
      if (targetCollection.read_only) {
        return false;
      }
    }
  } else if (dropActionType === DropActionType.nextToItem) {
    let dataObject = draggingItem.dataObject as DataObject;
    let childrenArray = targetParent.children!.toArray();
    let upperSibbling = index > 0 ? childrenArray[index - 1] : undefined;
    let lowerSibbling = index < childrenArray.length ? childrenArray[index] : undefined;
    if (dataObject.type === DataTypes.article) {
      if (targetParent.isRoot) {
        return false;
      } else if (upperSibbling && lowerSibbling) {
        let upperContent = upperSibbling.dataObject as DataObject;
        let lowerContent = lowerSibbling.dataObject as DataObject;
        if (
          upperContent.type === DataTypes.collection &&
          lowerContent.type === DataTypes.collection
        ) {
          return false;
        }
      } else if (upperSibbling) {
        let upperContent = upperSibbling.dataObject as DataObject;

        if (upperContent.type === DataTypes.collection) {
          return false;
        }
      }
    } else if (dataObject.type === DataTypes.collection) {
      if (upperSibbling && lowerSibbling) {
        let upperContent = upperSibbling.dataObject as DataObject;
        let lowerContent = lowerSibbling.dataObject as DataObject;
        if (upperContent.type === DataTypes.article && lowerContent.type === DataTypes.article) {
          return false;
        }
      }
      if (lowerSibbling) {
        let lowerContent = lowerSibbling.dataObject as DataObject;

        if (lowerContent.type === DataTypes.article) {
          return false;
        }
      }
      return checkMaxLevel(targetParent, draggingItem);
    }
  }

  return true;
}

export function checkMaxLevel(targetParent: TreeParent, item: TreeItem): boolean {
  if (targetParent instanceof TreeItem) {
    let parent = targetParent;
    let parentDataObject = parent.dataObject as DataObject;
    let allLevels: Array<number> = [...item.allChildren]
      .filter((childItem: TreeItem) => {
        let childDataObject = childItem.dataObject as DataObject;
        return childDataObject.type === DataTypes.collection;
      })
      .map((collectionItem: TreeItem) => {
        let collectionDataObject = collectionItem.dataObject as DataObject;
        return collectionDataObject.level;
      });
    let depth = allLevels.length ? Math.max(...allLevels) + 1 : 1;
    if (depth + parentDataObject.level > MAX_LEVEL) {
      return false;
    }
  }
  return true;
}

export function indexForDropInside(draggingItem: TreeItem, targetItem: TreeItem): number {
  let dataObject = draggingItem.dataObject as DataObject;
  if (dataObject.type === DataTypes.article && targetItem.children) {
    let nbArticles = targetItem.children.filter((child) => {
      return (child.dataObject as DataObject).type === DataTypes.article;
    }).length;
    return nbArticles;
  }
  return (targetItem.children?.length as number) ?? 0;
}

/**
 * Find which async actions will be triggered after a item has been dropped
 */
export function didDropItem(
  item: TreeItem,
  root: Collection,
  oldParent: TreeParent,
  dropActionTye: DropActionType,
  reorderCollection: (
    collection: Collection,
    newPosition: number,
    positionMap: Map<string, number>,
  ) => Promise<Collection>,
  moveCollection: (
    collection: Collection,
    parentCollection: Collection,
    newPosition: number,
    positionMap: Map<string, number>,
  ) => Promise<Collection>,
  moveArticle: (
    article: ArticleSummary,
    newCollection: Collection,
    newOrder: number,
    oldCollection?: Collection,
  ) => Promise<void>,
): void {
  if (!item) {
    return;
  }
  let contentObject = item.dataObject as DataObject;
  let parent = item.parent as TreeItem;
  let allSiblings = parent.children?.toArray();
  let newOrder = allSiblings?.indexOf(item) || 0;
  if (contentObject.type === DataTypes.article) {
    let articleSummary = contentObject.content as ArticleSummary;
    let parentDataObject = parent.dataObject as DataObject;

    let newCollection = parentDataObject.content as Collection;
    let oldCollection: Collection | undefined;

    if (articleSummary.collection !== parentDataObject.content) {
      oldCollection = articleSummary.collection;
      articleSummary.collection = parentDataObject.content as Collection;
    }

    moveArticle(articleSummary, newCollection, newOrder, oldCollection);

    contentObject.level = parentDataObject.level + 1;
    if (oldCollection) {
      updateCountForCollectionAndAncestors(newCollection, 1);
      updateCountForCollectionAndAncestors(oldCollection, -1);
    }
  } else if (contentObject.type === DataTypes.collection) {
    let collection = contentObject.content as Collection;
    let parentDataObject = parent.dataObject as DataObject;
    let isTheSameParent = item.parent === oldParent;
    let nbArticles =
      allSiblings?.filter((sibling) => {
        let dataObject = sibling.dataObject as DataObject;
        return dataObject.type === DataTypes.article;
      }).length || 0;
    let newCollectionPosition = newOrder - nbArticles;
    let positionMap = item.parent
      .children!.filter((child) => (child.dataObject as DataObject).type === DataTypes.collection)
      .reduce((acc, element, index) => {
        let childDataObject = element.dataObject as DataObject;
        acc.set(childDataObject.content.id, index);
        return acc;
      }, new Map<string, number>());
    if (!isTheSameParent) {
      let newParentLevel = parentDataObject ? parentDataObject.level : -1;
      let oldParentLevel = contentObject.level - 1;
      let deltaLevel = newParentLevel - oldParentLevel;
      if (deltaLevel) {
        [...item.allChildren].forEach((childItem) => {
          childItem.dataObject.level += deltaLevel;
        });
        contentObject.level += deltaLevel;
      }
      let parent = parentDataObject ? (parentDataObject.content as Collection) : root;
      moveCollection(collection, parent, newCollectionPosition, positionMap);
      updateCountForCollectionAndAncestors(parent, collection.count);
      let oldParentItem = oldParent as TreeItem;
      let oldParentDataObject = oldParentItem.dataObject as DataObject;
      if (oldParentDataObject && oldParentDataObject.type === DataTypes.collection) {
        let oldParent = oldParentDataObject.content as Collection;
        updateCountForCollectionAndAncestors(oldParent, -collection.count);
      }
    } else {
      reorderCollection(collection, newCollectionPosition, positionMap);
    }
  }

  if (dropActionTye === DropActionType.insideItem) {
    if (!parent.isExpanded) {
      parent.isExpanded = true;
    }
    later(() => focusOnItem(item), TIMEOUTS.droppingInside);
  }
}

export function focusOnItem(item: TreeItem, withSelection = true) {
  let dataObject = item.dataObject as DataObject;
  let isCollection = dataObject.type === DataTypes.collection;
  let identifier = `[${
    isCollection ? 'data-collection-tree-collection-id' : 'data-collection-tree-article-id'
  }="${dataObject.content.id}"]`;
  let newElement = document.querySelector(identifier);
  if (newElement) {
    newElement.scrollIntoView({ block: 'center' });
    if (isCollection) {
      let nameInput = newElement.querySelector('input');
      if (nameInput) {
        nameInput.focus();
        if (withSelection) {
          nameInput.select();
        }
      }
    }
  }
}

export function sortContent(content1: Content, conten2: Content) {
  return content1.order - conten2.order;
}

export function buildNewCollectionNode(
  tree: Tree,
  collection: Collection,
  level: number,
  locale: () => string,
  addCollection: (item: TreeItem) => Promise<void>,
  addArticlesToCollection: (articles: Array<any>, collectionItem: TreeItem) => Promise<void>,
  listAddableArticles: () => Promise<Array<ArticleMultilingual>>,
  removeCollection: (collectionItem: TreeItem) => Promise<void>,
  saveCollection: (collection: Collection) => Promise<void>,
  isExpanded = false,
  isRecentlyAdded = false,
) {
  let collectionModel = collection as any;
  collectionModel.initLocalizedContent();
  let newItem = new TreeItem({
    tree,
    parent: tree,
    children: [],
    isExpanded,
    canHaveChildren: true,
    dataObject: {
      content: collection,
      locale,
      type: DataTypes.collection,
      level,
      listAddableArticles,
      isRecentlyAdded,
      addCollection,
      addArticlesToCollection,
      saveCollection,
      deleteCollection: removeCollection,
    },
    component: 'articles/site/collections/tree/collection-item',
  });
  newItem.dataObject.addCollection = () => addCollection(newItem);
  newItem.dataObject.addArticlesToCollection = (articles: Array<any>) =>
    addArticlesToCollection(articles, newItem);
  newItem.dataObject.saveCollection = () => saveCollection(collection);
  newItem.dataObject.deleteCollection = () => removeCollection(newItem);
  return newItem;
}

export function buildNewArticleNode(
  tree: Tree,
  article: ArticleSummary,
  level: number,
  locale: () => string,
  defaultLocale: () => string,
  removeArticleFromCollection: (articleItem: TreeItem) => Promise<void>,
): TreeItem {
  let item = new TreeItem({
    tree,
    parent: tree,
    children: [],
    isExpanded: false,
    canHaveChildren: false,
    dataObject: {
      content: article,
      level,
      locale,
      defaultLocale,
      type: DataTypes.article,
      removeArticleFromCollection,
    },
    component: 'articles/site/collections/tree/article-item',
  });
  item.dataObject.removeArticleFromCollection = () => removeArticleFromCollection(item);
  return item;
}

export function updateCountForCollectionAndAncestors(
  collection: Collection | undefined,
  countDelta: number,
) {
  let currentNode = collection;
  while (currentNode && !currentNode.get('isHome')) {
    currentNode.set('count', currentNode.get('count') + countDelta);
    currentNode = currentNode.get('parent') as Collection;
  }
}
