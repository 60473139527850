/* import __COLOCATED_TEMPLATE__ from './site-asset-upload-component.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import Component from '@glimmer/component';
import { action } from '@ember/object';
// @ts-ignore
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';

type Args = {
  type: string;
  text: string;
  imageStyle?: string;
  mediaHelper: any;
  siteUrl?: string;
  themeColor?: string;
  action?: () => void;
  onSelectExternalIcon?: () => void;
};

export default class SiteAssetUploadComponent extends Component<Args> {
  isDragging = false;
  file?: File;
  siteUrl = this.args.siteUrl;

  get fileExtensions() {
    let fileExtensions = ['.png', '.gif', '.jpeg', '.jpg'];
    return this.args.type === 'externalIcon' ? [...fileExtensions, '.svg'] : fileExtensions;
  }

  get hasFile() {
    return !!this.args.mediaHelper?.file;
  }

  get hasUrl() {
    return !!this.siteUrl;
  }

  get hasContent() {
    return this.hasFile || this.hasUrl;
  }

  get backgroundStyle() {
    return this.args.themeColor ? sanitizeHtml(`background-color: #${this.args.themeColor}`) : '';
  }

  get imageUrl(): string | undefined {
    let mediaHelper = this.args.mediaHelper;
    if (
      mediaHelper &&
      mediaHelper.file &&
      mediaHelper.url &&
      mediaHelper.file.type !== 'image/svg+xml'
    ) {
      return mediaHelper.url;
    }
    return this.siteUrl;
  }

  @action
  onClickIcon() {
    if (this.args.onSelectExternalIcon) {
      this.args.onSelectExternalIcon();
    }
  }

  @action
  async onFilesSelected(files: File[]) {
    if (files.length === 0) {
      return;
    }
    let file = files[0]; // multi upload is not supported
    await this.args.mediaHelper.readFile(file);

    this.isDragging = false;
    this.file = file;

    if (['logo', 'footerLogo', 'favicon', 'social_image'].includes(this.args.type)) {
      this.siteUrl = this.args.mediaHelper?.url;
    }
    if (this.args.action) {
      this.args.action();
    }
  }

  @action
  reset() {
    let mediaHelper = this.args.mediaHelper;
    mediaHelper.setProperties({
      url: '',
      file: '',
    });
    if (this.siteUrl) {
      mediaHelper.setProperties({
        isReset: true,
        isSaved: false,
      });
    } else {
      mediaHelper.setProperties({
        isReset: false,
        isSaved: true,
      });
    }

    this.isDragging = false;
    this.file = undefined;
    this.siteUrl = '';

    if (this.args.action) {
      this.args.action();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::SiteAssetUploadComponent': typeof SiteAssetUploadComponent;
  }
}
