/* import __COLOCATED_TEMPLATE__ from './redirects-drawer.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */

import Component from '@glimmer/component';
import type HelpCenterSite from 'embercom/models/help-center-site';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { type TaskGenerator, timeout } from 'ember-concurrency';
import type MutableArray from '@ember/array/mutable';
import { action } from '@ember/object';
import type HelpCenterRedirect from 'embercom/models/help-center-redirect';
import { A } from '@ember/array';
import { EntityType } from 'embercom/models/data/entity-types';
import type IntlService from 'embercom/services/intl';
import type Store from '@ember-data/store';
import { isEmpty } from '@ember/utils';

const PAGE_SIZE = 20;
const SEARCH_DEBOUNCE_MS = 300;

type Args = {
  site: HelpCenterSite;
};

type Meta = {
  next_page: number | null;
  total_count: number;
};

type AvailableTab = 'articles' | 'collections';

enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

type TableSortState = {
  valuePath: string;
  direction: SortDirection;
};

export default class RedirectsDrawer extends Component<Args> {
  @service declare store: Store;
  @service declare notificationsService: any;
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare helpCenterService: any;

  @tracked isDrawerOpen = false;
  @tracked isModifyDrawerOpen = false;
  @tracked selectedTab: AvailableTab = 'articles';
  @tracked redirects: MutableArray<HelpCenterRedirect>;
  @tracked currentPage = 1;
  @tracked pageSize = PAGE_SIZE;
  @tracked meta: Meta | null = null;
  @tracked redirectToModify: HelpCenterRedirect | null = null;
  @tracked redirectToDelete: HelpCenterRedirect | null = null;
  @tracked showDeleteRedirectModal = false;
  @tracked searchPhrase = '';
  @tracked totalRedirects = 0;
  @tracked tableSortState: TableSortState = {
    valuePath: 'fromUrl',
    direction: SortDirection.DESC,
  };

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.redirects = [];
    taskFor(this.restartLoadHelpCenterRedirectsTask).perform();
  }

  @task({ restartable: true })
  *restartLoadHelpCenterRedirectsTask(): TaskGenerator<void> {
    this.redirects = A();
    this.currentPage = 1;
    this.meta = null;
    this.totalRedirects = 0;
    yield taskFor(this.loadHelpCenterRedirectsTask).perform();
  }

  @task({ restartable: true })
  *loadHelpCenterRedirectsTask(): TaskGenerator<void> {
    let sortBy =
      this.tableSortState.valuePath === 'fromUrl' ? 'url' : this.tableSortState.valuePath;
    let response = yield this.store.query('help-center-redirect', {
      page: this.currentPage,
      per_page: this.pageSize,
      entity_type: this.selectedEntityType,
      help_center_id: this.args.site.id,
      phrase: this.searchPhrase,
      order_by: sortBy,
      order_direction: this.tableSortState.direction,
    });
    this.redirects.pushObjects(response.toArray());
    this.meta = response.meta;
    this.totalRedirects = response.meta.total_count;
  }

  @task({ drop: true })
  *nextPageTask(): TaskGenerator<void> {
    this.currentPage = this.meta?.next_page || this.currentPage + 1;
    yield taskFor(this.loadHelpCenterRedirectsTask).perform();
  }

  @task({ restartable: true })
  *searchRedirectsTask() {
    yield timeout(SEARCH_DEBOUNCE_MS);

    yield taskFor(this.restartLoadHelpCenterRedirectsTask).perform();
  }

  @action setSelectedTab(tab: AvailableTab) {
    this.selectedTab = tab;
    this.searchPhrase = '';
    taskFor(this.restartLoadHelpCenterRedirectsTask).perform();
  }

  @action onSearchPhraseChange(event: { target: { value: string } }) {
    this.searchPhrase = event.target.value;
    taskFor(this.searchRedirectsTask).perform();
  }

  @action deleteRedirect(redirect: HelpCenterRedirect) {
    this.redirectToDelete = redirect;
    this.showDeleteRedirectModal = true;
  }

  @action async confirmDeleteRedirect() {
    if (!this.redirectToDelete) {
      return;
    }

    await this.redirectToDelete.destroyRecord();
    this.redirects.removeObject(this.redirectToDelete);

    this.notificationsService.notifyConfirmation(
      this.intl.t('articles.settings.redesign.general.domain.redirects.deletion.success'),
    );

    this.redirectToDelete = null;
    this.showDeleteRedirectModal = false;
    this.totalRedirects -= 1;
  }

  @action cancelDeleteRedirect() {
    this.redirectToDelete = null;
    this.showDeleteRedirectModal = false;
  }

  @action addNewRedirect() {
    this.redirectToModify = this.store.createRecord('help-center-redirect', {
      entityType: EntityType.ArticleContent,
      helpCenterId: this.args.site.id,
    });

    this.isDrawerOpen = false;
    this.isModifyDrawerOpen = true;
  }

  @action editRedirect(redirect: HelpCenterRedirect) {
    this.redirectToModify = redirect;
    this.isDrawerOpen = false;
    this.isModifyDrawerOpen = true;
  }

  @task *updateRedirectAndCloseModifyDrawer(): TaskGenerator<void> {
    try {
      let successMessage = this.isCreating
        ? 'articles.settings.redesign.general.domain.redirects.creation.success'
        : 'articles.settings.redesign.general.domain.redirects.update.success';
      yield this.redirectToModify?.save();

      this.notificationsService.notifyConfirmation(this.intl.t(successMessage));

      this.isModifyDrawerOpen = false;
      this.isDrawerOpen = true;
      this.redirectToModify = null;

      taskFor(this.restartLoadHelpCenterRedirectsTask).perform();
    } catch (error) {
      let errorMessage = this.isCreating
        ? this.intl.t('articles.settings.redesign.general.domain.redirects.creation.error')
        : this.intl.t('articles.settings.redesign.general.domain.redirects.update.error');
      if (error.jqXHR?.responseJSON?.errors) {
        errorMessage = error.jqXHR.responseJSON.errors;
      }
      this.notificationsService.notifyError(errorMessage);
    }
  }

  @action onModifyDrawerClose() {
    this.isModifyDrawerOpen = false;
    this.isDrawerOpen = true;
    this.redirectToModify?.rollbackAttributes();
    this.redirectToModify = null;
  }

  @action onSortUpdate(sortBy: string) {
    let currentDirection = this.tableSortState?.direction;
    let newDirection = SortDirection.ASC;

    if (sortBy === this.tableSortState.valuePath) {
      newDirection =
        isEmpty(currentDirection) || currentDirection === SortDirection.DESC
          ? SortDirection.ASC
          : SortDirection.DESC;
    }

    this.tableSortState = {
      valuePath: sortBy,
      direction: newDirection,
    };

    taskFor(this.restartLoadHelpCenterRedirectsTask).perform();
  }

  get placeholderEntityNameLabel() {
    return this.selectedTab === 'articles'
      ? this.intl.t('articles.settings.redesign.general.domain.redirects.drawer.missing-article')
      : this.intl.t(
          'articles.settings.redesign.general.domain.redirects.drawer.missing-collection',
        );
  }

  get selectedEntityType() {
    return this.selectedTab === 'articles'
      ? EntityType.ArticleContent
      : EntityType.ArticleCollection;
  }

  get canModifyHelpCenterRedirects() {
    return this.appService.app?.canModifyHelpCenterRedirects;
  }

  get isLoading() {
    return taskFor(this.loadHelpCenterRedirectsTask).isRunning;
  }

  get isLoadingNextPage() {
    return taskFor(this.nextPageTask).isRunning;
  }

  get canLoadMore() {
    return this.meta?.next_page;
  }

  get showEmptyState() {
    return !this.isLoading && this.redirects.length === 0;
  }

  get totalArticleCount() {
    return this.selectedTab === 'articles' ? this.totalRedirects : undefined;
  }

  get totalCollectionCount() {
    return this.selectedTab === 'collections' ? this.totalRedirects : undefined;
  }

  get isShowingArticleRedirects() {
    return this.selectedTab === 'articles';
  }

  get canShowSearchInput() {
    return this.isShowingArticleRedirects && this.canModifyHelpCenterRedirects;
  }

  get isCreating() {
    return this.redirectToModify?.isNew || false;
  }

  get statusColumnLabel() {
    let label = '';
    if (this.selectedTab === 'articles') {
      label =
        'articles.settings.redesign.general.domain.redirects.drawer.table.header.article-status-column-label';
    } else {
      label =
        'articles.settings.redesign.general.domain.redirects.drawer.table.header.collection-status-column-label';
    }
    return this.intl.t(label);
  }

  get columns() {
    let columns = [
      {
        label: this.intl.t(
          'articles.settings.redesign.general.domain.redirects.drawer.table.header.from',
        ),
        valuePath: 'fromUrl',
        width: this.canModifyHelpCenterRedirects ? '30%' : '40%',
        isSortable: true,
      },
      {
        label: this.intl.t(
          'articles.settings.redesign.general.domain.redirects.drawer.table.header.to',
        ),
        valuePath: 'name',
        width: this.canModifyHelpCenterRedirects ? '22%' : '30%',
      },
      {
        label: this.statusColumnLabel,
        valuePath: 'state',
        width: '30%',
      },
    ];

    if (this.canModifyHelpCenterRedirects) {
      columns.insertAt(2, {
        label: this.intl.t(
          'articles.settings.redesign.general.domain.redirects.drawer.table.header.language',
        ),
        valuePath: 'locale',
        width: '15%',
        isSortable: true,
      });
    }
    return columns;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    '::Articles::Site::Settings::General::RedirectsDrawer': typeof RedirectsDrawer;
  }
}
