/* import __COLOCATED_TEMPLATE__ from './custom-font-families.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type Font from 'embercom/models/customization-options/global-options/font';
import type FontFace from 'embercom/models/customization-options/global-options/font-face';
import { MediaHelper } from 'embercom/objects/media/media-helper';
import type IntlService from 'embercom/services/intl';
import { type FileValidator } from '../../upload-button';
import { helper } from '@ember/component/helper';
type Args = {
  fontOptions: Font;
  mediaHelper: MediaHelper;
};

const MAX_FONT_SIZE = 1000000;
export default class CustomFontFamilies extends Component<Args> {
  supportedFontExtensions = ['.woff', '.woff2', '.ttf', '.otf'];
  @service declare intl: IntlService;

  get fontFileValidators(): FileValidator[] {
    return [
      {
        validate: (file: File) => {
          return file.size < MAX_FONT_SIZE;
        },
        errorMessage: (file: File) =>
          this.intl.t('articles.settings.redesign.content.font.custom-fonts.size-error', {
            fileName: file.name,
          }),
      },
    ];
  }

  existsInList = helper(function ([allFontFaces, fontFace]: [FontFace[], FontFace]) {
    return fontFace.existsInList(allFontFaces);
  });

  @tracked
  openSectionId: string | null = null;

  @action
  async onFilesSelected(files: File[]) {
    for (let file of files) {
      await this.addFontFile(file);
    }
  }

  private async addFontFile(file: File) {
    let mediaHelper = MediaHelper.create({
      type: 'custom_font_face',
      siteSettingsName: 'fontFace',
    });
    await mediaHelper.readFile(file);
    await this.args.fontOptions.addFontFace(mediaHelper);
  }

  @action
  onOpenSectionChange(sectionId: string) {
    this.openSectionId = sectionId;
  }

  @action
  onFontFamilyChange(changingFontFace: FontFace, fontFamily: string) {
    this.args.fontOptions.ensureSelectedValuesUpdated(changingFontFace, fontFamily);
  }

  @action
  removeFontFace(fontFace: FontFace) {
    this.args.fontOptions.removeFontFace(fontFace);
    this.openSectionId = null;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::Styling::Fonts::CustomFontFamilies': typeof CustomFontFamilies;
    'articles/site/settings/styling/fonts/custom-font-families': typeof CustomFontFamilies;
  }
}
