/* import __COLOCATED_TEMPLATE__ from './additional-usage.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { type TooltipRow } from 'embercom/components/billing/usage/tooltips-new/shared-components/tooltip-item';

interface Args {
  additionalUsage: number;
  dataTestId?: string;
  currentMetricChargeDiscountPercentage?: string;
  currentMetricChargeDiscount?: number;
}

export default class AdditionalUsage extends Component<Args> {
  @service declare intl: IntlService;

  get additionalUsageRow() {
    let res = [
      {
        label: this.intl.t('billing.summary.usage-tooltip.additional_usage'),
        muted: true,
        noPadding: true,
        strong: true,
      },
      { label: `${this.args.additionalUsage}` },
    ];
    return res as TooltipRow[];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Usage::TooltipsNew::SharedComponents::AdditionalUsage': typeof AdditionalUsage;
  }
}
