/* import __COLOCATED_TEMPLATE__ from './content-authors.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import Component from '@ember/component';

export default Component.extend({
  authors: computed(
    'article.defaultContent.author',
    'article.orderedArticleContents.[]',
    'localeId',
    function () {
      if (!this.article) {
        return null;
      }

      if (this.localeId) {
        let content = this.article.contentByLocale(this.localeId);
        if (content) {
          return [content.author];
        } else {
          return null;
        }
      } else {
        return this.article.orderedArticleContents.compact().mapBy('author').uniqBy();
      }
    },
  ),

  firstAuthor: readOnly('authors.firstObject'),
});
