/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type HelpCenterSite from 'embercom/models/help-center-site';
import { type HelpCenterSettingNavigationHelper } from '../main';
import { type UploadHelper } from 'embercom/objects/media/media-helper';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    app: any;
    site: HelpCenterSite;
    uploadHelper: UploadHelper;
    navigationHelper: HelpCenterSettingNavigationHelper;
  };
}

export default class Header extends Component<Signature> {
  @service declare appService: any;

  @action
  goToConfigLanguage(): void {
    this.args.navigationHelper.navigate('language', 'general');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::NewStyling::Header': typeof Header;
  }
}
