/* import __COLOCATED_TEMPLATE__ from './price-usage-breakdown-card.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { PRODUCT_FEATURES } from 'embercom/lib/intershop/intershop';
import {
  ARTICLES_ID,
  PEOPLE_REACHED_ID,
  PRICING_5_X_EARLY_STAGE_PRICING_MODELS,
  FIN_AI_COPILOT_BASE_ID,
} from 'embercom/lib/billing';
import { includedAddOnProductIds } from 'embercom/lib/intershop/intershop';
import type Plan from 'embercom/models/plan';
import type IntlService from 'embercom/services/intl';
import PricingMetric from 'embercom/lib/purchase/pricing-metric';
import { Metric } from 'embercom/models/data/pricing/metric-types';
import { PRICING_METRIC_GROUPS, PRICING_5_X_GROUPS } from 'embercom/lib/purchase/constants';
import { isPresent } from '@ember/utils';
import type Contract from 'embercom/models/billing/contract';
import type Charge from 'embercom/models/billing/price/charge';
import { type PlanGroupId } from 'embercom/components/billing/summary/price-usage-breakdown-plan-group-header';
import type Store from '@ember-data/store';

// Deprecated - Avoid adding any new properties to PlanCardRow, instead pass args to the component directly
export type PlanCardRow = any;

// Deprecated - Avoid adding any new properties to PlanCardRowsByCharge, instead pass args to the component directly
export type PlanCardRowsByCharge = {
  [key in Metric]: PlanCardRow[];
};

// Deprecated - Avoid adding any new properties to Card, instead pass args to the component directly
export interface Card {
  planId: number;
  amount: number;
  charges: Charge[];
  planCardRowsByCharge: PlanCardRowsByCharge;
}

interface Args {
  planId: number;
  amount: number;
  charges: Charge[];
  shouldHidePrices: boolean;
  openEditPlanModal: (plan: Plan) => void;
  openRemoveProductModal: (plan: Plan) => void;
  contract?: Contract;
  allPlansForGraduationPricingModel?: Plan[];
  planGroupId?: PlanGroupId;
  isFirstCardInGroup: boolean;
  //  Deprecated - Below args will be cleaned up soon, use the above args to deduce any new content
  card: Card;
  transistionToIntershop: any;
  trackTrialAnalyticsEvent: any;
  onPricing5_XPlan: boolean;
  onFinForPlatforms: boolean;
}

const ORDER_OF_CHARGES = [
  Metric.fixed,
  Metric.core_seat_count,
  Metric.resolutions_with_custom_answers,
  Metric.sms_segments_sent_received_monthly_us,
  Metric.sms_segments_sent_received_monthly_ca,
  Metric.sms_segments_sent_received_monthly_uk,
  Metric.sms_segments_sent_received_monthly_au,
  Metric.sms_segments_sent_received_in_shifted_billing_cycle_us,
  Metric.sms_segments_sent_received_in_shifted_billing_cycle_ca,
  Metric.sms_segments_sent_received_in_shifted_billing_cycle_uk,
  Metric.sms_segments_sent_received_in_shifted_billing_cycle_au,
  Metric.whatsapp_inbound,
  Metric.whatsapp_outbound,
  Metric.emails_sent,
];

const STANDALONE_ALLOWED_METRICS = [Metric.core_seat_count, Metric.resolutions_with_custom_answers];

const NON_GROUPED_PLANS = [FIN_AI_COPILOT_BASE_ID];

export default class PriceUsageBreakdownCard extends Component<Args> {
  @service customerService: any;
  @service declare appService: any;
  @service router: any;
  @service declare intl: IntlService;
  @service declare intershopService: any;
  @service declare store: Store;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
  }

  get app() {
    return this.appService.app;
  }

  get customer() {
    return this.customerService.customer;
  }

  get isOnPricing5EarlyStage() {
    return PRICING_5_X_EARLY_STAGE_PRICING_MODELS.includes(
      this.customerService.currentPricingModelIdentifier,
    );
  }

  get displayIntershopPricingPageLink() {
    return (
      this.canViewIntershopPricingPage &&
      ![ARTICLES_ID, PEOPLE_REACHED_ID].includes(this.product.get('id')) &&
      this.intershopPlanPricingLinkIcon &&
      this.intershopPlanPricingLinkText
    );
  }

  get canViewIntershopPricingPage() {
    return this.plan && this.app.canAccessIntershop && this.intershopService.canAccessPricing;
  }

  get allPlansForPricingModel() {
    return this.args.allPlansForGraduationPricingModel
      ? this.args.allPlansForGraduationPricingModel
      : this.customerService.plans;
  }

  get plan(): Plan {
    return this.allPlansForPricingModel.find((plan: Plan) => plan.idAsNumber === this.args.planId);
  }

  get product() {
    return this.plan.product;
  }

  get intershopPlanPricingLinkIcon() {
    return PRODUCT_FEATURES[this.product.id]?.billingSummaryLinkIcon;
  }

  get intershopPlanPricingLinkText() {
    return PRODUCT_FEATURES[this.product.id]?.billingSummaryLinkText;
  }

  get showBillingCycleMetricsInBillingSummary() {
    return this.customer.showBillingCycleMetricsInBillingSummary;
  }

  get isAddon() {
    return !!this.product.addon;
  }

  get isTrialingAddOn() {
    return (
      (this.app.isSalesforceContracted || this.app.onPricing5) &&
      this.isAddon &&
      this.product.isOnTrial &&
      !this.isOnPricing5EarlyStage
    );
  }

  get trialExpiresIn() {
    return this.isTrialingAddOn ? this.product.daysToTrialExpiration : null;
  }

  get showIntershopBuyNowLink() {
    return (
      this.isTrialingAddOn &&
      this.app.canAccessIntershop &&
      includedAddOnProductIds().includes(this.product.id)
    );
  }

  get showRemoveProductLink() {
    return (
      this.customerService.canEditCurrentSubscription &&
      (this.canRemoveAddon || this.canRemoveCoreProducts)
    );
  }

  get canRemoveAddon() {
    //P5 Self serve annual customers shouldn't be able to remove addon immediately.
    if (this.customerService.isSelfServeAnnualCustomer && this.app.onPricing5) {
      return false;
    }
    return this.isAddon && !this.plan.get('deactivatedAt');
  }

  get planStatus() {
    if (this.isTrialingAddOn) {
      return 'trialing';
    }

    if (this.args.contract?.inRenewalPeriod) {
      return 'active';
    }

    return isPresent(this.plan.get('deactivatedAt')) ? 'cancelled' : 'active';
  }

  get activeCorePlans() {
    return this.customerService.activePlans.filter((plan: Plan) => !plan.product.addon);
  }

  get canRemoveCoreProducts() {
    return (
      !this.app.isOnValueBasedPricing &&
      !this.app.isSalesforceContracted &&
      !this.app.onPricing5 &&
      this.activeCorePlans.length > 1
    );
  }

  get pricingMetricsForCharges() {
    return this.charges.map((charge) => {
      return new PricingMetric(charge, this.intl);
    });
  }

  get planStartingPriceInCents() {
    return this.pricingMetricsForCharges
      .map((pricingMetric) => {
        return pricingMetric.basePrice;
      })
      .reduce((a: number, b: number) => a + b, 0);
  }

  get selfServeAnnualStartingPriceCharge() {
    let firstCharge = this.charges.firstObject;
    if (
      !this.customerService.isSelfServeAnnualCustomer &&
      firstCharge?.pricing_metric !== 'fixed'
    ) {
      return;
    }
    return firstCharge;
  }

  get usesOnlyBillingCycleChargeBreakdowns() {
    return (
      this.args.onPricing5_XPlan ||
      (this.customer.showBillingCycleMetricsInBillingSummary &&
        this.everyChargeIsBillingCycleVariation &&
        (this.args.contract?.isPrimarySubscription || !this.app.isSalesforceContracted))
    );
  }

  get hasOnlyFlatFeeCharge() {
    return this.charges.length === 1 && this.charges.firstObject!.pricingMetricModel.isFixedMetric;
  }

  get shouldShowPlanStartingPrice() {
    return (
      !this.hasOnlyFlatFeeCharge && // In this case, the flat fee is rendered as a normal charge
      this.planStartingPriceInCents > 0 &&
      !this.args.contract?.isSecondarySubscription &&
      // If the card contains only billing cycle metrics, we should NOT show a base price row.
      // That UI has a different way of showing what is included in the plan. However, we do currently have plans that
      // show a mixture eg. seats (non billing cycle) and people reached (billing cycle). In that case, we should show
      // the base price row.
      !this.usesOnlyBillingCycleChargeBreakdowns
    );
  }

  get isFixedTermCharge() {
    return !!this.charges.firstObject?.fixed_term_charge;
  }

  get everyChargeIsBillingCycleVariation() {
    return this.pricingMetricsForCharges.every((pricingMetric) => {
      return pricingMetric.usesBillingCycleMetric;
    });
  }

  get charges() {
    if (this.appService.app.canUseStandalone) {
      return this.args.charges.filter((charge) =>
        STANDALONE_ALLOWED_METRICS.includes(charge.pricing_metric),
      );
    }
    return this.args.charges;
  }

  get orderedCharges() {
    if (this.args.onPricing5_XPlan) {
      return this.charges.sort(
        (a, b) =>
          ORDER_OF_CHARGES.indexOf(a.pricing_metric) - ORDER_OF_CHARGES.indexOf(b.pricing_metric),
      );
    }
    return this.charges;
  }

  get groupedCharges() {
    if (NON_GROUPED_PLANS.includes(this.args.planId.toString())) {
      return;
    }

    if (this.args.onPricing5_XPlan) {
      // pp5_todo: Temporarily ungrouping SMS until we have designs for how to display it in Pricing 5.0, as this is the easiest way
      // to avoid breaking the Billing Summary in the meantime.
      return Object.entries(PRICING_5_X_GROUPS)
        .map(([groupKey, metricNames]) => {
          return {
            metricGroup: groupKey,
            charges: this.charges
              .filter(
                (charge) =>
                  metricNames.includes(charge.pricing_metric) && charge.isVisibleToCustomer,
              )
              .sortBy('pricing_metric'),
          };
        })
        .filter((group) => {
          return isPresent(group.charges);
        });
    }

    // For SMS we want to display metrics in groups based on active phone numbers vs SMS segments sent in regions
    return PRICING_METRIC_GROUPS.map((metricGroup) => {
      return {
        metricGroup,
        charges: this.charges
          .filter((charge) => charge.pricing_metric.startsWith(metricGroup))
          .sortBy('pricing_metric'),
      };
    }).filter((group) => {
      return isPresent(group.charges);
    });
  }

  get topBorderClass() {
    if (this.args.onPricing5_XPlan || this.args.onFinForPlatforms) {
      if (this.isAddon) {
        return;
      }

      return 'border-t-8 settings__billing__summary__border-top-color__pricing5-core';
    }
    if (this.args.planGroupId && this.args.isFirstCardInGroup) {
      return `border-t-8 settings__billing__summary__border-top-color__${this.args.planGroupId}`;
    }
    return;
  }

  get formattedDeactivatedAtDate() {
    return this.intl.formatDate(this.plan.get('deactivatedAt'), {
      format: 'D MMMM YYYY',
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });
  }

  @action
  transitionToIntershopPlanPricing() {
    this.router.transitionTo(
      'apps.app.intershop.products.product.plans.plan',
      this.product.get('slug'),
      this.plan.get('slug'),
    );
  }

  get shouldShowCancellation() {
    let stripeMigration = this.store.peekRecord('billing/stripe-migration', this.app.id);
    return this.planStatus === 'cancelled' && (!stripeMigration?.isPending ?? false);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Summary::PriceUsageBreakdownCard': typeof PriceUsageBreakdownCard;
    'billing/summary/price-usage-breakdown-card': typeof PriceUsageBreakdownCard;
  }
}
