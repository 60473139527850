/* import __COLOCATED_TEMPLATE__ from './article-tagging-modal.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type ArticleMultilingual from 'embercom/models/article-multilingual';
import type IntlService from 'ember-intl/services/intl';

interface Args {
  article: ArticleMultilingual;
  onClose?: () => void;
}

interface Signature {
  Args: Args;
}

export default class ArticleTaggingModal extends Component<Signature> {
  @service declare notificationsService: any;
  @service declare intl: IntlService;

  @action
  afterTagSave() {
    this.notificationsService.notifyConfirmation(
      this.intl.t('articles.shared.article-tagging-modal.tags-applied'),
    );
    this.args.article.reload();
    if (this.args.onClose) {
      this.args.onClose();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Shared::ArticleTaggingModal': typeof ArticleTaggingModal;
  }
}
