/* import __COLOCATED_TEMPLATE__ from './migration-help.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type StripeMigration from 'embercom/models/billing/stripe-migration';

interface Args {
  migration: StripeMigration;
}

interface Signature {
  Args: Args;
}

export default class BillingMigrationHelpComponent extends Component<Signature> {
  @tracked openSectionId: string | null = null;

  translationKey = 'billing.migrations.migration_help.';
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::StripeMigration::MigrationHelp': typeof BillingMigrationHelpComponent;
  }
}
