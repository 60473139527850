/* import __COLOCATED_TEMPLATE__ from './begin-trial.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { get } from 'embercom/lib/ajax';
import { findLatestPricing5PlanData, findLatestVBP2PlanData } from 'embercom/lib/billing';
import RouteRegexes from 'embercom/lib/route-regexes';

export default class BeginTrial extends Component {
  @service router;
  @service store;
  @service purchaseAnalyticsService;
  @service cardlessTrialService;
  @service intl;
  @service cardlessConversionModalService;
  @service appService;
  @tracked earlyStagePartner;

  constructor() {
    super(...arguments);
    this.checkAction();
    if (!this.app) {
      this.fetchRequiredAttributes();
    } else {
      this.earlyStagePartner = this.app.earlyStagePartner;
    }
  }

  get app() {
    return this.appService.app;
  }

  get validEarlyStageApplicant() {
    return this.cardlessTrialService.customer?.validEarlyStageApplicant;
  }

  get showUpgradeButton() {
    return this.cardlessTrialService.inTrialOrExpiredTrial;
  }

  get isInOnboardingHomeRoute() {
    return this.router.currentRouteName.match(RouteRegexes.onboardingHome);
  }

  get isInGuideLibraryRoute() {
    return this.router.currentRouteName.match(RouteRegexes.guideLibrary);
  }

  get canOnlyViewPlans() {
    return this.cardlessTrialService.inSalesProspectTrial;
  }

  get upgradeButtonLabel() {
    if (this.isFinStandaloneSelfServe) {
      return this.intl.t('top-banner-selector.subscribe');
    } else if (this.canOnlyViewPlans) {
      return this.intl.t('top-banner-selector.view_plans');
    } else {
      return this.intl.t('top-banner-selector.upgrade');
    }
  }

  get trialPlanName() {
    let trialPlanData;
    let trialPlanIds = this.cardlessTrialService.activeOrExpiredPlanTrialIds;

    if (this.cardlessTrialService.isPricing5) {
      trialPlanData = findLatestPricing5PlanData(trialPlanIds);
    } else {
      trialPlanData = findLatestVBP2PlanData(trialPlanIds);
    }
    return this.intl.t(trialPlanData.nameTranslationKey);
  }

  @action
  onUpgrade() {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object: 'upgrade_button',
      context: 'cardless_trial',
      place: 'enter_demo_banner',
    });
    if (this.isFinStandaloneSelfServe || !this.cardlessTrialService.customer.isStripeCustomer) {
      // If local customer bring them to the checkout which will show a blocked state
      // If isStandaloneApp bring them to checkout
      this.router.transitionTo('apps.app.teams-checkout', this.cardlessTrialService.appId);
    } else {
      this.cardlessConversionModalService.toggle();
    }
  }

  get isFinStandaloneSelfServe() {
    return this.appService.app.isStandaloneApp && this.cardlessTrialService.inTrialOrExpiredTrial;
  }

  @action
  transitionToEarlyStageCheckout() {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object: 'start_early_stage_subscription_button',
      context: 'cardless_trial',
      place: 'enter_demo_banner',
    });

    if (this.earlyStagePartner) {
      this.router.transitionTo('apps.app.teams-checkout', this.cardlessTrialService.appId, {
        queryParams: {
          partner: true,
        },
      });
    } else {
      this.router.transitionTo('apps.app.teams-checkout', this.cardlessTrialService.appId);
    }
  }

  @action
  requestTrialExtension() {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      context: 'cardless_trial',
      place: 'enter_demo_banner',
      object: 'request_extension_button',
    });
  }

  @action
  transitionToOnboardingHome() {
    this.store.findRecord('onboarding/home/guide', this.app.id);
    return this.router.transitionTo('apps.app.getting-started', this.app.id);
  }

  checkAction() {
    if (this.showUpgradeButton) {
      let queryParams = this.router.currentRoute?.queryParams;
      if (queryParams && queryParams.action === 'buy_intercom') {
        this.cardlessConversionModalService.toggle();
      }
    }
  }

  async fetchRequiredAttributes() {
    let response = await get(
      `/ember/apps/${this.cardlessTrialService.appId}.json?attribute_allowlist=early_stage_partner`,
    );
    this.earlyStagePartner = response.early_stage_partner;
  }
}
