/* import __COLOCATED_TEMPLATE__ from './main.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { groupBy } from 'underscore';
import { captureException } from 'embercom/lib/sentry';

export default class JiraForTickets extends Component {
  @service notificationsService;
  @service store;
  @service intl;
  @service appService;

  @tracked jiraIntegrationObject = null;
  @tracked isLoadingSettings = true;
  @tracked integrationMisconfigured = false;
  @tracked noTicketTypesFound = false;
  @tracked selectedProjects = [];
  @tracked jiraSites = [];
  @tracked isWarningModalOpened = false;
  @tracked shouldOpenWarningModal = false;

  constructor() {
    super(...arguments);
    this.fetchSettings.perform();
  }

  @task
  *fetchSettings() {
    try {
      this.jiraIntegrationObject = yield this.store.queryRecord(
        'appstore/app-package/jira-for-tickets/app-settings',
        {},
      );

      if (this.jiraIntegrationObject.intercomTicketTypes.length === 0) {
        this.noTicketTypesFound = true;
        return;
      }

      if (this.jiraIntegrationObject.intercomJiraTicketTypeMappings.length > 0) {
        this.selectedProjects = [
          ...new Set(
            this.jiraIntegrationObject.intercomJiraTicketTypeMappings.map(
              (mapping) => mapping.jira_project_id,
            ),
          ),
        ];
      }

      if (this.canShowJiraSitesList()) {
        let jiraSites = this.jiraIntegrationObject.jiraSites || [];
        if (jiraSites.length > 0) {
          this.jiraSites = this.jiraIntegrationObject.jiraSites.map((site) => ({
            text: site.name,
            value: site.id,
          }));
        }
      }

      this.isLoadingSettings = false;
      this.shouldOpenWarningModal = this.jiraIntegrationObject.automationType === 'custom';
    } catch (e) {
      this.integrationMisconfigured = true;
      // send this error to Sentry!
      captureException(e, {
        fingerprint: ['fetching-jira-settings', 'failed'],
        extra: {
          appId: this.appService.appId,
        },
        tags: {
          responsibleTeam: 'team-data-foundations',
          responsible_team: 'team-data-foundations',
        },
      });
    }
  }

  isDuplicateExists(item, itemIndex) {
    return !!this.jiraIntegrationObject.intercomJiraTicketTypeMappings.find(
      (mapping, index) =>
        mapping.intercom_ticket_type_id === item.intercom_ticket_type_id &&
        mapping.jira_ticket_type_id === item.jira_ticket_type_id &&
        mapping.jira_project_id === item.jira_project_id &&
        index < itemIndex,
    );
  }

  get intercomJiraTicketTypeProjectIdMappings() {
    return groupBy(
      this.jiraIntegrationObject.intercomJiraTicketTypeMappings.map((mapping, index) => {
        return {
          intercomTicketTypeId: mapping.intercom_ticket_type_id,
          jiraTicketTypeId: mapping.jira_ticket_type_id,
          jiraProjectId:
            mapping.jira_project_id || this.jiraIntegrationObject.jiraProjectsArray[0].value,
          index,
          isDuplicate: this.isDuplicateExists(mapping, index),
        };
      }),
      'jiraProjectId',
    );
  }
  get anyDubplicateExists() {
    return Object.values(this.intercomJiraTicketTypeProjectIdMappings).some((mappings) =>
      mappings.some((mapping) => mapping.isDuplicate),
    );
  }

  get isSaveButtonEnabled() {
    if (!this.jiraIntegrationObject) {
      return false;
    }

    return (
      (this.atLeastOneTicketTypeMapping && !this.anyDubplicateExists) ||
      !this.jiraIntegrationObject.isEnabled
    );
  }

  get atLeastOneTicketTypeMapping() {
    return (
      this.jiraIntegrationObject.intercomJiraTicketTypeMappings.length > 0 &&
      this.jiraIntegrationObject.intercomJiraTicketTypeMappings[0].intercom_ticket_type_id &&
      this.jiraIntegrationObject.intercomJiraTicketTypeMappings[0].jira_ticket_type_id &&
      this.jiraIntegrationObject.intercomJiraTicketTypeMappings[0].jira_project_id
    );
  }

  get isAnyProjectAvailable() {
    return Object.keys(this.intercomJiraTicketTypeProjectIdMappings).length > 0;
  }

  @action
  toggleJiraIntegration() {
    this.jiraIntegrationObject.isEnabled = !this.jiraIntegrationObject.isEnabled;
  }

  @action
  toggleWarningModal() {
    this.isWarningModalOpened = !this.isWarningModalOpened;
  }

  @action
  removeTicketTypeMappingByProjectId(projectId) {
    this.jiraIntegrationObject.intercomJiraTicketTypeMappings.removeObjects(
      this.jiraIntegrationObject.intercomJiraTicketTypeMappings.filter(
        (mapping) => mapping.jira_project_id === projectId,
      ),
    );
    this.jiraIntegrationObject.set('intercomJiraTicketTypeMappings', [
      ...this.jiraIntegrationObject.intercomJiraTicketTypeMappings,
    ]);
  }

  @action
  removeJiraProject(projectId, isRemoving = true) {
    this.removeTicketTypeMappingByProjectId(projectId);
    this.selectedProjects = this.selectedProjects.filter((id) => id !== projectId);
    if (!this.selectedProjects.length && isRemoving) {
      this.toggleJiraIntegration();
    }
  }

  @action
  addNewTicketTypeMapping(jiraProjectId) {
    this.jiraIntegrationObject.set('intercomJiraTicketTypeMappings', [
      ...this.jiraIntegrationObject.intercomJiraTicketTypeMappings,
      {
        intercom_ticket_type_id: this.jiraIntegrationObject.intercomTicketTypesArray[0].value,
        jira_ticket_type_id: this.jiraIntegrationObject.jiraTicketTypes[jiraProjectId][0].id,
        jira_project_id: jiraProjectId,
      },
    ]);
  }

  @action
  saveSettingsOrToggleWarningModal() {
    if (this.jiraIntegrationObject.automationType === 'basic' && this.shouldOpenWarningModal) {
      this.toggleWarningModal();
    } else {
      this.saveJiraIntegrationSettings();
    }
  }

  @action
  async saveJiraIntegrationSettings() {
    try {
      await this.jiraIntegrationObject.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('appstore.settings.jira-for-tickets.update-success'),
      );
      this.shouldOpenWarningModal = this.jiraIntegrationObject.automationType === 'custom';
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('appstore.settings.jira-for-tickets.update-error'),
      );
    }
    this.isWarningModalOpened = false;
  }

  @action
  removeTicketTypeMapping(index) {
    this.jiraIntegrationObject.intercomJiraTicketTypeMappings.removeAt(index);
    if (!this.jiraIntegrationObject.intercomJiraTicketTypeMappings.length) {
      this.toggleJiraIntegration();
    }
  }

  @action
  setJiraTicketType(value, index) {
    this.jiraIntegrationObject.intercomJiraTicketTypeMappings[index].jira_ticket_type_id = value;
    this.jiraIntegrationObject.set('intercomJiraTicketTypeMappings', [
      ...this.jiraIntegrationObject.intercomJiraTicketTypeMappings,
    ]);
  }

  @action
  setIntercomTicketType(value, index) {
    this.jiraIntegrationObject.intercomJiraTicketTypeMappings[index].intercom_ticket_type_id =
      value;
    this.jiraIntegrationObject.set('intercomJiraTicketTypeMappings', [
      ...this.jiraIntegrationObject.intercomJiraTicketTypeMappings,
    ]);
  }

  @action
  toggleSyncComments() {
    this.jiraIntegrationObject.syncComments = !this.jiraIntegrationObject.syncComments;
  }

  @action
  changeAutomationSettings(value) {
    this.jiraIntegrationObject.automationType = value;
    if (value === 'custom') {
      this.jiraIntegrationObject.isEnabled = false;
      this.jiraIntegrationObject.syncComments = false;
    }
  }

  @action
  addNewProjectMapping() {
    let newJiraProject = this.jiraIntegrationObject.jiraProjectsArray.find(
      (project) => this.selectedProjects.indexOf(project.value) === -1,
    );
    if (newJiraProject) {
      this.selectedProjects = [...this.selectedProjects, newJiraProject.value];
      this.addNewTicketTypeMapping(newJiraProject.value);
    }
  }

  @action
  addNewJiraProjectWithId(projectId) {
    this.selectedProjects = [...this.selectedProjects, projectId];
    this.addNewTicketTypeMapping(projectId);
  }

  @action
  onSelectJiraSite(site) {
    this.jiraIntegrationObject.selectedJiraSite = site;
  }

  canShowJiraSitesList() {
    return this.appService.app.canListJiraSitesOnJiraIntegrationSettings;
  }
}
