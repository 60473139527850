/* import __COLOCATED_TEMPLATE__ from './layout.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import type HelpCenterSite from 'embercom/models/help-center-site';
import { type HomePageBlock } from 'embercom/models/customization-options/layout-options/home-page/home-page-options';

interface Args {
  site: HelpCenterSite;
}

export default class Layout extends Component<Args> {
  groupName = `layout-${Math.random().toString(36).substring(7)}`;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    let { homeCollectionCols, selectedLocales } = this.args.site;
    this.homePageLayout.initialize({
      homeCollectionColumns: homeCollectionCols,
      selectedLocales,
    });
  }

  get homePageLayout() {
    return this.args.site.customizationOptions.layout.homePage;
  }

  get homePageBlocks() {
    return this.homePageLayout.blocks ?? [];
  }

  @action
  onItemOrdered(reorderedItems: HomePageBlock[]) {
    this.homePageLayout.reorderBlocks(reorderedItems.map((item) => item.type));
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::NewStyling::Layout': typeof Layout;
    'articles/site/settings/new-styling/layout': typeof Layout;
  }
}
