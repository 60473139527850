/* import __COLOCATED_TEMPLATE__ from './report-card.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import PricingMetric from 'embercom/lib/purchase/pricing-metric';
import { inject as service } from '@ember/service';
import { Metric } from 'embercom/models/data/pricing/metric-types';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { cached } from 'tracked-toolbox';
import { ChargeModel } from 'embercom/lib/purchase/pricing-metric';
import { hasFeature } from 'embercom/helpers/has-feature';
import moment from 'moment-timezone';
import {
  mapUsageReminderType,
  SMS_ALL_REGIONS,
  METRIC_CHART_TYPE_MAP,
  SMS_METRICS,
  METRICS_SUPPORTING_USAGE_ALERTS,
  METRICS_SUPPORTING_USAGE_LIMITS,
  getCurrentMetricCharge,
  getMetricTooltipMapping,
} from 'embercom/helpers/billing/usage-helper';
import {
  TARGETLINECLASS,
  TARGETLINEPALETTE,
} from 'embercom/lib/billing/usage/custom-usage-default-line-chart-config';
import ajax from 'embercom/lib/ajax';

export default class ReportCard extends Component {
  @service customerService;
  @service appService;
  @service intl;
  @service modalService;
  @service store;

  @tracked selectedMetric = this.chartMetrics[0];
  @tracked modalIsOpen = false;
  @tracked finBucketsUsage = {
    cumulative_usage: 0,
    contracted_usage: 0,
  };

  // NOTE: 'period' is defined as the time from the start of the current month's billing cycle till the end of it,
  // even if a customer is on a yearly/quarterly payment plan.
  @tracked finUsageThisPeriod = 0;

  constructor() {
    super(...arguments);

    if (this.shouldUseBucketedMetric) {
      this.loadFinBucketsUsage();
      this.loadFinBucketsUsageThisPeriod();
    }
  }

  async loadFinBucketsUsage() {
    try {
      let response = await ajax({
        url: '/ember/pricing_model_daily_app_stats/fin_buckets_usage',
        type: 'GET',
        data: {
          app_id: this.appService.app.id,
        },
      });
      this.finBucketsUsage = response;
    } catch (error) {
      console.error('Error fetching fin buckets usage:', error);
    }
  }

  async loadFinBucketsUsageThisPeriod() {
    let lastPmdasData = this.pmdasData.at(-1);
    this.finUsageThisPeriod = lastPmdasData ? lastPmdasData.resolutions_with_custom_answers : 0;
  }

  get app() {
    return this.appService.app;
  }

  get savedUsageReminder() {
    return this.args.usageReminders.find(
      (element) => element.usageType === mapUsageReminderType(this.selectedMetric),
    );
  }

  get currentMetricUsageLimit() {
    let currentLimitMetric = this.args.usageLimits?.find(
      (element) => element.metric === this.selectedMetric,
    );

    if (currentLimitMetric && currentLimitMetric.tableName) {
      return this.store.peekAll(currentLimitMetric.tableName).firstObject;
    }
    return undefined;
  }

  get usageModalLabelText() {
    if (this.selectedMetric === Metric.resolutions_with_custom_answers) {
      if (
        (this.savedUsageReminder && !this.savedUsageReminder.deleted) ||
        (this.currentMetricUsageLimit && this.currentMetricUsageLimit.enabled)
      ) {
        return 'billing.usage.modal-usage-alerts-and-limits.label_text_edit_alerts_limits';
      }

      return 'billing.usage.modal-usage-alerts-and-limits.label_text_start_alerts_limits';
    } else {
      if (this.savedUsageReminder && !this.savedUsageReminder.deleted) {
        return 'billing.usage.modal-usage-alerts-and-limits.label_text_edit_alerts';
      }
      return 'billing.usage.modal-usage-alerts-and-limits.label_text_start_alerts';
    }
  }

  get shouldShowUsageAlertProductTours() {
    // customers will see a product tour for emails sent regardless of if they have alerts set up
    return this.selectedMetric === Metric.emails_sent;
  }

  get shouldShowUsageLimitProductTours() {
    // customers will see a product tour for resolutions regardless of they have alerts or limits set up or not
    return this.selectedMetric === Metric.resolutions_with_custom_answers;
  }

  get customer() {
    return this.customerService.customer;
  }

  get isAnnualOrSalesforceContracted() {
    // new tooltips handle contracted logic separately
    if (this.app.canUseBillingSummaryRedesign) {
      return false;
    }
    return this.app.isSalesforceContracted || this.customerService.isSelfServeAnnualCustomer;
  }

  get priceToDisplay() {
    if (this.appService.app.canUseBillingSummaryRedesign) {
      if (
        this.isResolutionsMetric &&
        this.isMultiWorkspace &&
        this.app.usagePageTweaksForFinBuckets
      ) {
        return this.finMultiWorkspaceOveragePrice;
      }

      return this.updatedBillingSummaryPrice;
    }

    return this.price;
  }

  get price() {
    let charge =
      this.isAnnualOrSalesforceContracted && this.contract ? this.contractCharge : this.charge;

    let discount = charge?.discount_item;

    let overageDiscount = charge?.overage_discount_item;

    let totalDiscountAmount =
      (discount?.amount_in_cents ?? 0) + (overageDiscount?.amount_in_cents ?? 0);

    return this.centsToDollars(discount ? this.centPrice - totalDiscountAmount : this.centPrice);
  }

  get centPrice() {
    if (this.selectedMetric === SMS_ALL_REGIONS) {
      return this.cumulativePrice;
    }

    if (this.selectedMetric === Metric.messages_sent) {
      return this.calculateAdditionalUsagePrice;
    }

    if (this.isAnnualOrSalesforceContracted && this.contract) {
      return this.contractCharge.price;
    }

    return this.charge.price;
  }

  get calculateAdditionalUsagePrice() {
    let charge = this.isAnnualOrSalesforceContracted ? this.contractCharge : this.charge;
    return charge.calculateAdditionalUsagePrice;
  }

  centsToDollars(cents) {
    return cents / 100;
  }

  get currentUsage() {
    return this.chartData.length > 0 ? this.chartData.at(-1)[1] : 0;
  }

  get perWorkspaceUsage() {
    let workspace = this.contract.perWorkspaceUsage?.find(
      (workspace) => workspace.app_id === this.customerService.app.id,
    );

    return this.getUsage(workspace) ?? 0;
  }

  getUsage(workspace) {
    return workspace.usage?.[this.charge?.pricing_metric] ?? 0;
  }

  get totalUsage() {
    if (this.selectedMetric === SMS_ALL_REGIONS) {
      return this.cumulativeUsageForAllRegions;
    }

    return this.contract.perWorkspaceUsage.reduce(
      (sum, workspace) => sum + this.getUsage(workspace),
      0,
    );
  }

  get isMultiWorkspace() {
    return this.app.isSalesforceContracted
      ? this.contract.numberOfSecondarySubscriptions > 0 || this.contract.isSecondarySubscription
      : false;
  }

  get shouldShowUsageAlertLabel() {
    return METRICS_SUPPORTING_USAGE_ALERTS.includes(this.selectedMetric);
  }

  get contractAllowanceForMetric() {
    if (
      !this.contract.contractUsageLimits ||
      !(this.selectedMetric in this.contract.contractUsageLimits)
    ) {
      return 0;
    }
    return this.contract.contractUsageLimits[this.selectedMetric];
  }

  get shouldShowLimitPill() {
    return this.selectedMetric === Metric.resolutions_with_custom_answers && this.hasLimitBeenSet;
  }

  get hasLimitBeenSet() {
    return this.currentMetricUsageLimit && this.currentMetricUsageLimit.enabled;
  }

  get hasLimitBeenPassed() {
    return this.hasLimitBeenSet && this.currentMetricUsageLimit.threshold <= this.currentUsage;
  }

  get alertModalTitle() {
    let title = this.intl.t(this.args.title);
    let metricTitle = SMS_METRICS.includes(this.selectedMetric)
      ? this.intl.t(`billing.usage.pricing-metric-selector.${this.selectedMetric}`)
      : '';

    return `${title} ${metricTitle}`;
  }

  @cached
  get charge() {
    return this.charges.find((charge) => charge.pricing_metric === this.selectedMetric);
  }

  @cached
  get contractCharge() {
    return this.contractCharges.find((charge) => charge.pricing_metric === this.selectedMetric);
  }

  @cached
  get chargesPerMetric() {
    return this.charges.filter((charge) => charge.pricing_metric === this.selectedMetric);
  }

  @cached
  get chargePerMetric() {
    return this.charges.find((charge) => charge.pricing_metric === this.selectedMetric);
  }

  @cached
  get contractChargesPerMetric() {
    return this.contractCharges.filter((charge) => charge.pricing_metric === this.selectedMetric);
  }

  get pricingMetric() {
    return new PricingMetric(this.charge, this.intl);
  }

  get isChargeTiered() {
    return this.charge.charge_model === ChargeModel.Tiered;
  }

  get contract() {
    return this.args.contract;
  }

  get pmdasData() {
    return this.args.pmdasData || [];
  }

  get currentBillingPeriodCharges() {
    return this.args.currentBillingPeriodCharges || [];
  }

  get showContractedUsage() {
    return this.contractedUsage > 0;
  }

  get contractedUsage() {
    if (
      this.selectedMetric !== SMS_ALL_REGIONS &&
      !this.contract.contractUsageLimits[this.selectedMetric]
    ) {
      return this.charge.baseUsage;
    }

    return this.contract.contractUsageLimits[this.selectedMetric];
  }

  // gets total fin bucket usage for all workspaces from bucket start date
  get finBucketsCumulativeUsageForAllWorkspaces() {
    return (
      this.contract.perWorkspaceUsage?.reduce((sum, workspace) => {
        return sum + workspace.usage.fin_bucket_resolutions;
      }, 0) || 0
    );
  }

  get inSubscriptionText() {
    // if on Fin buckets, and on Resolutions metric report card, and FFs on, then show updated copy
    if (this.shouldUseBucketedMetric) {
      switch (this.bucketDurationInMonths) {
        case 3:
          return this.intl.t('billing.usage.in-quarterly-subscription');
        case 12:
          return this.intl.t('billing.usage.in-annual-subscription');
        default:
          return this.intl.t('billing.usage.in-subscription');
      }
    }

    return this.intl.t('billing.usage.in-subscription');
  }

  get chartType() {
    return METRIC_CHART_TYPE_MAP[this.selectedMetric];
  }

  // Default: dotted grey line if there is contracted usage and not a multiworkspace and no limit set
  // If limit is on:
  // grey full line for limit
  // yellow full line if limit has been reached

  get targetLine() {
    // Do not show target line for resolutions metric if on quarterly/annual billing
    // see https://github.com/intercom/intercom/issues/368194#issuecomment-2690666696
    if (
      this.isResolutionsMetric &&
      this.app.usagePageTweaksForFinBuckets &&
      this.billingDurationInMonths !== 1
    ) {
      return null;
    }

    let shouldShowContractedLine =
      this.contractedUsage && !this.isMultiWorkspace && !this.shouldUseBucketedMetric;
    let shouldShowLimitLine =
      METRICS_SUPPORTING_USAGE_LIMITS.find((element) => element.metric === this.selectedMetric) &&
      this.hasLimitBeenSet;
    let target = {
      value: this.contractedUsage,
      options: {
        palette: TARGETLINEPALETTE.default,
        lineClass: TARGETLINECLASS.default,
        dashStyle: 'dash',
      },
    };
    let limitTargetOptions = {
      value: this.hasLimitBeenSet ? this.currentMetricUsageLimit.threshold : 0,
      options: {
        palette: TARGETLINEPALETTE.limitOn,
        lineClass: TARGETLINECLASS.limitOn,
        dashStyle: 'solid',
      },
    };

    if (this.hasLimitBeenPassed) {
      limitTargetOptions = {
        value: this.currentMetricUsageLimit.threshold,
        options: {
          palette: TARGETLINEPALETTE.limitReached,
          lineClass: TARGETLINECLASS.limitReached,
          dashStyle: 'solid',
        },
      };
    }

    if (shouldShowContractedLine && shouldShowLimitLine) {
      target.secondaryTarget = limitTargetOptions;
    } else if (shouldShowLimitLine) {
      target = limitTargetOptions;
    }

    return shouldShowContractedLine || shouldShowLimitLine ? target : null;
  }

  get chartData() {
    let chartData = [];
    let billingDatesInUtc = this.getBillingDatesInUTCTimestamp();

    billingDatesInUtc.forEach((billingDate) => {
      let convertedDate = new Date(billingDate);

      let isCycleStartDate = moment
        .tz(new Date(convertedDate).toISOString(), 'UTC')
        .isSame(
          moment.tz(new Date(this.customer.currentBillingCycleStartDate).toISOString(), 'UTC'),
          'day',
        );

      if (isCycleStartDate && !this.customer.canReadUsageFromStripe) {
        chartData.push([convertedDate.getTime(), 0]);
      } else if (this.shouldAddChartValue(convertedDate)) {
        // IF PMDAS data doesn't exist for the last available day,
        // we shouldn't add it (sometimes PMDAS gets generated later in the day and it would mess up the charts and totals)
        chartData.push([convertedDate.getTime(), this.calculateChartValue(convertedDate)]);
      }
    });

    return chartData;
  }

  getBillingDatesInUTCTimestamp() {
    let billingDates = [];
    let dt = moment.tz(this.customer.currentBillingCycleStartDate, 'UTC');
    billingDates.push(dt.valueOf());
    while (!dt.isSame(moment.tz(new Date(), 'UTC'), 'day')) {
      dt.add(1, 'days');
      billingDates.push(dt.valueOf());
    }
    return billingDates;
  }

  calculateChartValue(billingDate) {
    let value = 0;

    if (this.selectedMetric === SMS_ALL_REGIONS) {
      this.metrics.forEach((metric) => {
        value += this.getPmdasValueForGivenDayAndMetric(billingDate, metric);
      });
    } else {
      value = this.getPmdasValueForGivenDayAndMetric(billingDate, this.selectedMetric);
    }
    return value;
  }

  shouldAddChartValue(billingDate) {
    let isBillingDateToday = this.isDateToday(billingDate);

    if (
      this.appService.app.canUseBillingSummaryRedesign &&
      isBillingDateToday &&
      (this.selectedMetric === 'core_seat_count' || this.selectedMetric === 'copilot_seat_count')
    ) {
      return false;
    }

    return (
      (isBillingDateToday && this.getPmdasValueForToday(this.selectedMetric)) || !isBillingDateToday
    );
  }

  isDateToday(date) {
    return moment.tz(date, 'UTC').isSame(moment.tz(new Date().toISOString(), 'UTC'), 'day');
  }

  getPmdasValueForToday(metricName) {
    if (metricName === SMS_ALL_REGIONS) {
      return this.pmdasData.find((element) => {
        return (
          this.isDateToday(element.created_at) &&
          (element[Metric.sms_segments_sent_received_in_shifted_billing_cycle_ca] >= 0 ||
            element[Metric.sms_segments_sent_received_in_shifted_billing_cycle_us] >= 0 ||
            element[Metric.sms_segments_sent_received_in_shifted_billing_cycle_au] >= 0 ||
            element[Metric.sms_segments_sent_received_in_shifted_billing_cycle_uk] >= 0)
        );
      });
    }

    return this.pmdasData.find((element) => {
      return this.isDateToday(element.created_at) && element[metricName] >= 0;
    });
  }

  getPmdasValueForGivenDayAndMetric(date, metricName) {
    let foundData = this.pmdasData.find((element) => {
      return moment.tz(element.created_at, 'UTC').isSame(date, 'day') && element[metricName] >= 0;
    });

    if (foundData) {
      return foundData[metricName];
    }
    return 0;
  }

  get metrics() {
    return this.args.pricingMetrics;
  }

  get chartMetrics() {
    if (this.args.type === 'sms') {
      return [SMS_ALL_REGIONS, ...this.metrics];
    }

    return this.metrics;
  }

  get hasMultipleMetrics() {
    return this.metrics.length > 1;
  }

  get dropdownItems() {
    return this.chartMetrics.map((metric) => {
      return {
        text: this.intl.t(`billing.usage.pricing-metric-selector.${metric}`),
        value: metric,
      };
    });
  }

  get showBreakdownTooltip() {
    if (this.selectedMetric === SMS_ALL_REGIONS) {
      return this.isMultiWorkspace;
    }

    if (this.isPhoneMetric || (this.currentUsage === 0 && this.price === 0)) {
      return false;
    }

    return true;
  }

  get isPhoneMetric() {
    return this.selectedMetric === Metric.calling;
  }

  get isResolutionsMetric() {
    return this.args.type === 'resolutions';
  }

  get formatType() {
    return this.isPhoneMetric ? 'USDwithCents' : undefined;
  }

  formatUsage(usage) {
    return this.isPhoneMetric ? this.centsToDollars(usage) : usage;
  }

  get formattedContractedUsage() {
    // if on Fin buckets, and on Resolutions metric report card, and FFs on, then return bucketed quantity
    if (this.shouldUseBucketedMetric) {
      return this.formatUsage(this.finBucketResolutionsInContract);
    }

    return this.formatUsage(this.contractedUsage);
  }

  get formattedTotalUsage() {
    return this.formatUsage(this.totalUsage);
  }

  // If we have the new endpoint to give us overage data from Stripe,and it's different than what we have from PMDAS, use that. Otherwise use PMDAS.
  get formattedCurrentUsage() {
    if (
      this.appService.app.canUseBillingSummaryRedesign &&
      (this.selectedMetric === 'core_seat_count' || this.selectedMetric === 'copilot_seat_count')
    ) {
      let yesterday = moment.tz('UTC').subtract(1, 'day').startOf('day');
      return this.getPmdasValueForGivenDayAndMetric(yesterday, this.selectedMetric);
    }

    return this.formatUsage(this.currentUsage);
  }

  get planId() {
    let breakdown = this.customerService.currentPrice.breakdown.find((breakdown) => {
      return breakdown.charges.find((charge) => {
        return charge.pricing_metric === this.selectedMetric;
      });
    });

    return breakdown.plan_id;
  }

  getCharges(price) {
    let charges = [];

    price.breakdown.forEach((breakdown) => {
      let planId = breakdown.plan_id;
      breakdown.charges.forEach((charge) => {
        charge.planId = planId.toString();
        charges.push(charge);
      });
    });

    return this.metrics.flatMap((metric) => {
      return charges.filter((charge) => charge.pricing_metric === metric);
    });
  }

  get charges() {
    return this.getCharges(this.customerService.currentPrice);
  }

  get contractCharges() {
    return this.getCharges(this.contract.currentPrice);
  }

  getChargePrice(charges) {
    return charges.reduce((accumulator, charge) => {
      return accumulator + charge.price;
    }, 0);
  }

  get cumulativePrice() {
    if (this.isAnnualOrSalesforceContracted && this.contract) {
      return this.getChargePrice(this.contractCharges);
    }
    return this.getChargePrice(this.charges);
  }

  get cumulativeUsage() {
    return this.charges.reduce((accumulator, charge) => {
      accumulator += this.isMultiWorkspace ? charge.actual_usage : charge.actualUsage;
      return accumulator;
    }, 0);
  }

  get cumulativeUsageForAllRegions() {
    return this.charges.reduce((accumulator, charge) => {
      accumulator += this.args.contract.totalUsage?.[charge.pricing_metric];
      return accumulator;
    }, 0);
  }

  get descriptionTooltipMetric() {
    if (this.args.type === 'sms' && this.selectedMetric === SMS_ALL_REGIONS) {
      return SMS_ALL_REGIONS;
    }

    if (this.args.type === 'whatsapp') {
      return 'whatsapp_inbound_outbound';
    }

    return this.selectedMetric;
  }

  get usesFinResolutionBuckets() {
    return this.customer.subscription.usesFinResolutionBuckets;
  }

  get billingDurationInMonths() {
    return this.customerService.customer.subscription.billingPeriodDurationInMonths;
  }

  get bucketDurationInMonths() {
    if (!this.usesFinResolutionBuckets) {
      return 1;
    }
    return this.billingDurationInMonths;
  }

  get updatedBillingSummaryPrice() {
    if (this.selectedMetric === SMS_ALL_REGIONS) {
      return this.centsToDollars(this.getAggregatedValueForAllSMSRegions('amount_due'));
    }

    return this.centsToDollars(this.getMetricValue(this.pricingMetric.metric, 'amount_due'));
  }

  get finMultiWorkspaceOveragePrice() {
    let pricePerFinResolution = this.chargePerMetric.per_unit_price / 100;
    let overageAmountAcrossAllWorkspaces =
      this.finBucketsCumulativeUsageForAllWorkspaces - this.finBucketResolutionsInContract;

    if (overageAmountAcrossAllWorkspaces <= 0) {
      return 0;
    }

    return pricePerFinResolution * overageAmountAcrossAllWorkspaces;
  }

  get finBucketResolutionsInContract() {
    return this.contractedUsage * this.bucketDurationInMonths;
  }

  getAggregatedValueForAllSMSRegions(property) {
    return this.metrics.reduce((sum, metric) => sum + this.getMetricValue(metric, property), 0);
  }

  getMetricValue(metric, property) {
    let chargeKey = getMetricTooltipMapping(metric, this.intl)?.charge_key;
    return (
      getCurrentMetricCharge(chargeKey, this.args.currentBillingPeriodCharges)?.[property] || 0
    );
  }

  // Billing Summary redesign: for now we are only using Fin bucketed metrics
  get shouldUseBucketedMetric() {
    if (
      (this.app.canUseBillingSummaryRedesign ||
        hasFeature('billing-usage-page-tweaks-for-fin-buckets', this.appService)) &&
      this.isResolutionsMetric &&
      this.usesFinResolutionBuckets
    ) {
      return true;
    }
    return false;
  }

  @action
  onMetricChange(value) {
    this.selectedMetric = value;
  }
}
