/* import __COLOCATED_TEMPLATE__ from './simple-usage-breakdown.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { capitalize } from '@ember/string';
import Component from '@glimmer/component';
import type Charge from 'embercom/models/billing/price/charge';
import { PLAN_DATA, PRICING_5_X_CORE_PLANS } from 'embercom/lib/billing';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { formatPriceFromCents } from 'embercom/components/billing/usage/tooltips/format-price';

interface Args {
  charges: Charge[];
}

export default class SimpleUsageBreakdown extends Component<Args> {
  @service declare intl: IntlService;

  get charge() {
    return this.args.charges[0];
  }

  get sections() {
    return this.args.charges.map((charge) => {
      return {
        metricTitle: PRICING_5_X_CORE_PLANS.includes(charge.planId)
          ? this.intl.t('billing.usage.tooltip.simple-usage-breakdown.price-per-metric-unit', {
              metric: this.formatMetricName(1),
            })
          : this.intl.t('billing.usage.tooltip.simple-usage-breakdown.price-per-seat', {
              metric: this.intl.t(PLAN_DATA[charge.planId].nameTranslationKey),
            }),
        price: charge.per_unit_price,
      };
    });
  }

  get formattedDiscountAmount() {
    return '-'.concat(formatPriceFromCents(this.charge.perUnitDiscount, this.intl));
  }

  get actualUsage() {
    return this.charge.actualUsage ?? 0;
  }

  get metricName() {
    return this.formatMetricName(2);
  }

  formatMetricName(pluralizeCount: number) {
    return capitalize(this.charge.pricingMetricModel.pluralize(pluralizeCount) ?? '');
  }

  get metricPluralizedForActualUsage() {
    return this.formatMetricName(this.actualUsage);
  }

  get formattedTotalFormula() {
    let usage = this.intl.formatNumber(this.actualUsage);
    let prices = this.args.charges.map(
      (charge) => `${formatPriceFromCents(charge.unitPriceWithDiscount, this.intl)}`,
    );
    let priceString = prices.length > 1 ? `(${prices.join(' + ')})` : prices.join(' + ');

    return `${usage} x ${priceString}`;
  }

  get formattedTotalUsage() {
    let usage = this.actualUsage;
    let price = this.args.charges.reduce((sum, charge) => sum + charge.per_unit_price, 0);

    return formatPriceFromCents(usage * price, this.intl);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Usage::Tooltips::SimpleUsageBreakdown': typeof SimpleUsageBreakdown;
  }
}
