/* import __COLOCATED_TEMPLATE__ from './article-list.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { bool, or } from '@ember/object/computed';
import { computed, action } from '@ember/object';
import Component from '@ember/component';
import { task, didCancel } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { adminHasSeenFromPageviews, fetchAdminPageviews } from 'embercom/lib/admin-view-tracking';
import { CAN_MANAGE_ARTICLES_PERMISSION } from 'embercom/lib/articles/constants';

export default Component.extend({
  store: service(),
  intl: service(),
  appService: service(),
  notificationsService: service(),
  helpCenterService: service(),
  router: service(),
  permissionsService: service(),
  classNames: ['flex-auto', 'flex', 'flex-col', 'min-h-0'],
  isLoading: true,
  adminPageviews: null,
  hasOnlyExampleArticle: computed('articles.{length,firstObject.titleForDisplay}', function () {
    return (
      this.articles.length === 1 &&
      this.articles[0].titleForDisplay?.toLowerCase() === 'getting started with articles'
    );
  }),
  showEmptyStateBanner: computed(
    'articles.{length,meta}',
    'hasOnlyExampleArticle',
    'isLoading',
    function () {
      return (
        !this.isLoading &&
        this.articles.meta !== undefined &&
        (this.hasOnlyExampleArticle || this.articles.length === 0)
      );
    },
  ),
  showPageIntroductionBanner: true,
  importButtonDisabled: or('hasImportId', 'hasActiveApiImport'),
  hasImportId: or('currentImportId', 'hasActiveSyncJob'),
  hasActiveSyncJob: bool('activeSyncJob'),
  intercomEventService: service(),
  init() {
    this._super(...arguments);
    this.getAdminPageviews
      .perform()
      .then(() => this._determineBannerDisplayLogic())
      .catch((e) => {
        if (!didCancel(e)) {
          throw e;
        }
      });
  },

  getAdminPageviews: task(function* () {
    let pageviews = yield fetchAdminPageviews(this.store);
    return this.set('adminPageviews', pageviews);
  }),

  _determineBannerDisplayLogic() {
    if (adminHasSeenFromPageviews(this.adminPageviews, 'page_introduction_banner_articles')) {
      this.set('showPageIntroductionBanner', false);
    }
  },

  isInternalSource: computed('request.conditions.sync', function () {
    let isSync = this.request?.conditions?.sync;
    return !(isSync === 'true');
  }),

  createArticle: action(function () {
    this.router.transitionTo('apps.app.articles.articles.new');
  }),

  importArticles: action(function () {
    this.permissionsService.checkPermission(CAN_MANAGE_ARTICLES_PERMISSION).then(() => {
      this.set('showArticleImportModal', true);
      this.triggerAnalyticsEvent('migrate_from_zendesk_import_dropdown_item');
    });
  }),

  syncArticles: action(function () {
    this.permissionsService.checkPermission(CAN_MANAGE_ARTICLES_PERMISSION).then(() => {
      this.set('showArticleSyncModal', true);
      this.triggerAnalyticsEvent('sync_from_zendesk_import_dropdown_item');
    });
  }),

  removeRegularSync: task(function* () {
    try {
      yield this.permissionsService.checkPermission(CAN_MANAGE_ARTICLES_PERMISSION);
    } catch (e) {
      return;
    }

    try {
      yield this.pauseRegularSync?.perform();
      this.set('showArticleCleanupBeforeSyncModal', false);
      this.set('showArticleImportModal', true);
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('articles.settings.sync.remove-sync.notification.error'),
      );
      this.set('showArticleCleanupBeforeSyncModal', false);
    }
  }),

  removeSyncBeforeOtherSync: task(function* () {
    try {
      yield this.permissionsService.checkPermission(CAN_MANAGE_ARTICLES_PERMISSION);
    } catch (e) {
      return;
    }

    try {
      yield this.pauseRegularSync?.perform();
      this.set('showRemoveOtherSyncModal', false);
      this.set('showArticleSyncModal', true);
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('articles.settings.sync.remove-sync.notification.error'),
      );
      this.set('showRemoveOtherSyncModal', false);
    }
  }),

  redirectToSettings: action(function () {
    this.router.transitionTo(
      'apps.app.articles.site.settings.setting',
      this.helpCenterService.site.id,
    );
  }),

  showCreateArticle: action(function () {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'create_article_helpcenter_link',
      context: `onboarding_guide_library.articles.banner.buttons`,
      place: 'app.articles.list.all',
      section: 'discovery_banner',
    });
    window.Intercom('showArticle', 56641); // https://www.intercom.com/help/en/articles/56641-create-your-first-article
  }),

  triggerAnalyticsEvent: action(function (object) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object,
      context: `onboarding_guide_library.articles.header.learn-dropdown`,
      place: 'app.articles.list.all.learn-import',
      section: 'learn_dropdown',
    });
  }),
});
