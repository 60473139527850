/* import __COLOCATED_TEMPLATE__ from './article-sync-modal.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Component from '@glimmer/component';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

const HELP_CENTER_REGEX = /\/hc/;

export default class ArticleSyncModal extends Component {
  @service store;
  @service realTimeEventService;
  @service openCenteredWindowService;
  @service notificationsService;
  @service intl;
  @service helpCenterService;
  @service appService;
  @tracked url = '';
  @tracked openSectionId;
  @tracked selectedHelpCenter;

  constructor() {
    super(...arguments);

    this.realTimeEventService.on('ImportStarted', this, '_handleStartedImport');
    this.selectedHelpCenter = this.helpCenterService.site;
  }

  get app() {
    return this.appService.app;
  }

  // show the dropdown only when needed
  get showHelpCenterDropdown() {
    return this.helpCenters.length > 1;
  }

  willDestroy() {
    super.willDestroy();
    this.realTimeEventService.off('ImportStarted', this, '_handleStartedImport');
  }

  get helpCenters() {
    return this.helpCenterService.sites;
  }

  get helpCenterOptions() {
    return this.helpCenters.map((site) => {
      return {
        text: site.name,
        value: site.id,
      };
    });
  }

  get isUrlInvalid() {
    let normalizedUrl = this.normalizeZendeskURL(this.url);

    if (!normalizedUrl) {
      return false;
    }

    try {
      let parsedUrl = new URL(normalizedUrl);
      return !parsedUrl.hostname.match(/.+\.zendesk\.com$/);
    } catch (e) {
      return true;
    }
  }

  _handleStartedImport(event) {
    this.notificationsService.notifyConfirmation(
      this.intl.t('articles.list.sync.start_notification'),
    );
    this.args.closeModal();
  }

  @action
  onOpenSectionChange(sectionId) {
    this.openSectionId = sectionId;
  }

  @action
  toggleExpand() {
    this.openSectionId = this.openSectionId ? null : 'sync_content_detail';
  }

  @action switchHelpCenter(value) {
    this.selectedHelpCenter = this.helpCenters.find((helpCenter) => helpCenter.id === value);
  }

  @action
  async startSync() {
    let url = this.normalizeZendeskURL(this.url);
    if (!url) {
      return;
    }
    let ZdUrl = await this.zendeskOAuthURL(url);
    // eslint-disable-next-line @intercom/intercom/no-bare-strings
    this.openCenteredWindowService.setup(ZdUrl, 800, 1280, 'Sync');
  }

  normalizeZendeskURL(url) {
    url = url?.trim();

    if (isEmpty(url)) {
      return;
    }

    if (url.match(/^http:\/\//)) {
      url = url.substring(7);
    }

    if (url.match(/^https/) === null) {
      url = `https://${url}`;
    }

    // This conditional will strip out the help center centric part of the URL
    if (url.match(HELP_CENTER_REGEX)) {
      let index = url.match(HELP_CENTER_REGEX).index;
      url = url.substring(0, index);
    }

    return url;
  }

  async zendeskOAuthURL(url) {
    let oauthState = this.store.createRecord('articles/oauth-state', {
      siteUrl: url,
      helpCenterId: this.selectedHelpCenter.id,
    });
    await oauthState.save();

    let stateJson = JSON.stringify({
      oauth_state_id: oauthState.id,
      mode: 'sync',
    });

    let queryParams = encodeURI(
      `response_type=code&redirect_uri=${this.selectedHelpCenter.get(
        'zendeskImporterRedirectUri',
      )}&client_id=${this.selectedHelpCenter.get(
        'zendeskImporterClientId',
      )}&scope=hc:read&state=${stateJson}`,
    );

    return `${url}/oauth/authorizations/new?${queryParams}`;
  }
}
