/* import __COLOCATED_TEMPLATE__ from './badge-help-centers.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import Component from '@glimmer/component';
import { type Collection } from '../site/collections/tree/types';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type HelpCenterSite from 'embercom/models/help-center-site';

interface Args {
  article: any;
}

export default class BadgeHelpCenters extends Component<Args> {
  @service declare store: Store;

  get article() {
    return this.args.article;
  }

  get helpCenters() {
    let collections = this.article.inCollections;
    let helpCenterIds = [
      ...new Set(collections.map((collection: Collection) => collection.helpCenterId)),
    ];
    let helpCenters = this.store
      .peekAll('help-center-site')
      .filter((helpCenter: HelpCenterSite) => helpCenterIds.includes(helpCenter.id));
    return helpCenters;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Shared::BadgeHelpCenters': typeof BadgeHelpCenters;
    'articles/shared/badge-help-centers': typeof BadgeHelpCenters;
  }
}
