/* import __COLOCATED_TEMPLATE__ from './guidance-support-info.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

interface Args {}

interface Signature {
  Args: Args;
}

export default class BillingStripeMigrationGuidanceSupportInfoComponent extends Component<Signature> {
  @service declare appService: $TSFixMe;
  @service declare customerService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;

  translationKey = 'billing.migrations.migration_help.';

  @action
  openMessenger() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      context: 'proactive_migrations',
      place: 'migration_help',
      object: 'open_messenger_button',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::StripeMigration::MigrationSubcontent::GuidanceSupportInfo': typeof BillingStripeMigrationGuidanceSupportInfoComponent;
  }
}
