/* import __COLOCATED_TEMPLATE__ from './collection-item.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type TreeItem } from 'embercom/objects/tree-list';
import { MAX_LEVEL } from './collection-tree-helper';
import { type Collection, type DataObject, DataTypes } from './types';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type RouterService from '@ember/routing/router-service';
import type ArticleGroup from 'embercom/models/articles/article-group';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';

interface Args {
  item: TreeItem;
}

export default class CollectionItem extends Component<Args> {
  @tracked collectionToEdit: ArticleGroup | undefined;
  @tracked openArticlePicker = false;

  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare appService: any;
  @service declare knowledgeHubService: KnowledgeHubService;

  get collectionDataObject(): DataObject {
    return this.args.item.dataObject;
  }

  get app() {
    return this.appService.app;
  }

  get locale() {
    return this.collectionDataObject.locale();
  }

  get collection() {
    return this.collectionDataObject.content as Collection;
  }

  get collectionStatus() {
    if (!this.collection.name) {
      return 'missing-title';
    }
    if (!this.contentHasPublishedChild(this.args.item)) {
      return 'missing-article';
    }
    return 'published';
  }

  get setupModalContext() {
    let context = {
      eventSource: 'collection_item',
      collection: this.collectionToEdit,
      localeId: this.locale,
    };
    return context;
  }

  contentHasPublishedChild(item: TreeItem): boolean {
    if (!item.children?.length) {
      return false;
    }
    return item.children.any((child) => {
      let cData = child.dataObject;
      let articleLocale = cData.locale && cData.locale();
      let selectedLocalizedArticle =
        articleLocale && cData.content?.locales && cData.content?.locales[articleLocale];
      return (
        selectedLocalizedArticle?.state === 'published' || this.contentHasPublishedChild(child)
      );
    });
  }

  @action
  closeArticlePicker() {
    this.openArticlePicker = false;
  }

  @action
  async removeCollection() {
    if (this.collectionDataObject.deleteCollection) {
      await this.collectionDataObject.deleteCollection();
    }
  }

  @action
  async saveCollection() {
    if (this.collectionDataObject.saveCollection) {
      await this.collectionDataObject.saveCollection();
    }
  }

  // Toggle isExpanded unless the click is on an element preventing expansion
  @action
  toggleIsExpanded(event: PointerEvent) {
    let preventExpandElements = Array.from(
      document.getElementsByClassName('prevent-colleciton-tree-expand'),
    );
    let target = event.target;

    if (!(target instanceof Element)) {
      return;
    }

    for (let element of preventExpandElements) {
      if (element.contains(target)) {
        return;
      }
    }

    this.args.item.isExpanded = !this.args.item.isExpanded;
  }

  @action
  setCollectionToEdit() {
    this.collectionToEdit = this.collectionDataObject.content as unknown as ArticleGroup;
  }

  @action
  async onAdd(dataType: DataTypes) {
    if (dataType === DataTypes.collection && this.collectionDataObject.addCollection) {
      await this.collectionDataObject.addCollection();
    } else if (dataType === DataTypes.article && this.collectionDataObject.listAddableArticles) {
      this.openArticlePicker = true;
    }
  }

  get contents() {
    let items = [
      {
        text: this.intl.t('articles.collections.actions.add_articles'),
        icon: 'article',
        onSelectItem: async () => {
          this.onAdd(DataTypes.article);
        },
        isDestructive: false,
        isDisabled: this.collection.read_only,
      },
      ...(this.collectionDataObject.level < MAX_LEVEL
        ? [
            {
              text: this.intl.t('articles.collections.actions.add_child_collection'),
              icon: 'collection',
              onSelectItem: async () => {
                this.onAdd(DataTypes.collection);
              },
              isDestructive: false,
              isDisabled: this.collection.read_only,
            },
          ]
        : []),
      {
        text: this.intl.t('articles.collections.actions.delete'),
        icon: 'trash',
        onSelectItem: async () => {
          this.removeCollection();
        },
        isDestructive: true,
        isDisabled: this.collection.read_only,
      },
    ];

    return [
      {
        items,
      },
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Collections::Tree::CollectionItem': typeof CollectionItem;
    'articles/site/collections/tree/collection-item': typeof CollectionItem;
  }
}
