/* import __COLOCATED_TEMPLATE__ from './pane.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import type HelpCenterSite from 'embercom/models/help-center-site';
import { type HelpCenterSettingNavigationHelper } from '../main';

import type Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import { PreviewType } from '../react-preview';
import { type SectionId } from 'embercom/services/help-center-setting-navigation-service';

interface Args {
  app: any;
  site: HelpCenterSite;
  navigationHelper: HelpCenterSettingNavigationHelper;
  setPreviewType: (previewType: string) => void;
}

const SECTION_TO_PREVIEW_TYPE: { [key: string]: PreviewType } = {
  header: PreviewType.Homepage,
  'shared-elements': PreviewType.Homepage,
  'collection-card': PreviewType.Homepage,
  footer: PreviewType.Footer,
};

export default class Pane extends Component<Args> {
  @service declare store: Store;
  @service declare appService: any;

  constructor(owner: any, args: Args) {
    super(owner, args);
    if (!this.args.site.themeColor) {
      this.args.site.themeColor = '#0099CC';
    }
    this.args.site.customizationOptions.setDefaultValues(this.args.site);
  }

  @action
  onOpenSectionChange(sectionId: SectionId) {
    this.args.navigationHelper.navigate(sectionId);
    this.args.setPreviewType(SECTION_TO_PREVIEW_TYPE[sectionId]);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::Styling::Pane': typeof Pane;
    'articles/site/settings/styling/pane': typeof Pane;
  }
}
