/* import __COLOCATED_TEMPLATE__ from './site-default-status.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import templateOnlyComponent from '@ember/component/template-only';
import type HelpCenterSite from 'embercom/models/help-center-site';

interface Args {
  site: HelpCenterSite;
}

const SiteDefaultStatus = templateOnlyComponent<Args>();
export default SiteDefaultStatus;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::General::SiteDefaultStatus': typeof SiteDefaultStatus;
    'articles/site/settings/general/site-default-status': typeof SiteDefaultStatus;
  }
}
