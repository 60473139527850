/* import __COLOCATED_TEMPLATE__ from './domain.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type HelpCenterSite from 'embercom/models/help-center-site';
import { type TlsSelectionHelper } from '../main';

interface Args {
  customDomain: any;
  site: HelpCenterSite;
  isManagedCustomDomainEnabled: boolean;
  tlsSelectionHelper: TlsSelectionHelper;
}

const HTTPS_QUICK_SETUP = 0;
const HTTPS_MANUAL_SETUP = 2;

export default class Domain extends Component<Args> {
  @service declare appService: any;

  get app() {
    return this.appService.app;
  }

  @action
  setSSLValue(isSSL: boolean): void {
    this.args.site.customDomainUsesSsl = isSSL;
  }

  @action
  validateCustomDomainAndPathPrefix({ target: { value } }: { target: { value: string } }): void {
    if (!this.args.isManagedCustomDomainEnabled) {
      return;
    }

    if (
      this.args.tlsSelectionHelper.currentSelection === HTTPS_QUICK_SETUP &&
      this.isUsingPathPrefix(value)
    ) {
      this.args.tlsSelectionHelper.currentSelection = HTTPS_MANUAL_SETUP;
    }
  }

  get shouldDisableHttpsQuickOption(): boolean {
    return (
      this.isUsingPathPrefix(this.args.site.customDomainAndPathPrefix) ||
      !this.args.site.meetsQuickTlsCriteria
    );
  }

  isUsingPathPrefix(domainAndPrefix: string): boolean {
    if (!domainAndPrefix || domainAndPrefix.length === 0) {
      return false;
    }

    try {
      let paths = new URL(`http://${domainAndPrefix}`).pathname.split('/');
      return paths.length >= 2 && paths[1].length > 0;
    } catch (error) {
      return false;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::General::Domain': typeof Domain;
    'articles/site/settings/general/domain': typeof Domain;
  }
}
