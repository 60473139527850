/* import __COLOCATED_TEMPLATE__ from './article-user-filter.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import type Admin from 'embercom/models/admin';

interface Args {
  activeFilter: string;
  selectedValues: string[];
  assignees: Admin[];
  detailKey: string;
  filterId: string;
  name: string;
  filterContent: (filter: string, value: string[]) => void;
  updateActiveFilter: (filter?: string) => void;
}

export default class ArticleUserFilter extends Component<Args> {
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare intercomEventService: any;
  @tracked selectedValues: string[] = [];

  constructor(owner: any, args: Args) {
    super(owner, args);
    this.selectedValues = [...(this.args.selectedValues || [])];
  }

  get app() {
    return this.appService.app;
  }

  get isOpen() {
    return this.args.activeFilter === this.args.filterId;
  }

  get details() {
    let count = this.selectedValues.length ? 1 : 0;

    return this.intl.t(this.args.detailKey, {
      firstItem: count ? this._getDisplayName(this.selectedValues[0]) : '',
      count,
      additional: count - 1,
    });
  }

  _getDisplayName(userId: string) {
    let matched = this.app.assignableAdminsWithoutUnassigned.find(
      (user: Admin) => user.id === userId,
    );
    if (!matched) {
      return '';
    }
    return matched.name;
  }

  @action deleteSelectedValues() {
    this.onSelectionChange([]);
    this.args.filterContent(this.args.filterId, this.selectedValues);
  }

  @action onSelectionChange(selectedValues: string[]) {
    this.selectedValues = selectedValues;
  }

  @action selectUser(userId: string) {
    this.selectedValues = [userId];
  }

  @action onOpen() {
    if (this.isOpen) {
      return;
    }

    this.args.updateActiveFilter(this.args.filterId);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      object: 'article_user_filter',
    });
  }

  @action onClose() {
    this.args.updateActiveFilter(undefined);
    let original = this.args.selectedValues || [];
    if (
      original.length === this.selectedValues.length &&
      original.every((value, index) => value === this.selectedValues[index])
    ) {
      return;
    }

    this.args.filterContent(this.args.filterId, this.selectedValues);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'applied_filter',
      object: 'article_user_filter',
      count: this.selectedValues.length,
      values: this.selectedValues.map((v) => this._getDisplayName(v)),
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Shared::ArticleUserFilter': typeof ArticleUserFilter;
    'articles/shared/article-user-filter': typeof ArticleUserFilter;
  }
}
