/* import __COLOCATED_TEMPLATE__ from './link-group-editor.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import type Store from '@ember-data/store';
import { action } from '@ember/object';
import { schedule } from '@ember/runloop';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type HelpCenterSiteLinkGroup from 'embercom/models/help-center-site-link-group';

interface Args {
  linkGroups: Array<HelpCenterSiteLinkGroup>;
  locale: string;
  maxLinksPerGroup: number;
}

export default class LinkGroupEditor extends Component<Args> {
  @service declare store: Store;
  @tracked openSectionId: string | null = null;
  @tracked showDeleteGroupModal = false;
  groupName = `help-center-link-group-editor-${Math.random().toString(36).substring(7)}`;

  get filteredLinkGroups() {
    return this.args.linkGroups.filter((group) => group.locale === this.args.locale);
  }

  @action
  onOpenSectionChange(sectionId: string | null) {
    this.openSectionId = sectionId;
  }

  @action
  closeSection() {
    this.onOpenSectionChange(null);
  }

  @action
  onOrderChange(linkGroups: Array<HelpCenterSiteLinkGroup>) {
    this.args.linkGroups.removeObjects(this.filteredLinkGroups);
    this.args.linkGroups.addObjects(linkGroups);
    this.reorderLinkGroups();
  }

  @action
  addLink(linkGroup: HelpCenterSiteLinkGroup) {
    linkGroup.addLink();
  }

  @action
  onRemoveLinkGroup() {
    let openSectionId = this.openSectionId ? parseInt(this.openSectionId, 10) : 0;
    this.args.linkGroups.removeAt(openSectionId);
    this.reorderLinkGroups();
    this.showDeleteGroupModal = false;
  }

  @action
  onAddInitialLinkGroup(event: Event) {
    let newLinkGroup = this.store.createFragment('help-center-site-link-group');
    newLinkGroup.locale = this.args.locale;
    newLinkGroup.sortOrder = 0;
    this.args.linkGroups.pushObject(newLinkGroup);

    this.onOpenSectionChange('0');

    let eventTarget = event.target as HTMLInputElement;
    schedule('afterRender', () => {
      if (eventTarget.classList.contains('help-center-link-group-title-input')) {
        // Focus on input when input has been clicked
        let lastInput = document.querySelector(
          '.help-center-link-group-title-input:last-of-type',
        ) as HTMLInputElement;
        if (lastInput) {
          lastInput.focus();
        }
      }
    });
  }

  private reorderLinkGroups() {
    this.args.linkGroups
      .filter((linkGroup) => linkGroup.locale === this.args.locale)
      .forEach((linkGroup, index) => {
        linkGroup.sortOrder = index;
      });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::Content::Links::LinkGroupEditor': typeof LinkGroupEditor;
  }
}
