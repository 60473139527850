/* import __COLOCATED_TEMPLATE__ from './article-locale-selector.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ArticleLocaleSelector extends Component {
  @service appService;
  @service helpCenterService;
  @service intercomEventService;

  get localeOptions() {
    return this.helpCenterService.allUniqueLocalesOrdered.map((locale) => ({
      locale_name: locale.name,
      locale_id: `(${locale.localeId.toUpperCase()})`,
      value: locale.localeId,
      state: this.args.article.contentByLocale(locale.localeId)?.state || 'missing',
      component: 'articles/show/article-locale-option',
    }));
  }

  get selectedLocaleState() {
    return this.args.article.contentByLocale(this.selectedLocaleId)?.state;
  }

  get selectedLocaleId() {
    return this.args.selectedLocaleId;
  }

  @action
  updateLocale(locale) {
    let articleContent = this.args.article.contentByLocale(this.selectedLocaleId);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'locale_selector',
      place: 'show',
      section: 'articles',
      previous_locale: this.selectedLocaleId,
      next_locale: locale,
      ...(articleContent && { article_content_id: articleContent.id }),
    });
    this.helpCenterService.updateLocale(locale);
  }
}
