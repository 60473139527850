/* import __COLOCATED_TEMPLATE__ from './link-editor.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import type Store from '@ember-data/store';
import { action } from '@ember/object';
import { schedule } from '@ember/runloop';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type HelpCenterSiteLink from 'embercom/models/help-center-site-link';

interface Args {
  links: Array<HelpCenterSiteLink>;
}

export default class LinkEditor extends Component<Args> {
  @service declare store: Store;
  groupName = `help-center-link-editor-${Math.random().toString(36).substring(7)}`;

  @action
  onAddInitialLink(event: Event) {
    let eventTarget = event.target as HTMLInputElement;
    let url = '';
    let title = '';
    if (eventTarget.classList.contains('help-center-link-editor-link-input')) {
      url = eventTarget.value;
    } else {
      title = eventTarget.value;
    }

    this.args.links.pushObject(
      this.store.createFragment('help-center-site-link', {
        title,
        url,
        sortOrder: 1,
      }),
    );

    // Ensure the input is re-focused after the link is created
    schedule('afterRender', () => {
      let inputClass = eventTarget.classList.contains('help-center-link-editor-link-input')
        ? 'help-center-link-editor-link-input'
        : 'help-center-link-editor-label-input';
      let lastInput = document.querySelector(`.${inputClass}:last-of-type`) as HTMLInputElement;
      if (lastInput) {
        lastInput.focus();
      }
    });
  }

  @action
  onChange(linkUpdate: Array<HelpCenterSiteLink>) {
    this.args.links.clear();
    this.args.links.addObjects(linkUpdate);

    this.reorderLinks();
  }

  @action
  onRemoveLink(link: HelpCenterSiteLink) {
    this.args.links.removeObject(link);

    this.reorderLinks();
  }

  @action
  onFocusOut(link: HelpCenterSiteLink) {
    if (!link.url.trim().startsWith('http') && !link.url.trim().startsWith('mailto')) {
      link.url = `https://${link.url.trim()}`;
    }
  }

  private reorderLinks() {
    this.args.links.map((link, index) => {
      link.sortOrder = index + 1; // Sort order is 1-indexed, for some reason
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::Content::Links::LinkEditor': typeof LinkEditor;
  }
}
