/* RESPONSIBLE TEAM: team-knowledge-foundations */

import ENV from 'embercom/config/environment';
import { type AuthorSummary } from '../../collections-editor-and-preview';

export enum PreviewMessageType {
  ScrollTo = 'help-center-scroll-to',
  OverwriteCollections = 'overwrite-collections',
  UpdateHelpCenterPreviewSettings = 'update-help-center-preview-settings',
}

interface ScrollToMessage {
  type: PreviewMessageType.ScrollTo;
  location: 'header' | 'footer';
}

interface PayloadMessage {
  type: PreviewMessageType.UpdateHelpCenterPreviewSettings;
  payload: any;
}

interface OverwriteCollectionsMessage {
  type: PreviewMessageType.OverwriteCollections;
  payload: OverwriteCollectionsPayload[];
}

interface OverwriteCollectionsPayload {
  id: string;
  iconUrl: string;
  iconContentType: string;
  articleCount: number;
  authors: AuthorSummary[];
  authorCount: number;
  description: string;
  url: string;
}

export type PostMessage = ScrollToMessage | PayloadMessage | OverwriteCollectionsMessage;

function getHCIframe(): HTMLIFrameElement | undefined {
  return document.getElementById('help-center-preview-frame') as HTMLIFrameElement;
}

function getHCIframeWindow(): Window | null | undefined {
  // Makes it easier to stub out the iframe in tests.
  if (ENV.environment === 'test') {
    // @ts-ignore
    return window.frames['help-center-preview-frame'];
  }
  return getHCIframe()?.contentWindow;
}

export function postMessageToHCIframe(message: PostMessage) {
  let iframeWindow = getHCIframeWindow();
  if (iframeWindow) {
    iframeWindow.postMessage(JSON.parse(JSON.stringify(message)), '*');
  }
}
