/* import __COLOCATED_TEMPLATE__ from './article-singlelingual-editor.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
/* eslint-disable @intercom/intercom/no-bare-strings */

import { isBlank } from '@ember/utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { MAX_SUMMARY_SIZE, MAX_TITLE_SIZE, MAX_PREVIEW_SIZE } from 'embercom/models/article';
import { getRouterService } from 'embercom/lib/container-lookup';
import { task } from 'ember-concurrency-decorators';
import { htmlToTextContent } from 'embercom/lib/html-unescape';
import ArticlesComposerConfig from 'embercom/lib/articles/composer-config';
import { BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import { tracked } from '@glimmer/tracking';
import ENV from 'embercom/config/environment';
import { timeout } from 'ember-concurrency';
import { EntityType } from 'embercom/models/data/entity-types';
import { post } from 'embercom/lib/ajax';
import { CAN_MANAGE_ARTICLES_PERMISSION } from 'embercom/lib/articles/constants';
import { sanitizeArticleContent } from 'embercom/lib/articles/content-sanitizer';

export default class ArticleSinglelingualEditor extends Component {
  @service intercomEventService;
  @service appService;
  @service notificationsService;
  @service clipboardService;
  @service store;
  @service helpCenterService;
  @service permissionsService;
  @service intl;

  @tracked activeContent = this.args.activeContent;
  @tracked prosemirrorComposer;
  @tracked showConfirmModal = false;
  @tracked showHistoryOverlay = false;
  @tracked taggingModalOpened = false;
  @tracked activeContentBlocksDocument;

  clipboard = null;
  maxSummarySize = MAX_SUMMARY_SIZE;
  classNames = ['flex-auto', 'overflow-auto', 'articles__editor__singlelingual-container'];
  constructor() {
    super(...arguments);
    this.activeContentBlocksDocument = new BlocksDocument(this.activeContentBlocks);
  }

  get article() {
    return this.args.article;
  }

  get app() {
    return this.appService.app;
  }
  get site() {
    return this.helpCenterService.site;
  }

  get currentlySelectedLocaleId() {
    if (!this.activeContent) {
      return '';
    }
    return this.activeContent.locale;
  }

  get selectedLocaleName() {
    if (!this.activeContent) {
      return '';
    }

    let currentLocale = this.helpCenterService.allUniqueLocalesOrdered.find(
      (locale) => locale.localeId === this.activeContent.locale,
    );
    return currentLocale?.name || '';
  }

  get activeContentBlocks() {
    if (this.activeContent) {
      return this.activeContent.jsonBlocksForEditing || this.activeContent.jsonBlocks;
    }
    return [];
  }

  get isDefaultLanguage() {
    return this.currentlySelectedLocaleId === this.helpCenterService.site.defaultLocale.localeId;
  }

  get isLive() {
    return this.activeContent.isPublished === true;
  }

  get hasDirtyArticleContents() {
    return this.activeContent.hasDirtyAttributes === true;
  }

  get notDirty() {
    return !this.hasDirtyArticleContents;
  }

  get canDelete() {
    return this.article.articleContents.length > 1;
  }

  get deleteTranslationDialogDesc() {
    return this.intl.t('articles.editor.action-permanent-cannot-be-undone', {
      title_for_display: this.activeContent.titleForDisplay,
      selected_locale_name: this.selectedLocaleName,
    });
  }

  get prosemirrorComposerConfig() {
    let eventService = this.intercomEventService;
    let analytics = {
      trackAnalyticsEvent: (eventName, attrs) => {
        eventService.trackAnalyticsEvent({
          action: eventName,
          object: 'article',
          place: 'composer',
          ...attrs,
        });
      },
    };

    let anchorLinkOnClick = (heading) => {
      this.copyHeadingLinkToClipboard(heading);
    };

    let config = new ArticlesComposerConfig(
      this.app,
      this.activeContent.textDirection,
      analytics,
      anchorLinkOnClick,
    );

    if (this.app.canUseArticlesAi) {
      config.formatters = {
        placement: 'right',
        config: [{ icon: 'ai', key: 'ai-expand' }].map(({ icon, key }) => ({
          icon,
          name: key,
          transform: async (selectedBlocks) => {
            this.notificationsService.notify(
              this.intl.t('articles.editor.ai-running'),
              ENV.APP._3000MS,
            );
            let blocks = await this.getSelectionForAI(selectedBlocks);
            return blocks;
          },
          description: this.intl.t(`articles.editor.${key}`),
        })),
      };
    }

    return config;
  }

  async getSelectionForAI(selectedBlocks) {
    if (this.app.canUseArticlesAi) {
      let params = {
        entity_id: this.activeContent.id,
        entity_type: EntityType.ArticleContent,
        blocks: selectedBlocks,
        intercom_feature: 'article_composer',
      };

      try {
        let response = await post(
          `/ember/inbox/open_ai_demo/article_generator?app_id=${this.appService.app.id}`,
          params,
        );

        return response.response_blocks;
      } catch (error) {
        this.notificationsService.notifyResponseError(error);
        throw error;
      }
    }
  }

  get dropdownItemList() {
    let list = { items: [] };
    if (this.isLive) {
      list.items.push({
        text: this.intl.t('articles.editor.unpublish'),
        isDestructive: false,
        icon: 'hidden',
        isDisabled:
          (this.prosemirrorComposer && this.prosemirrorComposer.composer.state.isUploading) ||
          this.saveAndClose.isRunning,
        onSelectItem: () => {
          this.executeUnpublishAndClose();
          this.trackButtonClick('unpublish_button');
        },
      });
    }

    list.items.push({
      text: this.intl.t('articles.editor.show-history'),
      icon: 'history',
      onSelectItem: () => {
        // eslint-disable-next-line ember/no-side-effects
        this.showHistoryOverlay = true;

        this.intercomEventService.trackAnalyticsEvent({
          action: 'clicked',
          object: 'show_history_button',
          place: 'edit',
          section: 'articles',
          article_id: this.article.id,
          article_content_id: this.activeContent.id,
          locale: this.currentlySelectedLocaleId,
        });
      },
    });

    if (this.canDelete) {
      list.items.push({
        text: this.intl.t('articles.editor.delete'),
        isDestructive: true,
        icon: 'trash',
        onSelectItem: this.showDeleteConfirmation,
      });
    }
    return [list];
  }

  _trackCopyLink(heading, success) {
    this.intercomEventService.trackAnalyticsEvent({
      object: 'anchor_link_button',
      action: 'clicked',
      locale: this.currentlySelectedLocaleId,
      anchor_link_type: heading.type,
      success,
    });
  }

  copyHeadingLinkToClipboard(heading) {
    let url = `${this.activeContent.publicUrl}#${heading.idAttribute}`;
    let onSuccess = () => {
      this._trackCopyLink(heading, true);
      let previewText = htmlToTextContent(heading.text);
      previewText =
        previewText.length > MAX_PREVIEW_SIZE
          ? `${previewText.substring(0, MAX_PREVIEW_SIZE)}…`
          : previewText;
      this.notificationsService.notifyConfirmation(
        this.intl.t('articles.editor.copied-anchor-link-to-place', { preview_text: previewText }),
      );
      this.clipboard?.destroy();
    };
    let onError = () => {
      this._trackCopyLink(heading, false);
      this.notificationsService.notifyWarning('Failed to copy link');
      this.clipboard?.destroy();
    };
    this.clipboard = this.clipboardService.createClipboard(
      '.js__prosemirror-composer-copy-clipboard-element',
      url,
      onSuccess,
      onError,
    );
  }

  handleServerError(response, message) {
    let xhr = response.jqXHR;
    if (xhr && xhr.responseJSON && xhr.responseJSON.message) {
      let errorMessage = xhr.responseJSON.message;
      let displayedErrorMessage = `${message}: ${errorMessage}.`;

      if (
        xhr.statusText === 'Conflict' &&
        xhr.responseJSON.meta &&
        xhr.responseJSON.meta.conflict_resource
      ) {
        console.error(
          this.intl.t('articles.editor.article-existing-resource-conflict'),
          xhr.responseJSON.meta.conflict_resource,
        );
      }

      this.notificationsService.notifyResponseError(
        response,
        { default: displayedErrorMessage },
        { duration: ENV.APP.educateErrorNotificationTimeout },
      );
    } else {
      this.notificationsService.notifyResponseError(response, { default: `${message}.` });
    }
  }

  @task({ restartable: true })
  *autoSave() {
    if (this.app.disableAutoSave) {
      return;
    }

    if (this.activeContent.isDeleted) {
      return;
    }

    if (this.isLive) {
      return;
    }

    if (!this.hasDirtyArticleContents) {
      return;
    }

    yield timeout(ENV.APP._5000MS);

    let currentHash = this.activeContent.hash;

    if (this.prosemirrorComposer.composer.state.isUploading) {
      return;
    }

    if (this.lastSaveHash === currentHash) {
      return;
    }

    if (this.app.canUseSantizeImagesBeforeSave) {
      // Sanitize content before saving
      let sanitizedBlocks = sanitizeArticleContent(this.activeContent.jsonBlocks);
      this.activeContent.jsonBlocks = sanitizedBlocks;
    }

    let updated = yield this.activeContent.autoSaveAsDraft.perform();

    this.lastSaveHash = currentHash;

    this.store.pushPayload({
      'articles/article-content-for-editing': updated,
    });
  }

  @task({ drop: true })
  *saveAndClose(isPublish) {
    this.autoSave.cancelAll();
    let content = this.activeContent;
    if (!content) {
      return;
    }

    if (content.title && content.title.length > MAX_TITLE_SIZE) {
      this.notificationsService.notifyError(
        this.intl.t('articles.editor.article-title-length-too-long', {
          max_title_size: MAX_TITLE_SIZE,
        }),
      );
      return;
    }

    if (content.summary && content.summary.length > MAX_SUMMARY_SIZE) {
      this.notificationsService.notifyError(
        this.intl.t('articles.editor.article-summary-length-too-long', {
          max_summary_size: MAX_SUMMARY_SIZE,
        }),
      );
      return;
    }

    if (isBlank(content.title)) {
      content.set('title', 'Untitled article');
    }

    try {
      // Sanitize content before saving
      if (this.app.canUseSantizeImagesBeforeSave) {
        let sanitizedBlocks = sanitizeArticleContent(content.jsonBlocks);
        content.jsonBlocks = sanitizedBlocks;
      }

      if (this.hasDirtyArticleContents) {
        let updated = yield content.saveAsDraft.perform();
        this.store.pushPayload({
          'articles/article-content-for-editing': updated,
        });
      }

      if (isPublish) {
        let updated = yield content.publish.perform();
        this.store.pushPayload({
          'articles/article-content-for-editing': updated,
        });
      }

      yield this.article.reload();

      let message = isPublish
        ? this.intl.t('articles.editor.article-ready-to-add-to-collection')
        : this.intl.t('articles.editor.article-saved-as-draft');
      this.notificationsService.notifyConfirmation(message);

      getRouterService(this).transitionTo('apps.app.articles.articles.article.show', this.article);
    } catch (response) {
      this.handleServerError(response, this.intl.t('articles.editor.problem-saving-article'));
      return;
    }
  }

  @task({ drop: true })
  *unpublish() {
    let content = this.activeContent;
    if (!content) {
      return;
    }

    this.autoSave.cancelAll();

    try {
      let updated = yield content.unpublish.perform();

      this.store.pushPayload({
        'articles/article-content-for-editing': updated,
      });

      yield this.article.reload();

      this.notificationsService.notifyConfirmation(
        this.intl.t('articles.editor.article-unpublished-only-visible-to-team'),
      );

      getRouterService(this).transitionTo('apps.app.articles.articles.article.show', this.article);
    } catch (response) {
      this.handleServerError(response, this.intl.t('articles.editor.problem-unpublishing-article'));
    }
  }

  @action
  async executeSaveAndClose() {
    try {
      await this.saveAndClose.perform(false);
    } catch (error) {
      if (error && error.message !== 'TaskCancelation') {
        this.handleServerError(error, this.intl.t('articles.editor.problem-saving-article'));
      }
    }
  }

  @action
  trackButtonClick(object) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object,
      place: 'edit',
      section: 'articles',
      article_id: this.article.id,
      article_content_id: this.activeContent.id,
      locale: this.currentlySelectedLocaleId,
      ...(this.activeContent.latestVersionId && {
        article_version_id: this.activeContent.latestVersionId,
      }),
    });
  }

  @action
  async executePublishAndClose() {
    try {
      try {
        await this.permissionsService.checkPermission(CAN_MANAGE_ARTICLES_PERMISSION);
      } catch (e) {
        return;
      }
      await this.saveAndClose.perform(true);
    } catch (error) {
      if (error && error.message !== 'TaskCancelation') {
        this.handleServerError(error, this.intl.t('articles.editor.problem-publishing-article'));
      }
    }
  }

  @action
  async executeUnpublishAndClose() {
    try {
      await this.unpublish.perform(true);
    } catch (error) {
      if (error && error.message !== 'TaskCancelation') {
        this.handleServerError(error, this.intl.t('articles.editor.problem-unpublishing-article'));
      }
    }
  }

  @action
  async deleteTranslation() {
    try {
      this.autoSave.cancelAll();
      await this.activeContent.destroyRecord();
      await this.article.reload();
      getRouterService(this).transitionTo('apps.app.articles.articles.article.show', this.article);
      this.notificationsService.notifyConfirmation(
        this.intl.t('articles.editor.article-translation-deleted-successfully'),
      );
    } catch (response) {
      this.handleServerError(
        response,
        this.intl.t('articles.editor.problem-deleting-article-translation'),
      );
    }
  }
  @action
  async showDeleteConfirmation() {
    try {
      await this.permissionsService.checkPermission(this.activeContent.requiredEditPermission);
    } catch (e) {
      return;
    }
    this.showConfirmModal = true;
  }

  @action
  async cancelEdit() {
    if (!this.activeContent) {
      window.history.back();
      return;
    }
    this.activeContent.rollbackAttributes();
    await this.article.reload();
    getRouterService(this).transitionTo('apps.app.articles.articles.article.show', this.article);
  }

  @action
  prosemirrorComposerChanged(newBlocksDocument) {
    this.activeContent.set('jsonBlocks', newBlocksDocument.blocks);
    this.activeContent.set('jsonBlocksForEditing', newBlocksDocument.blocks);
    if (this.app.canUseArticleVersionSteps) {
      this.activeContent.set(
        'stepsSinceLastVersion',
        this.activeContent.stepsSinceLastVersion === undefined
          ? newBlocksDocument.stepsSinceLastChange
          : this.activeContent.stepsSinceLastVersion.concat(newBlocksDocument.stepsSinceLastChange),
      );
    }
  }

  @action
  prosemirrorComposerReady(composer) {
    this.prosemirrorComposer = composer;
  }

  @action
  openTaggingModal() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      object: 'tagging_modal',
    });

    this.taggingModalOpened = true;
  }

  @action
  createNewBlocksDocument() {
    this.activeContentBlocksDocument = new BlocksDocument(this.activeContentBlocks);
  }
}
