/* import __COLOCATED_TEMPLATE__ from './report-container-header.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import Component from '@glimmer/component';
import moment from 'moment-timezone';
import { inject as service } from '@ember/service';

interface Args {
  currentBillingPeriodCharges: $TSFixMe;
}

interface Signature {
  Args: Args;
}

export default class ReportContainerHeader extends Component<Signature> {
  @service declare customerService: $TSFixMe;
  @service declare appService: $TSFixMe;

  get customer() {
    return this.customerService.customer;
  }

  get billingPeriodDate() {
    let humanizedDate = 'MMMM DD, YYYY';
    return `${moment.tz(this.customer.currentBillingCycleStartDate, 'UTC').format(humanizedDate)} - ${moment
      .tz(this.customer.currentBillingCycleEndDate, 'UTC')
      .format(humanizedDate)}`;
  }

  get currentTotal() {
    return this.args.currentBillingPeriodCharges.total_amount / 100;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Usage::ReportContainerHeader': typeof ReportContainerHeader;
  }
}
