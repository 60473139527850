/* import __COLOCATED_TEMPLATE__ from './conversation-list.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { get } from 'embercom/lib/ajax';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import Range from 'embercom/models/reporting/range';
import moment from 'moment-timezone';
import { isPresent } from '@ember/utils';
import { allChannels, CHANNEL_METADATA_MAPPING } from 'embercom/lib/operator/custom-bots/constants';

export default class ConversationList extends Component {
  @service store;
  @service appService;
  @service intl;

  @tracked pageNumber = 1;
  @tracked conversations = [];
  @tracked conversationCount = 0;
  shouldFetchNextPage = true;
  @tracked rangeStart = this.formattedStartDate();
  @tracked rangeEnd = moment().endOf('day').format();
  @tracked selectedTargetChannels = [];

  constructor() {
    super(...arguments);

    this.loadPage.perform();
  }

  get targetChannelTypes() {
    if (this.selectedTargetChannels?.length > 0) {
      return this.selectedTargetChannels.map((channel) => {
        return CHANNEL_METADATA_MAPPING[channel].channelTypeValue;
      });
    }
  }

  get defaultChannels() {
    return allChannels(this.appService.app.enableTwitterIntegrationBots).map((channel) => {
      return {
        ...CHANNEL_METADATA_MAPPING[channel],
        isSelected: this.selectedTargetChannels.includes(channel),
        text: this.intl.t('operator.workflows.channels.type', {
          channel: CHANNEL_METADATA_MAPPING[channel].value,
        }),
      };
    });
  }

  get header() {
    switch (this.args.eventType) {
      case 'answer_sent':
        return this.intl.t('custom-answers.answer-conversation-list.header.sent', {
          count: this.conversationCount,
        });
      case 'all_done':
        return this.intl.t('custom-answers.answer-conversation-list.header.resolved', {
          count: this.conversationCount,
        });
      case 'abandoned':
        return this.intl.t('custom-answers.answer-conversation-list.header.abandoned', {
          count: this.conversationCount,
        });
      case 'wait_for_the_team':
        return this.intl.t('custom-answers.answer-conversation-list.header.routed-to-team', {
          count: this.conversationCount,
        });
    }
  }

  get emptyStateText() {
    switch (this.args.eventType) {
      case 'answer_sent':
        return this.intl.t('custom-answers.answer-conversation-list.empty-state.sent');
      case 'all_done':
        return this.intl.t('custom-answers.answer-conversation-list.empty-state.resolved');
      case 'abandoned':
        return this.intl.t('custom-answers.answer-conversation-list.empty-state.abandoned');
      case 'wait_for_the_team':
        return this.intl.t('custom-answers.answer-conversation-list.empty-state.routed-to-team');
    }
  }

  get range() {
    return Range.createFromParamsWithAddedRanges(
      this.rangeStart,
      this.rangeEnd,
      this.timezone(),
      this.wentLiveAt()
        ? this._createRangeOption(
            'custom-answers.date-range.past-2-years',
            this.startDate(),
            this.timezone(),
          )
        : null,
    );
  }

  wentLiveAt() {
    return this.args.answer.firstWentLiveAt;
  }

  startDate() {
    let wentLiveAt = new Date(this.wentLiveAt() || this.appService.app.created_at);
    let twoYearsAgo = moment().subtract(2, 'years').add(1, 'day').toDate();
    return wentLiveAt > twoYearsAgo ? wentLiveAt : twoYearsAgo;
  }

  formattedStartDate() {
    return moment(this.startDate()).startOf('day').format();
  }

  timezone() {
    return this.appService.app.timezone;
  }

  @task({ restartable: true })
  *loadPage() {
    let response = yield get('/ember/custom_answers/conversations', {
      app_id: this.appService.app.id,
      page: this.pageNumber,
      channel_type: this.targetChannelTypes,
      answer_id: this.args.answer.id,
      event_type: this.args.eventType,
      per_page: 10,
      start_date: this.range.start,
      end_date: this.range.end,
    });

    this.store.pushPayload({ conversations: response.conversations });
    this.conversationCount = response.total_count;
    this.conversations.pushObjects(
      response.conversations.map((c) => this.store.peekRecord('conversation', c.id)),
    );
    this.shouldFetchNextPage = isPresent(response.conversations); // Stop fetching subsequent pages if there are no more conversations
  }

  @action
  loadFirstPage() {
    this.conversations = [];
    this.pageNumber = 1;
    this.loadPage.perform();
  }

  @action
  loadNextPage() {
    if (this.shouldFetchNextPage) {
      this.pageNumber++;
      return this.loadPage.perform();
    }
  }

  @action
  onUpdateDateRange(range) {
    this.rangeStart = range.start;
    this.rangeEnd = range.end;
    this.loadFirstPage();
  }

  @action
  onUpdateTargetChannels(channels) {
    this.selectedTargetChannels = channels;
    this.loadFirstPage();
  }

  _createRangeOption(label, startOfRange, timezone) {
    let today = moment.tz(timezone).endOf('day');
    let sinceStartofRange = today.diff(moment.tz(startOfRange, timezone), 'days') + 1;
    return { [label]: [sinceStartofRange, 1] };
  }
}
