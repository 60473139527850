/* import __COLOCATED_TEMPLATE__ from './language-filter.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { modifier } from 'ember-modifier';

export default class LanguageFilter extends Component {
  @service intl;
  @service intercomEventService;

  @tracked selectedValues = [];

  filterResetable = modifier((_, [v]) => {
    let newValues = v || [];

    this.onSelectionChange(newValues.slice());
  });

  get isOpen() {
    return this.args.activeFilter === 'languages';
  }

  get hasFilter() {
    return this.items.length >= 8;
  }

  get items() {
    return this.args.availableItems.map((item) => ({
      text: item.text,
      value: item.value,
      isSelected: this.selectedValues ? this.selectedValues.includes(item.value) : false,
      component: 'articles/shared/language-filter-option',
    }));
  }

  get details() {
    let count = this.selectedValues.length;

    return this.intl.t('articles.shared.language_filter.details', {
      firstItem: count ? this._getDisplayName(this.selectedValues[0]) : '',
      count,
      additional: count - 1,
    });
  }

  get label() {
    return this.intl.t('articles.shared.language_filter.label');
  }

  get searchPlaceHolder() {
    return this.intl.t('articles.shared.language_filter.search_placeholder');
  }

  _getDisplayName(v) {
    let matched = this.items.find((e) => e.value === v);
    if (!matched) {
      return '';
    }
    return matched.text;
  }

  @action deleteSelectedValues() {
    this.onSelectionChange([]);
    this.args.filterContent('languages', this.selectedValues);
  }

  @action onSelectionChange(selectedValues) {
    this.selectedValues = selectedValues;
  }

  @action onOpen() {
    if (this.isOpen) {
      return;
    }

    this.args.updateActiveFilter('languages');
    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      object: 'language_filter',
    });
  }

  @action onClose() {
    this.args.updateActiveFilter(undefined);
    let original = this.args.selectedValues || [];
    if (
      original.length === this.selectedValues.length &&
      original.every((value, index) => value === this.selectedValues[index])
    ) {
      return;
    }

    this.args.filterContent('languages', this.selectedValues);

    this.intercomEventService.trackAnalyticsEvent({
      action: 'applied_filter',
      object: 'language_filter',
      count: this.selectedValues.length,
      values: this.selectedValues.map((v) => this._getDisplayName(v)),
    });
  }
}
