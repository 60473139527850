/* import __COLOCATED_TEMPLATE__ from './cancel-subscription.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { startSurvey } from 'embercom/lib/intercom-widget-helper';
import { SELF_SERVE_CHURN_SURVEY_ID } from 'embercom/lib/billing/surveys';
import { tracked } from '@glimmer/tracking';

export default class CancelSubscription extends Component {
  @service appService;
  @service router;
  @service pauseSubscriptionModalService;
  @service intercomEventService;

  @tracked isLoseBenefitsModalVisible = false;
  @tracked isCancelQuestionnaireModalVisible = false;

  get app() {
    return this.appService.app;
  }
  get isPauseSubscriptionModalOpen() {
    return this.pauseSubscriptionModalService.isOpen;
  }

  get isPauseSubscriptionEnabled() {
    return this.app.canPauseEarlyStageSubscription;
  }

  get isLoseBenefitsModalOpen() {
    return this.app.canSeeChurnSaveInterventionChanges && this.isLoseBenefitsModalVisible;
  }

  get isCancelQuestionnaireModalOpen() {
    return this.app.canSeeChurnSaveInterventionChanges && this.isCancelQuestionnaireModalVisible;
  }

  get canSeeConfirmCancellationModal() {
    return this.app.canSeeChurnSaveInterventionChanges && this.args.redirectSource;
  }

  get getPricingModelName() {
    if (this.appService.app.onPricing5) {
      return 'pricing5';
    }
    if (this.appService.app.hasValueBasedPricing2) {
      return 'vbp2';
    }
    if (this.appService.app.hasValueBasedPricing1) {
      return 'vbp1';
    }

    return 'other';
  }

  @action
  closePauseSubscriptionModal() {
    this.pauseSubscriptionModalService.close();
  }

  @action
  closeLoseBenefitsModal() {
    this.isLoseBenefitsModalVisible = false;
  }

  @action
  continueCancellation(option) {
    this.isCancelQuestionnaireModalVisible = false;
    if (option === 'dont-want-intercom') {
      this.isLoseBenefitsModalVisible = true;
    } else {
      this.router.transitionTo('apps.app.settings.subscription.billing.settings', {
        queryParams: {
          redirect_source: 'cancellation_flow',
        },
      });
    }
  }

  @action
  closeCancelQuestionnaireModal(option) {
    this.isCancelQuestionnaireModalVisible = false;

    this.isLoseBenefitsModalVisible =
      this.app.canSeeChurnSaveInterventionChanges && option === 'continue_cancellation';
  }

  @action
  cancelSubscription() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'cancel_subscription_button',
      place: 'settings.subscription.billing.settings',
      section: 'cancel_subscription',
      context: 'billing_settings',
      pricing_model: this.getPricingModelName,
    });
    if (this.pauseSubscriptionModalService.canPauseEarlyStageSubscription) {
      this.pauseSubscriptionModalService.open();
    } else if (this.app.onFinForPlatforms) {
      this.router.transitionTo('apps.app.settings.subscription.billing.cancel.confirm');
    } else if (this.app.canSeeSelfServeChurnSurvey) {
      if (this.app.canSeeChurnSaveInterventionChanges) {
        this.isCancelQuestionnaireModalVisible = true;
      } else {
        // The last step of the new self serve churn Survey will
        // redirect customers to their cancellation page.
        startSurvey(SELF_SERVE_CHURN_SURVEY_ID);
      }
    } else {
      this.router.transitionTo('apps.app.billing.cancel'); // Fallback for AU / EU workspaces
    }
  }
}
