/* import __COLOCATED_TEMPLATE__ from './icon-selector-component.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
/* eslint-disable promise/prefer-await-to-then */

import Component from '@ember/component';
import { action } from '@ember/object';
import { UploadHelper, MediaHelper } from 'embercom/objects/media/media-helper';
import { Promise as EmberPromise } from 'rsvp';
import ajax from 'embercom/lib/ajax';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { CUSTOM_FILE_SIZE_VALIDATOR } from 'embercom/components/articles/site/settings/upload-button';

export default Component.extend({
  classNames: ['educate__icon-selector'],
  classNameBindings: ['isPopoverOpen:o__active'],
  isPopoverOpen: false,
  appService: service(),
  notificationsService: service(),
  app: readOnly('appService.app'),
  store: service(),
  intl: service(),

  init() {
    this._super();
    this.set(
      'uploadHelper',
      UploadHelper.create({
        externalIcon: MediaHelper.create({
          type: 'external_icon',
          siteSettingsName: 'externalIconUrl',
          validators: [
            CUSTOM_FILE_SIZE_VALIDATOR(
              150000,
              this.intl.t('articles.settings.redesign.validation.file-size-error', {
                size: '150KB',
              }),
              10000,
              this.intl.t('articles.settings.redesign.validation.file-size-error-with-file-type', {
                size: '10KB',
                type: 'SVG',
              }),
            ),
          ],
        }),
        mediaTypes: ['externalIcon'],
      }),
    );
  },

  _saveImage(image, type) {
    let formData = new FormData();
    formData.append('asset_type', type);
    formData.append('image', image);
    formData.append('app_id', this.get('app.id'));
    return ajax({
      type: 'POST',
      url: `/ember/article_folders/${this.get('folder.id')}/upload_asset.json`,
      data: formData,
      contentType: false,
      processData: false,
    }).then((response) => this._storeCollection(response));
  },

  _storeCollection(updated) {
    this.store.pushPayload({
      'articles/article-group': [updated],
    });
  },

  _deleteImage(type) {
    let formData = new FormData();
    formData.append('asset_type', type);
    formData.append('app_id', this.get('app.id'));
    return ajax({
      type: 'DELETE',
      url: `/ember/article_folders/${this.get('folder.id')}/delete_asset.json`,
      data: formData,
      contentType: false,
      processData: false,
    }).then((response) => this._storeCollection(response));
  },

  _updateMedia(mediaHelper) {
    if (this.inModalContext) {
      return new Promise((resolve) => {
        resolve(this.onSave(mediaHelper));
      });
    }
    return new EmberPromise((resolve, reject) => {
      let type = mediaHelper.get('type');
      let url = mediaHelper.get('url');
      let file = mediaHelper.get('file');
      let isReset = mediaHelper.get('isReset');
      let collection = this.folder;
      let externalIconSavedInsideCollection = collection?.data?.externalIconUrl;
      // the url inside the mediaHelper is actually the base64 image that is set when we select a file
      let newFileToUpload = url && !isReset;

      // we delete icon when it was saved on the collection AND there was a reset signal
      let shouldDeleteIcon = externalIconSavedInsideCollection && isReset;
      if (newFileToUpload) {
        this._saveImage(file, type)
          .then(() => {
            mediaHelper.setProperties({
              isSaved: true,
              isReset: false,
            });
            resolve();
          })
          .catch((response) => {
            this._handleServerError(
              response,
              this.intl.t('articles.collections.external-icon.upload.error'),
            );
            mediaHelper.setProperties({
              isSaved: false,
              isReset: true,
            });
            mediaHelper.reset();
            reject();
          });
      }
      if (shouldDeleteIcon) {
        this._deleteImage(type)
          .then(() => {
            mediaHelper.reset();
            resolve();
          })
          .catch((response) => {
            this._handleServerError(
              response,
              this.intl.t('articles.collections.external-icon.remove.error'),
            );
            mediaHelper.setProperties({
              isSaved: false,
              isReset: true,
            });
            reject();
          });
      }
    });
  },

  _saveMediaUpdates() {
    return new EmberPromise((resolve, reject) => {
      let media = this.get('uploadHelper.externalIcon');
      if (!media.get('hasUpdates')) {
        return resolve();
      }
      this._updateMedia(media)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  closePopover: action(function (popover) {
    popover.hide();
    this._saveMediaUpdates().then(() => {
      this.onSave();
    });
  }),

  _handleServerError(error, msg) {
    let errorHandler = this.handleServerError;
    if (!errorHandler) {
      this.notificationsService.notifyError(msg);
    } else {
      errorHandler(error, msg);
    }
  },
});
