/* import __COLOCATED_TEMPLATE__ from './article-header-viewer.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    content: {
      title?: string;
      summary?: string;
    };
    hasTitleChanged?: boolean;
    hasSummaryChanged?: boolean;
    titlePlaceholder?: string;
  };
  Blocks: {
    default: [];
  };
}

const ArticleHeaderViewer = templateOnlyComponent<Signature>();
export default ArticleHeaderViewer;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Show::ArticleHeaderViewer': typeof ArticleHeaderViewer;
  }
}
