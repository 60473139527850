/* RESPONSIBLE TEAM: team-knowledge-foundations */
import type ArticleGroup from 'embercom/models/articles/article-group';

export const HOME_IDENTIFIER = 'home';
export const COLLECTION_LEVEL = 0;
export const SECTION_LEVEL = 1;
export const SUBSECTION_LEVEL = 2;

export type CollectionWithLevel = { collection: ArticleGroup; level: number };

export function indentCollections(collections: Array<ArticleGroup>): Array<CollectionWithLevel> {
  let flattenedHiearachy: Array<CollectionWithLevel> = [];
  collections
    .filter((collection: ArticleGroup) => collection.parent.get('id') === HOME_IDENTIFIER)
    .forEach((collection: ArticleGroup) => {
      flattenedHiearachy.push({ collection, level: COLLECTION_LEVEL });
      collection.get('sortedChildren').forEach((section: ArticleGroup) => {
        flattenedHiearachy.push({ collection: section, level: SECTION_LEVEL });
        section.get('sortedChildren').forEach((subsection: ArticleGroup) => {
          flattenedHiearachy.push({ collection: subsection, level: SUBSECTION_LEVEL });
        });
      });
    });

  return flattenedHiearachy;
}
