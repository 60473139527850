/* import __COLOCATED_TEMPLATE__ from './price-per-metric.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { type TooltipRow } from 'embercom/components/billing/usage/tooltips-new/shared-components/tooltip-item';

interface Args {
  metricPrice: $TSFixMe;
  text: string;
}

export default class PricePerMetric extends Component<Args> {
  @service declare intl: IntlService;

  get pricePerMetricRow() {
    return [
      {
        label: this.args.text,
        muted: true,
        noPadding: true,
        strong: true,
      },
      {
        label: this.intl.t('billing.summary.usage-tooltip.formatted_price_per_seat', {
          pricePerUnit: this.formatPrice(this.args.metricPrice.per_unit_price / 100),
        }),
      },
    ] as TooltipRow[];
  }

  formatPrice(price: number) {
    return this.intl.formatNumber(price, {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Usage::TooltipsNew::SharedComponents::PricePerMetric': typeof PricePerMetric;
  }
}
