/* import __COLOCATED_TEMPLATE__ from './multi-workspace-contracted-over-limit.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type { TooltipComponentSignature } from '../../schemas';
import { type TooltipRow } from 'embercom/components/billing/usage/tooltips-new/shared-components/tooltip-item';
import { formatPriceFromCents } from '../../../tooltips/format-price';
export default class MultiWorkspaceContractedOverLimitComponent extends Component<TooltipComponentSignature> {
  @service declare intl: IntlService;
  @service declare customerService: $TSFixMe;

  get infoBoxContent() {
    let key =
      this.args.bucketDurationInMonths === 12
        ? 'billing.summary.usage-tooltip.multi_workspace.exceeds_bucket_threshold_year'
        : 'billing.summary.usage-tooltip.multi_workspace.exceeds_bucket_threshold_quarter';

    return this.intl.t(key, { formattedBucketNumber: this.args.formattedFinBucketInContract });
  }

  get overageAmount() {
    return this.args.finBucketsCumulativeUsageForAllWorkspaces - this.args.finBucketInContract;
  }

  get formattedOverageAmount() {
    return this.intl.formatNumber(this.overageAmount, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
  }

  get proratedTotalRow() {
    let centPriceForTotal = this.args.metricPrice.per_unit_price * this.overageAmount;

    return [
      {
        label: this.intl.t('billing.summary.usage-tooltip.total'),
        muted: true,
        noPadding: true,
        strong: true,
        value: formatPriceFromCents(centPriceForTotal, this.intl),
      },
    ] as TooltipRow[];
  }

  get pricePerResolutionRow() {
    return [
      {
        label: this.intl.t('billing.summary.usage-tooltip.price_per_resolution'),
        muted: true,
        noPadding: true,
        strong: true,
      },
      { label: this.formattedResolutionPrice },
    ] as TooltipRow[];
  }

  get formattedResolutionPrice() {
    return this.intl.formatNumber(this.args.metricPrice.per_unit_price / 100, {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Usage::TooltipsNew::BucketedMetrics::MultiWorkspaceContractedOverLimit': typeof MultiWorkspaceContractedOverLimitComponent;
  }
}
