/* import __COLOCATED_TEMPLATE__ from './hero.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import { inject as service } from '@ember/service';
import { action } from '@ember/object';

import Component from '@glimmer/component';
import type HelpCenterSite from 'embercom/models/help-center-site';
import { type UploadHelper } from 'embercom/objects/media/media-helper';

interface Args {
  site: HelpCenterSite;
  uploadHelper: UploadHelper;
}

export default class Hero extends Component<Args> {
  @service attributeService: any;

  get manualAppAttributes() {
    return this.attributeService.greetingComposerAttributes;
  }

  get headerComponent() {
    return this.args.site.customizationOptions.global?.namedComponents.header;
  }

  @action
  removeHeaderImageUrl(): void {
    this.args.site.headerUrl = '';
  }

  get mediaHelper() {
    return this.args.uploadHelper.getMediaHelper('header');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::NewStyling::Hero': typeof Hero;
  }
}
